import { useState } from 'react';
import { Button, Modal, Form, Row, Col, Spinner, Stack } from 'react-bootstrap';

export default function ProjectPhotos(props) {

    const [show, setShow] = useState(false);
    const [submitString, setSubmitString] = useState('Save Project');

    const [photos, setPhotos] = useState();

    const handleClose = () =>  {
        setSubmitString('Save Project');
        setPhotos('');
        setShow(false);
    }

    const handlePhotoComp = () => {
        try {
            var validUrl = require('valid-url');
            if (validUrl.isWebUri(props.row.values.photosDisplay)) {
                window.open(props.row.values.photosDisplay,'_newtab' + Date.now());
            } else {
                handleShow();
            }
        } catch (error) {
            handleShow();
        }
    }

    const handleShow = () => {
        setPhotos(props.row.values.photosDisplay);
        if (props.fromDashboard) {
            props.hideLastPopover();
        }
        setShow(true);
    }

    const handleSubmit = () => {
        setSubmitString(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const Parse = require('parse');
        const query = new Parse.Query(Parse.Object.extend('Project'));
        query.get(props.row.values.idDisplay)
        .then((project) =>{
            project.set('photoLink', photos);
            project.save()
            .then((res2)=>{
                handleClose();
            }).catch((error)=>{
                alert('Please send screenshot of this error message: ' + error.message);
                setSubmitString('Save Project');
            })
        }).catch((error)=>{
            alert('Please send screenshot of this error message: ' + error.message);
            setSubmitString('Save Project');
        })
    }
    return <>
        <Stack direction="horizontal" gap={2} >
            <div>
                <Button variant='outline-dark' className='cerulean-button square-button' onClick={handlePhotoComp}>
                    <i className="fas fa-camera"></i>
                </Button>
            </div>
        </Stack>
        
        <Modal show={show} onHide={handleClose} onExited={props.handleExit} centered size='lg' backdrop="static">
            <Modal.Header className='modal-header' closeButton>
                <Modal.Title>Photos: {props.row.values.projectNumberDisplay} — {props.row.values.projectNameDisplay}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Photos Link</Form.Label>
                            <Form.Control
                                type="text"
                                value={photos}
                                onChange={(e) => setPhotos(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                >
                    {submitString}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
