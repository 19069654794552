import { useState, useEffect } from 'react';
import { Button, Form, Modal, Spinner, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function ListBuilder(props) {

    const Parse = require('parse');

    const [show, setShow] = useState(false);
    const [listName, setListName] = useState('');
    const [listLength, setListLength] = useState(1);
    const [item1, setItem1] = useState('');
    const [item2, setItem2] = useState('');
    const [item3, setItem3] = useState('');
    const [item4, setItem4] = useState('');
    const [item5, setItem5] = useState('');
    const [item6, setItem6] = useState('');
    const [item7, setItem7] = useState('');
    const [showItem2, setShowItem2] = useState(false);
    const [showItem3, setShowItem3] = useState(false);
    const [showItem4, setShowItem4] = useState(false);
    const [showItem5, setShowItem5] = useState(false);
    const [showItem6, setShowItem6] = useState(false);
    const [showItem7, setShowItem7] = useState(false);
    const [showPlus, setShowPlus] = useState(true);
    const [showMinus, setShowMinus] = useState(false);
    const [itemWarning, setItemWarning] = useState(false);
    const [listWarning, setListWarning] = useState(false);
    const [submitString, setSubmitString] = useState('Create Checklist');
    
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(()=>{
        if (props.editMode) {
            setListName(props.row.values.nameDisplay);
            var listArray = props.row.values.listDisplay.split(',');
            setListLength(parseInt(props.row.values.lengthDisplay));
            switch (parseInt(props.row.values.lengthDisplay)) {
                case 7:
                    setItem1(listArray[0]);
                    setItem2(listArray[1]);
                    setItem3(listArray[2]);
                    setItem4(listArray[3]);
                    setItem5(listArray[4]);
                    setItem6(listArray[5]);
                    setItem7(listArray[6]);
                    break;
                case 6:
                    setItem1(listArray[0]);
                    setItem2(listArray[1]);
                    setItem3(listArray[2]);
                    setItem4(listArray[3]);
                    setItem5(listArray[4]);
                    setItem6(listArray[5]);
                    break;
                case 5:
                    setItem1(listArray[0]);
                    setItem2(listArray[1]);
                    setItem3(listArray[2]);
                    setItem4(listArray[3]);
                    setItem5(listArray[4]);
                    break;
                case 4:
                    setItem1(listArray[0]);
                    setItem2(listArray[1]);
                    setItem3(listArray[2]);
                    setItem4(listArray[3]);
                    break;
                case 3:
                    setItem1(listArray[0]);
                    setItem2(listArray[1]);
                    setItem3(listArray[2]);
                    break;
                case 2:
                    setItem1(listArray[0]);
                    setItem2(listArray[1]);
                    break;
                case 1:
                    setItem1(listArray[0]);
                    break;
                default:
                    setItem1(listArray[0]);
                    break;
            }
            setSubmitString('Save Checklist');
        }
    }, [show])

    // useEffect(()=> {
    //     if ((item7.length > 0) && showItem7) {
    //         setListLength(7);
    //     } else if ((item6.length > 0) && showItem6) {
    //         setShowItem7(true);
    //         setListLength(6);
    //     } else if ((item5.length > 0) && showItem5) {
    //         setShowItem6(true);
    //         setListLength(5);
    //     } else if ((item4.length > 0) && showItem4) {
    //         setShowItem5(true);
    //         setListLength(4);
    //     } else if ((item3.length > 0) && showItem3) {
    //         setShowItem4(true);
    //         setListLength(3);
    //     } else if ((item2.length > 0) && showItem2) {
    //         setShowItem3(true);
    //         setListLength(2);
    //     } else if (item1.length > 0) {
    //         setShowItem2(true);
    //         setListLength(1);
    //     }
    // }, [listName, item1, item2, item3, item4, item5, item6, item7]);

    useEffect(()=> {
        if ((item1.length > 72) || (item2.length > 72) || (item3.length > 72) || (item4.length > 72) || 
            (item5.length > 72) || (item6.length > 72) || (item7.length > 72)) {
            setItemWarning(true);
        } else {
            setItemWarning(false);
        }
        switch (listLength) {
            case 7:
                setShowItem2(true);
                setShowItem3(true);
                setShowItem4(true);
                setShowItem5(true);
                setShowItem6(true);
                setShowItem7(true);
                setShowPlus(false);
                setShowMinus(true);
                setListWarning(true);
                (!listName || !item1 || !item2 || !item3 || !item4 || !item5 || !item6 || !item7)  ? setIsDisabled(true) : setIsDisabled(false);
                break;
            case 6:
                setShowItem2(true);
                setShowItem3(true);
                setShowItem4(true);
                setShowItem5(true);
                setShowItem6(true);
                setShowItem7(false);
                setShowPlus(true);
                setShowMinus(true);
                setListWarning(true);
                (!listName || !item1 || !item2 || !item3 || !item4 || !item5 || !item6)  ? setIsDisabled(true) : setIsDisabled(false);
                break;
            case 5:
                setShowItem2(true);
                setShowItem3(true);
                setShowItem4(true);
                setShowItem5(true);
                setShowItem6(false);
                setShowItem7(false);
                setShowPlus(true);
                setShowMinus(true);
                setListWarning(false);
                (!listName || !item1 || !item2 || !item3 || !item4 || !item5)  ? setIsDisabled(true) : setIsDisabled(false);
                break;
            case 4:
                setShowItem2(true);
                setShowItem3(true);
                setShowItem4(true);
                setShowItem5(false);
                setShowItem6(false);
                setShowItem7(false);
                setShowPlus(true);
                setShowMinus(true);
                setListWarning(false);
                (!listName || !item1 || !item2 || !item3 || !item4)  ? setIsDisabled(true) : setIsDisabled(false);
                break;
            case 3:
                setShowItem2(true);
                setShowItem3(true);
                setShowItem4(false);
                setShowItem5(false);
                setShowItem6(false);
                setShowItem7(false);
                setShowPlus(true);
                setShowMinus(true);
                setListWarning(false);
                (!listName || !item1 || !item2 || !item3)  ? setIsDisabled(true) : setIsDisabled(false);
                break;
            case 2:
                setShowItem2(true);
                setShowItem3(false);
                setShowItem4(false);
                setShowItem5(false);
                setShowItem6(false);
                setShowItem7(false);
                setShowPlus(true);
                setShowMinus(true);
                setListWarning(false);
                (!listName || !item1 || !item2)  ? setIsDisabled(true) : setIsDisabled(false);
                break;
            case 1:
                setShowItem2(false);
                setShowItem3(false);
                setShowItem4(false);
                setShowItem5(false);
                setShowItem6(false);
                setShowItem7(false);
                setShowPlus(true);
                setShowMinus(false);
                setListWarning(false);
                (!listName || !item1)  ? setIsDisabled(true) : setIsDisabled(false);
                break;
            default:
                setShowItem2(false);
                setShowItem3(false);
                setShowItem4(false);
                setShowItem5(false);
                setShowItem6(false);
                setShowItem7(false);
                setShowPlus(true);
                setShowMinus(false);
                setListWarning(false);
                (!listName || !item1) ? setIsDisabled(true) : setIsDisabled(false);
                break;
        }
    }, [listName, listLength, item1, item2, item3, item4, item5, item6, item7]);

    const handleClose = () =>  {
        setShow(false);
        setListName('');
        setListLength(1);
        setItem1('');
        setItem2('');
        setItem3('');
        setItem4('');
        setItem5('');
        setItem6('');
        setItem7('');
        setShowItem2(false);
        setShowItem3(false);
        setShowItem4(false);
        setShowItem5(false);
        setShowItem6(false);
        setShowItem7(false);
        setShowPlus(true);
        setShowMinus(false);
        setListWarning(false);
        setItemWarning(false);
        setSubmitString('Create Checklist');
    }

    const handleShow = () => {
        setShow(true);
    }
      
    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitString(<>
            Creating&nbsp;
            <Spinner
                as="span"
                animation="border"
                size="sm"
            />
        </>);

        const Checklist = Parse.Object.extend('Checklist');
        const newChecklist = new Checklist();
        const query = new Parse.Query(Checklist);
        const query2 = new Parse.Query(Checklist);
        query.equalTo('name', listName);
        query.find()
        .then(function(res) {
            if (res.length < 1 || (props.editMode && (listName === props.row.values.nameDisplay))) {
                if (!props.editMode) {
                    var itemArray;
                    switch (listLength) {
                        case 7:
                            itemArray = [item1, item2, item3, item4, item5, item6, item7];
                            break;
                        case 6:
                            itemArray = [item1, item2, item3, item4, item5, item6];
                            break;
                        case 5:
                            itemArray = [item1, item2, item3, item4, item5];
                            break;
                        case 4:
                            itemArray = [item1, item2, item3, item4];
                            break;
                        case 3:
                            itemArray = [item1, item2, item3];
                            break;
                        case 2:
                            itemArray = [item1, item2];
                            break;
                        case 1:
                            itemArray = [item1];
                            break;
                        default:
                            itemArray = [item1];
                            break;
                    }
                    newChecklist.set('name', listName);
                    newChecklist.set('list', itemArray);
                    var listACL = new Parse.ACL();
                    listACL.setRoleReadAccess('staff', true);
                    listACL.setRoleWriteAccess('staff', true);
                    newChecklist.setACL(listACL);

                    newChecklist.save().then((listRes) => {
                        handleAddListDisplay(listRes, listName, itemArray);
                        handleClose();
                    }).catch((error)=> {
                        alert('Please send screenshot of this error message: ' + error.message);
                        setSubmitString('Create Checklist');
                    })
                } else {
                    query2.get(props.row.values.idDisplay)
                    .then((res) => {
                        var itemArray;
                        switch (listLength) {
                            case 7:
                                itemArray = [item1, item2, item3, item4, item5, item6, item7];
                                break;
                            case 6:
                                itemArray = [item1, item2, item3, item4, item5, item6];
                                break;
                            case 5:
                                itemArray = [item1, item2, item3, item4, item5];
                                break;
                            case 4:
                                itemArray = [item1, item2, item3, item4];
                                break;
                            case 3:
                                itemArray = [item1, item2, item3];
                                break;
                            case 2:
                                itemArray = [item1, item2];
                                break;
                            case 1:
                                itemArray = [item1];
                                break;
                            default:
                                itemArray = [item1];
                                break;
                        }
                        res.set('name', listName);
                        res.set('list', itemArray);
                        res.save()
                        .then((res2)=>{
                            if (listLength === props.row.values.lengthDisplay) {
                                const dataCopy = props.listData;
                                var thisList = dataCopy[props.row.index];
                                if (listName) {
                                    thisList.nameDisplay = listName;
                                    thisList.lengthDisplay = itemArray.length;
                                    thisList.listDisplay = itemArray.toString();
                                } 
                                dataCopy[props.row.index] = thisList;
                                props.setListData(dataCopy);
                                handleClose();
                            } else {
                                const query3 = new Parse.Query(Parse.Object.extend('Task'));
                                query3.equalTo('list', res2);
                                query3.equalTo('complete', false);
                                query3.find()
                                .then((res3) => {
                                    var checkArray = [];
                                    for (let i = 0; i < res2.length; i++) {
                                        checkArray[i] = false;
                                    }
                                    for (let j = 0; j < res3.length; j++) {
                                        const task = res3[j];
                                        task.set('check', checkArray);
                                        task.save()
                                        .then((res4) => {
                                        }).catch((error)=>{
                                            alert('Please send screenshot of this error message: ' + error.message);
                                            setSubmitString('Create Checklist');
                                        })
                                    }
                                    const dataCopy = props.listData;
                                    var thisList = dataCopy[props.row.index];
                                    if (listName) {
                                        thisList.nameDisplay = listName;
                                        thisList.lengthDisplay = itemArray.length;
                                        thisList.listDisplay = itemArray.toString();
                                    } 
                                    dataCopy[props.row.index] = thisList;
                                    props.setListData(dataCopy);
                                    handleClose();
                                }).catch((error)=>{
                                    alert('Please send screenshot of this error message: ' + error.message);
                                    setSubmitString('Create Checklist');
                                })
                            }
                        }).catch((error)=>{
                            alert('Please send screenshot of this error message: ' + error.message);
                            setSubmitString('Create Checklist');
                        })
                    }).catch((error)=>{
                        alert('Please send screenshot of this error message: ' + error.message);
                        setSubmitString('Create Checklist');
                    })
                }
            } else {
                alert('Error: Checklist with this name already exists');
                setSubmitString('Create Checklist');
            }
        }, (error) => {
            alert('Please send screenshot of this error message: ' + error.message);
            setSubmitString('Create Checklist');
        });
    }

    const handleAddListDisplay = (listRes, newListName, itemArray) => {
        const dataCopy = props.listData;
        var thisList = itemArray.toString();
        dataCopy.unshift({
            id: 0,
            idDisplay: listRes.id,
            listDisplay: thisList,
            lengthDisplay: itemArray.length,
            nameDisplay: newListName,
        });
        props.setListData(dataCopy);
    }

    const addItem = () => {
        if ((listLength > 0) && (listLength < 7)) {
            setListLength(listLength + 1);
        }
        
    }

    const removeItem = () => {
        if ((listLength > 1) && (listLength < 8)) {
            setListLength(listLength - 1);
        }
    }

    const handleItem1 = (e) => {
        let result = e.split(',').join('');
        setItem1(result)
    }

    const handleItem2 = (e) => {
        let result = e.split(',').join('');
        setItem2(result)
    }

    const handleItem3 = (e) => {
        let result = e.split(',').join('');
        setItem3(result)
    }

    const handleItem4 = (e) => {
        let result = e.split(',').join('');
        setItem4(result)
    }

    const handleItem5 = (e) => {
        let result = e.split(',').join('');
        setItem5(result)
    }

    const handleItem6 = (e) => {
        let result = e.split(',').join('');
        setItem6(result)
    }

    const handleItem7 = (e) => {
        let result = e.split(',').join('');
        setItem7(result)
    }

    return <>
        {!props.editMode && (
            <Button variant="outline-primary" onClick={handleShow}>
                New Checklist
                {/* <i className="fas fa-plus"></i> */}
            </Button> 
        )}
        {props.editMode && (
            <Button variant='outline-secondary' onClick={handleShow}>
                <i className="fas fa-pen"></i>
            </Button>
        )}
        <Modal show={show} onHide={handleClose} onExited={props.handleExit} centered backdrop="static"> 
            <Modal.Header className='modal-header' closeButton>
                <Modal.Title>New Checklist</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <Form onSubmit={handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group>
                            <Form.Label className='pl-4'>List Name</Form.Label>
                            <Form.Control
                                type="text"
                                size="sm"
                                autoFocus
                                value={listName}
                                placeholder=""
                                onChange={(e) => setListName(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group>
                            <Form.Label className='pl-4'>List Items</Form.Label>
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Col md={1} style={{textAlign:'right'}}>
                            1.
                        </Col>
                        <Col md={11}>
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    size="sm"
                                    value={item1}
                                    placeholder=""
                                    onChange={(e) => handleItem1(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {showItem2 && (
                        <Row className="mb-2">
                            <Col md={1} style={{textAlign:'right'}}>
                                2.
                            </Col>
                            <Col md={11}>
                                <Form.Group>
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        value={item2}
                                        placeholder=""
                                        onChange={(e) => handleItem2(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                    {showItem3 && (
                        <Row className="mb-2">
                            <Col md={1} style={{textAlign:'right'}}>
                                3.
                            </Col>
                            <Col md={11}>
                                <Form.Group>
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        value={item3}
                                        placeholder=""
                                        onChange={(e) => handleItem3(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                    {showItem4 && (
                        <Row className="mb-2">
                            <Col md={1} style={{textAlign:'right'}}>
                                4.
                            </Col>
                            <Col md={11}>
                                <Form.Group>
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        value={item4}
                                        placeholder=""
                                        onChange={(e) => handleItem4(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                    {showItem5 && (
                        <Row className="mb-2">
                            <Col md={1} style={{textAlign:'right'}}>
                                5.
                            </Col>
                            <Col md={11}>
                                <Form.Group>
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        value={item5}
                                        placeholder=""
                                        onChange={(e) => handleItem5(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                    {showItem6 && (
                        <Row className="mb-2">
                            <Col md={1} style={{textAlign:'right'}}>
                                6.
                            </Col>
                            <Col md={11}>
                                <Form.Group>
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        value={item6}
                                        placeholder=""
                                        onChange={(e) => handleItem6(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                    {showItem7 && (
                        <Row className="mb-2">
                            <Col md={1} style={{textAlign:'right'}}>
                                7.
                            </Col>
                            <Col md={11}>
                                <Form.Group>
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        value={item7}
                                        placeholder=""
                                        onChange={(e) => handleItem7(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col md={9}>
                            &nbsp;
                        </Col>
                        <Col md={1}>
                            {showPlus && (
                                <span>
                                <Button variant='outline-success' onClick={addItem}>
                                    <i className="fas fa-plus"></i>
                                </Button>&nbsp;</span>
                            )} 
                        </Col>
                        <Col md={1}>
                            {showMinus && (
                                <Button variant='outline-danger' onClick={removeItem}>
                                    <i className="fas fa-minus"></i>
                                </Button>
                            )} 
                        </Col>
                        <Col md={1}>
                            &nbsp;
                        </Col>
                    </Row>
                    <Row>
                        {itemWarning && (
                            <span>
                                <span className='form-warning'>
                                    Item text quite lengthy, consider making more succinct.
                                </span>
                            </span>
                        )}
                    </Row>
                    <Row>
                        {listWarning && (
                            <span>
                                <span className='form-warning'>
                                    Suggested total items is 6 or less.
                                </span>
                            </span>
                        )}
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                >
                    {submitString}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
