import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export default function IndicateManager(props) {


    if (props.hide === true) {
        return <>
            <div>
            </div>
        </>
    } else if (props.user === props.row.values.personDisplay) {
        return <>
            <OverlayTrigger
                placement='left'
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id={`tt-${props.row.values.id}`} className='team-tool'>
                        Pilot: {props.row.values.personNameDisplay}, Copilot: {props.row.values.secondNameDisplay}
                    </Tooltip>}
                >
                <div>
                    <i style={{color:'var(--text-muted)'}} className="fas fa-plane"></i>
                </div>
            </OverlayTrigger>
        </>
    } else {
        return <>
            <OverlayTrigger
                placement='left'
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id={`tt-${props.row.values.id}`} className='team-tool'>
                        Pilot: {props.row.values.personNameDisplay}, Copilot: {props.row.values.secondNameDisplay}
                    </Tooltip>}
                >
                <div>
                    <i style={{color:'var(--text-muted)'}} className="fas fa-user-friends"></i>
                </div>
            </OverlayTrigger>
        </>
    }
}
