import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Table, Breadcrumb, Button, Container, Badge } from 'react-bootstrap';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination, useAsyncDebounce } from 'react-table'
import EditProjectStatus from './EditProjectStatus';


export default function FindProject() {

    const [projects, setProjects] = useState([]);
    const [isProjectsLoaded, setIsProjectsLoaded] = useState(false);
    const [isProjectTableLoaded, setIsProjectTableLoaded] = useState(false);

    const Parse = require('parse');

    useEffect(() => {
        document.title = "Find Project";
    },[]);

    useEffect(() => {
        queryProjects();
    },[]);

    const queryProjects = () => {
        const Project = Parse.Object.extend('Project');
        const query = new Parse.Query(Project);
        query.limit(999999);
        query.includeAll();
        query.ascending('projectId');
        query.find()
        .then(function(res) {
            setProjects(res);
            setIsProjectsLoaded(true);
        }, (error) => {
            alert(error);
        });
    }

    const getProjectData = () => {

        var data2 = [];

        for (var i = 0; i < projects.length; i++) {
            const project = projects[i];
            var projId = project.get('projectId');

            data2.push({
                id: i,
                idDisplay: project.id,
                numberDisplay: projId,
                nameDisplay: project.get('name'),
                projDisplay: <Link target='_blank' to={'/staff/' + projId}>{projId} — {project.get('name')}</Link>,
                managerDisplay: project.get('managerId').attributes.firstName + ' ' + project.get('managerId').attributes.lastName,
                statusDisplay: project.get('status'),
            })
        }

        return data2;
    }

    const [projectData, setProjectData] = React.useState(React.useMemo(() => getProjectData, [isProjectsLoaded]));
    if (!isProjectTableLoaded) {
        if (projects.length > 0) {
            setProjectData(getProjectData);
            setIsProjectTableLoaded(true);
        }
    }

    const projectCols = React.useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'idDisplay',
                show: false,
            },
            {
                Header: '',
                accessor: 'nameDisplay',
                show: false,
            },
            {
                Header: '',
                accessor: 'numberDisplay',
                show: false,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Name</span>,
                accessor: 'projDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Pilot</span>,
                accessor: 'managerDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Status</span>,
                accessor: 'statusDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header: '',
                accessor: 'updateDisplay',
                Cell: ({ cell }) => {
                    return <>
                        <EditProjectStatus
                            row={cell.row}
                            cell={cell.value}
                            projectData={[...projectData]}
                            setProjectData={setProjectData}
                        />
                    </>
                },
            }
        ],
        [projectData]
    );
    
    const ProjectTable = () => {
        if(isProjectsLoaded) {
            return <>
                <TableView columns={projectCols} data={projectData} tableType='project'/>
            </>    
        } else if (!isProjectsLoaded) {
            return <Table><tbody><tr><td colSpan={1} style={{textAlign:'center'}}><em>Loading Projects...</em></td></tr></tbody></Table>
        } else {
            return <Table><tbody><tr><td colSpan={1} style={{textAlign:'center'}}>No projects found</td></tr></tbody></Table>
        }
    }

    function GlobalFilter({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter,
    }) {
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 200)
      
        return (
            <span>
                <input
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={'Search'}
                    style={{
                        fontSize: '1.1rem',
                        border: '0',
                        width: '100%',
                    }}
                />
            </span>
        )
    }

    function TableView ({ columns, data, tableType }) {

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            state: { pageIndex, pageSize },
            state,
            // setFilter,
            preGlobalFilteredRows,
            setGlobalFilter,
        } = useTable(
            {
                columns,
                data,
                initialState: {
                    pageIndex: 0,
                    pageSize: 50,
                    hiddenColumns: columns.map(column => {
                        if (column.show === false) {
                            return column.accessor || column.id;
                        }
                    }),
                },            
            },
            useFilters,
            useGlobalFilter,
            useSortBy,
            usePagination
        )

        return (
            <Container>

                <div>&nbsp;</div>
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
                <div>&nbsp;</div>

                <Table {...getTableProps()}>
                    
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                    {column.isSorted
                                    ? column.isSortedDesc
                                        ? <i className="fas fa-sort-down"></i>
                                        : <i className="fas fa-sort-up"></i>
                                    : ''}
                                </span>
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                            )
                        })}
                    </tbody>
                </Table>
                <div className="pagination">
                    <span>
                        <Button variant="outline-secondary" className='square-button' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            <i className="fas fa-angle-double-left"></i>
                        </Button>
                        &nbsp;
                        <Button variant="outline-secondary" className='square-button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                            <i className="fas fa-angle-left"></i>
                        </Button>
                        &nbsp;
                        <Button variant="outline-secondary" className='square-button' onClick={() => nextPage()} disabled={!canNextPage}>
                            <i className="fas fa-angle-right"></i>
                        </Button>
                        &nbsp;
                        <Button variant="outline-secondary" className='square-button' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            <i className="fas fa-angle-double-right"></i>
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                            Page&nbsp;
                                <strong>{pageIndex + 1}</strong> of {pageOptions.length}
                        </span>
                    </span>
                </div>
            </Container>
        )
    }

    return <>
        <Container>
            <div className="home-header">
                <h2>Find Project</h2>
            </div>
            <hr/>
            <div className="table-homepage">
                <div>
                    <ProjectTable />
                </div>
            </div>
        </Container>
    </>
}
