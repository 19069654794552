import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';

export default function ShowClientProjects(props) {

    const [show, setShow] = useState(false);
    const [name, setName] = useState();
    const [id, setId] = useState();
    const [clientProjects, setClientProjects] = useState();
    const [isClientProjectsLoaded, setIsClientProjectsLoaded] = useState(false);

    useEffect(()=>{
        setName(props.row.values.nameDisplay);
        setId(props.row.values.idDisplay);
    }, [show])

    const handleClose = () =>  {
        setShow(false);
        setName('');
        setId('');
        setClientProjects('');
        setIsClientProjectsLoaded(false);
    }

    const handleShow = () => {
        setShow(true);

        if (!isClientProjectsLoaded) {
            const Parse = require('parse');
            const clientQuery = new Parse.Query(Parse.User);
            clientQuery.get(id)
            .then(function(clientQueryRes) {
                const Project = Parse.Object.extend('Project');
                const query = new Parse.Query(Project);
                query.exclude(['internalNotes', 'statusHistory']);
                query.matchesQuery('users', clientQuery)
                query.limit(999999);
                query.ascending('projectId');
                query.find()
                .then(function(res) {
                    setClientProjects(res);
                    setIsClientProjectsLoaded(true);
                }, (error) => {
                    alert(error);
                });
            }, (error) => {
                alert(error);
            });
            
        }
    }

    const ClientProjectList = () => {
        var projLinks = 'Loading client projects';

        if (isClientProjectsLoaded) {
            if (clientProjects.length === 1) {
                var proj = clientProjects[0];
                projLinks = <Link to={'/staff/' + proj.attributes.projectId}>{proj.attributes.projectId + ' — ' +  proj.attributes.name}</Link>;
            } else {
                projLinks = [];
                for (let i = 0; i < clientProjects.length; i++) {
                    const proj = clientProjects[i];
                    projLinks.push(<span><Link to={'/staff/' + proj.attributes.projectId}>{proj.attributes.projectId + ' — ' +  proj.attributes.name}</Link><br /></span>);
                }
            }
        }        

        return <>
            {projLinks}
        </>
    }

    return <>
        <Button variant='outline-info' onClick={handleShow}>
            <i className="fas fa-project-diagram"></i>
        </Button>
        <Modal show={show} onHide={handleClose} centered backdrop="static">
            <Modal.Header closeButton className='modal-header'>
                <Modal.Title>{name} Projects</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <ClientProjectList />
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
