// Applies to all pages

import React, { useEffect, useState } from 'react';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
// import Home from './Home';
import Logout from './account/Logout';
import Task from './task/Task';
import Timesheet from './timesheet/Timesheet';
import Admin from './admin/Admin';
import Danger from './danger/Danger';
import Drafting from './drafting/Drafting';
import Account from './account/Account';
import Terms from './policies/Terms';
import Privacy from './policies/Privacy';
import ProjectView from './project-dashboard/ProjectView';
import KnowledgeBase from './knowledge-base/KnowledgeBase'
import ProjectDatabase from './find-project/ProjectDatabase'
import Checklist from './checklist/Checklist'
import User from './user-management/User'
import Staff from './staff-management/Staff'
import Logo from './LoteLogoHeader.png';
import { Navbar, Nav, NavDropdown, Container, Stack, Row, Form } from 'react-bootstrap';
import ResetPassword from './account/ResetPassword';
import PageNotFound404 from './PageNotFound404'
import history from '../utils/History';
import DarkModeToggle from "react-dark-mode-toggle";
import useDarkMode from 'use-dark-mode';

export default function Main() {

    const Parse = require('parse');

    Parse.initialize(
        process.env.REACT_APP_PARSE_APP_ID,
        process.env.REACT_APP_PARSE_JS_KEY
        );
    
    Parse.serverURL = process.env.REACT_APP_PARSE_API_URL;

    const currentUser = Parse.User.current();

    const [darkModeSwitch, setDarkModeSwitch] = useState(currentUser.attributes.darkMode);
    const darkMode = useDarkMode(currentUser.attributes.darkMode);

    const [userIsAdmin, setUserIsAdmin] = useState(false);
    const [isAdminLoaded, setIsAdminLoaded] = useState(false);
    const [userIsSuperAdmin, setUserIsSuperAdmin] = useState(false);

    const queryAdmin = () => {
        if (currentUser.id === 'WiGacaXnel' || currentUser.id === 'BKD0LlA2EC') {
            setUserIsAdmin(true);
        }
        if (currentUser.id === 'NS7ecaLkAU') {
            setUserIsAdmin(true);
            setUserIsSuperAdmin(true);
        }

        setIsAdminLoaded(true);
    }

    useEffect(() => {
        if (!isAdminLoaded) {
            queryAdmin(); 
        }
    },[isAdminLoaded])

    const handleDarkModeSwitch = () => {
        var newMode = !darkModeSwitch;
        const query = new Parse.Query(Parse.Object.extend('User'));
        query.get(currentUser.id)
        .then((user) => {
            user.set('darkMode', newMode);
            user.save()
            .then((res)=>{
                setDarkModeSwitch(newMode);
                if (newMode) {
                    darkMode.enable();
                } else {
                    darkMode.disable();
                }
            }).catch((error)=>{
                alert('Please send screenshot of this error message: ' + error.message);
            })
        }).catch((error)=>{
            alert('Please send screenshot of this error message: ' + error.message);
        })
    }

    return ( 
        <Router history={history}>
            <div className="background">
                <header>
                    <Navbar collapseOnSelect expand="lg" className='lote-navbar' bg="light" variant="light">
                        <Container>
                            <Navbar.Brand href="/staff/tasks"> 
                                <img src={Logo} className="logo" alt="Lote Consulting" />
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="me-auto">
                                    <Nav.Link href="/staff/tasks">Tasks & Projects</Nav.Link>
                                    <Nav.Link href="/staff/checklists" target="_blank">Checklists</Nav.Link>
                                    <Nav.Link href="/staff/knowledge-base" target="_blank">Knowledge Base</Nav.Link>
                                    <Nav.Link href="/staff/clients" target="_blank">Clients</Nav.Link>
                                    <Nav.Link href="/staff/timesheets" target="_blank">Timesheets</Nav.Link>
                                    <NavDropdown title="More" id="basic-nav-dropdown">
                                        <NavDropdown.Item href="/staff/team" target="_blank">
                                            Lote Team
                                        </NavDropdown.Item>
                                        <NavDropdown.Item href="/staff/find-project" target="_blank">
                                            Find Project
                                        </NavDropdown.Item>
                                        {userIsAdmin && (
                                            <NavDropdown.Item href="/staff/admin" target="_blank">
                                                Admin
                                            </NavDropdown.Item>
                                        )}
                                        <NavDropdown.Item href="https://tasks.office.com/loteconsulting.com/Home/PlanViews/oifP-KcHTEWAKni_1fnwbAgAECNQ" target="_blank">
                                            Portal Roadmap
                                        </NavDropdown.Item>
                                        <NavDropdown.Item href="/account" target="_blank">
                                            Account
                                        </NavDropdown.Item>
                                        {userIsSuperAdmin && (
                                            <NavDropdown.Item href="/staff/drafting" target="_blank">
                                                Drafting
                                            </NavDropdown.Item>
                                        )}
                                        {userIsSuperAdmin && (
                                            <NavDropdown.Item href="/staff/danger" target="_blank">
                                                Danger
                                            </NavDropdown.Item>
                                        )}
                                    </NavDropdown>                                    
                                </Nav>
                                <Nav>
                                    <Stack direction="horizontal" gap={3} >
                                        <div>
                                            {!userIsSuperAdmin && (
                                                <DarkModeToggle
                                                    onChange={handleDarkModeSwitch}
                                                    checked={darkModeSwitch}
                                                    size={40}
                                                />
                                            )}
                                            {userIsSuperAdmin && (
                                                <Form>
                                                    <Stack direction='horizontal' gap={1}>
                                                        <Form.Check 
                                                            type="switch"
                                                            onChange={handleDarkModeSwitch}
                                                            checked={darkModeSwitch}
                                                        />
                                                        {!darkModeSwitch && (
                                                            <i className="far fa-sun" style={{color:'var(--gold)'}}></i>
                                                        )}
                                                        {darkModeSwitch && (
                                                            <i className="far fa-moon" style={{color:'var(--silver)'}}></i>
                                                        )}
                                                    </Stack>
                                                </Form>
                                            )}
                                        </div>
                                        <div>
                                        <Logout />
                                        </div>
                                    </Stack>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </header>
                <div className="content mt-4">
                    <Switch>
                        <Route 
                            exact
                            path="/"
                            render={() => {
                                return (
                                    <Redirect to="/staff/tasks"/>
                                )
                            }}
                        />
                        {/* <Route path="/staff" component={Home} exact /> */}
                        <Route path="/staff/tasks" component={Task} exact />
                        <Route path="/staff/clients" component={User} exact />
                        <Route path="/staff/team" component={Staff} exact />
                        <Route path="/staff/checklists" component={Checklist} exact />
                        <Route path="/staff/find-project" component={ProjectDatabase} exact />
                        <Route path="/staff/knowledge-base" component={KnowledgeBase} exact />
                        <Route path="/staff/timesheets" component={Timesheet} exact />
                        <Route path="/staff/admin" component={Admin} exact />
                        <Route path="/staff/drafting" component={Drafting} exact />
                        <Route path="/staff/danger" component={Danger} exact />
                        <Route path="/account" component={Account} exact />
                        <Route path="/account/password" component={ResetPassword} exact />
                        <Route path="/terms" component={Terms} exact />
                        <Route path="/privacy" component={Privacy} exact />
                        <Route path="/staff/:id" component={ProjectView} exact />
                        <Route component={PageNotFound404} />
                    </Switch>
                </div>
                <footer>
                    <Row className='mb-4'>
                        
                    </Row>
                    <Navbar bg="light" variant="light" className='lote-navbar'>
                        <Container>
                            <Navbar.Text>
                                &copy; Lote Consulting Pty Ltd {new Date().getFullYear()}
                            </Navbar.Text>
                            <Nav>
                                <Nav.Link href="mailto:portal@loteconsulting.com" target='_blank'>portal@loteconsulting.com</Nav.Link>
                                <Nav.Link href="/terms" target='_blank'>Terms of Use</Nav.Link>
                                <Nav.Link href="/privacy" target='_blank'>Privacy Policy</Nav.Link>
                                <Nav.Link href="https://www.loteconsulting.com/" target='_blank'>Lote Website</Nav.Link>
                            </Nav>
                        </Container>
                    </Navbar>
                </footer>
            </div>
        </Router>
    );
}
