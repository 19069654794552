import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import history from '../../utils/History';

export default function Admin() {

    useEffect(() => {
        document.title = "Portal – Admin";
    }, []);

    const Parse = require('parse');
    const currentUser = Parse.User.current();

    const [userIsAdmin, setUserIsAdmin] = useState(false);

    const queryAdmin = () => {
        if (currentUser.id === 'WiGacaXnel' || currentUser.id === 'BKD0LlA2EC' || currentUser.id === 'NS7ecaLkAU') {
            setUserIsAdmin(true);
        } else {
            history.push('/');
            history.go(0);
        }
    }

    useEffect(() => {
        queryAdmin(); 
    },[])

    return <>
        {userIsAdmin && (
            <Container>
                <div className="home-header">
                    <h2>Admin Panel</h2>
                </div>
                <hr/>
                Admin view coming soon.
            </Container>
        )}
    </>
}
