import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Alert } from 'react-bootstrap';


export default function PdfUpload(props) {

    const onDrop = useCallback(acceptedFiles => {
        props.file(acceptedFiles);
      }, [props])

    const {
        getRootProps,
        getInputProps,
        fileRejections,
    } = useDropzone({
        // accept: 'application/pdf',
        maxSize: 20971520,
        onDrop
    });

    function convertToMb (bytes) {
        return (bytes / 1000 / 1000).toFixed(2);
    }

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
            <ul>
                {errors.map(e => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));

    const FileText = () => {
        if(props.list.length > 0) {
            const files = props.list.map(file => (
                <li key={file.path}>
                    {file.path} - {convertToMb(file.size)} Mb
                </li>
              ));
            return <>
                <span>File to upload:</span>
                {files}
            </>;
        } else if (fileRejectionItems.length > 0) {
            return <>
                <Alert variant="danger" size->
                    <h5>Error</h5>
                    <ul>{fileRejectionItems}</ul>
                </Alert>                
            </>;
        } else {
            return <span></span>
        }
    }

    return (
        <section>
            <div {...getRootProps({className: 'dropzone mb-3'})}>
                <input {...getInputProps()} />
                <p>Drag file here, or click to select file.</p>
                {/* <em>(Only pdf files up to 20 Mb will be accepted)</em> */}
            </div>
            <aside>
                <FileText />
            </aside>
        </section>
    );
}
