import { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';

export default function ShowOrgUsers(props) {

    const [show, setShow] = useState(false);
    const [name, setName] = useState();
    const [userList, setUserList] = useState();

    useEffect(()=>{
        setName(props.row.values.nameDisplay);
    }, [show])

    const handleClose = () =>  {
        setShow(false);
        setName('');
        setUserList('');
    }

    const handleShow = () => {
        setShow(true);
        const dataCopy = props.userData;
        var userListResult = '';
        for (let i = 0; i < dataCopy.length; i++) {
            const user = dataCopy[i];
            if (user.organisationDisplay === name) {
                userListResult += user.nameDisplay + ' ';
            }
        }
        setUserList(userListResult);
    }

    const OrgUserList = () => {
        return <>
            {userList}
        </>
    }

    return <>
        <Button variant='outline-info' onClick={handleShow}>
            <i className="fas fa-users"></i>
        </Button>
        <Modal show={show} onHide={handleClose} centered backdrop="static">
            <Modal.Header closeButton className='modal-header'>
                <Modal.Title>{name} Users</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <OrgUserList />
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
