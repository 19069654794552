import { map } from 'jquery';
import React, { useState, useEffect} from 'react';
import { Table, Breadcrumb, Button, Container, Badge, Row, Col } from 'react-bootstrap';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination, useAsyncDebounce } from 'react-table'

export default function Staff() {

    const Parse = require('parse');

    const [staff, setStaff] = useState([]);
    const [security, setSecurity] = useState([]);
    const [fire, setFire] = useState([]);
    const [isStaffLoaded, setIsStaffLoaded] = useState(false);
    const [isStaffTableLoaded, setIsStaffTableLoaded] = useState(false);

    const [timesheets, setTimesheets] = useState();
    const [isTimesheetsLoaded, setIsTimesheetsLoaded] = useState(false);

    useEffect(() => {
        document.title = "Lote Team";
        if (!isStaffLoaded) {
            querySecurity();
            queryFire();
            queryStaff();
            queryTimesheets();
        }
    },[]);

    const queryStaff = () => {
        const staffQuery = new Parse.Query(Parse.Role);
        staffQuery.equalTo('name', 'staff');
        staffQuery.first()
        .then(function(staffQueryRes) {
            const staff = staffQueryRes.getUsers().query();
            staff.equalTo('status', 'Active');
            staff.ascending('firstName');
            staff.find()
            .then((userQueryRes) => {
                setStaff(userQueryRes);
                setIsStaffLoaded(true);
            }, (error) => {
                alert(error);
            })
        }, (error) => {
            alert(error);
        });
    }

    const querySecurity = () => {
        const staffQuery = new Parse.Query(Parse.Role);
        staffQuery.equalTo('name', 'security');
        staffQuery.first()
        .then(function(staffQueryRes) {
            const staff = staffQueryRes.getUsers().query();
            staff.equalTo('status', 'Active');
            staff.find()
            .then((userQueryRes) => {
                var sec = [];
                userQueryRes.forEach(user => {
                    sec.push(user.id);
                });
                setSecurity(sec);
            }, (error) => {
                alert(error);
            })
        }, (error) => {
            alert(error);
        });
    }

    const queryFire = () => {
        const staffQuery = new Parse.Query(Parse.Role);
        staffQuery.equalTo('name', 'fire');
        staffQuery.first()
        .then(function(staffQueryRes) {
            const staff = staffQueryRes.getUsers().query();
            staff.equalTo('status', 'Active');
            staff.find()
            .then((userQueryRes) => {
                var fir = [];
                userQueryRes.forEach(user => {
                    fir.push(user.id);
                });
                setFire(fir);
            }, (error) => {
                alert(error);
            })
        }, (error) => {
            alert(error);
        });
    }

    const queryTimesheets = () => {
        const Timesheet = Parse.Object.extend('Timesheet');
        const timesheetQuery = new Parse.Query(Timesheet);
        timesheetQuery.equalTo('active', true);
        timesheetQuery.find()
        .then(function(res) {
            setTimesheets(res);
            setIsTimesheetsLoaded(true);
        }, (error) => {
            alert(error);
        });
    }

    const getStaffData = () => {

        var data2 = [];

        for (var i = 0; i < staff.length; i++) {
            const user = staff[i];

            var teamBadge = '';
            var sec = false;
            var fir = false;

            if (security.includes(user.id)) {
                sec = true;
            }

            if (fire.includes(user.id)) {
                fir = true;
            }


            if (sec && fir) {
                teamBadge = <span><h6><Badge pill bg="danger">Fire <i className="fas fa-fire"/></Badge></h6><span>&nbsp;&nbsp;&nbsp;&nbsp;</span><h6><Badge pill bg="info">Security/Marketing <i className="fas fa-lock"/>&nbsp;<i class="fas fa-rocket"></i></Badge></h6></span>;
            } else if (sec) {
                teamBadge = <span><h6><Badge pill bg="info">Security/Marketing <i className="fas fa-lock"/>&nbsp;<i class="fas fa-rocket"></i></Badge></h6></span>;
            } else if (fir) {
                teamBadge = <span><h6><Badge pill bg="danger">Fire <i className="fas fa-fire"/></Badge></h6></span>;
            } else {
                teamBadge = '';
            }

            data2.push({
                id: i,
                // idDisplay: user.id,
                nameDisplay: user.get('firstName') + ' ' + user.get('lastName'),
                phoneDisplay: user.get('phone'),
                emailDisplay: user.get('username'),
                teamDisplay: teamBadge,
            })
        }

        return data2;
    }

    const [staffData, setStaffData] = React.useState(React.useMemo(() => getStaffData, [isStaffLoaded]));
    if (!isStaffTableLoaded) {
        if (staff.length > 0) {
            setStaffData(getStaffData);
            setIsStaffTableLoaded(true);
        }
    }

    const staffCols = React.useMemo(
        () => [
            // {
            //     Header: 'User Id',
            //     accessor: 'idDisplay',
            //     show: false,
            // },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Name</span>,
                accessor: 'nameDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Email</span>,
                accessor: 'emailDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Phone</span>,
                accessor: 'phoneDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Team</span>,
                accessor: 'teamDisplay',
            },
        ],
        [staffData]
    );
    
    const StaffTable = () => {
        if(isStaffLoaded) {
            return <>
                <TableView columns={staffCols} data={staffData} tableType='staff'/>
            </>    
        } else if (!isStaffLoaded) {
            return <Table><tbody><tr><td colSpan={1} style={{textAlign:'center', color:'var(--text)'}}><em>Loading Lote Team...</em></td></tr></tbody></Table>
        } else {
            return <Table><tbody><tr><td colSpan={1} style={{textAlign:'center', color:'var(--text)'}}>No team members found</td></tr></tbody></Table>
        }
    }

    function GlobalFilter({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter,
    }) {
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 200)
      
        return (
            <span>
                <input
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={'Search'}
                    style={{
                        fontSize: '1.1rem',
                        border: '0',
                        width: '100%',
                    }}
                />
            </span>
        )
    }

    function TableView ({ columns, data, tableType }) {

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            state: { pageIndex, pageSize },
            state,
            // setFilter,
            preGlobalFilteredRows,
            setGlobalFilter,
        } = useTable(
            {
                columns,
                data,
                initialState: {
                    pageIndex: 0,
                    pageSize: 10000,
                    hiddenColumns: columns.map(column => {
                        if (column.show === false) {
                            return column.accessor || column.id;
                        }
                    }),
                },            
            },
            useFilters,
            useGlobalFilter,
            useSortBy,
            usePagination
        )

        return (
            <Container>

                <div>&nbsp;</div>
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
                <div>&nbsp;</div>

                <Table {...getTableProps()}>
                    
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                    {column.isSorted
                                    ? column.isSortedDesc
                                        ? <i className="fas fa-sort-down"></i>
                                        : <i className="fas fa-sort-up"></i>
                                    : ''}
                                </span>
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                            )
                        })}
                    </tbody>
                </Table>
                {/* <div className="pagination">
                    <span>
                        <Button variant="outline-secondary" className='square-button' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            <i className="fas fa-angle-double-left"></i>
                        </Button>
                        &nbsp;
                        <Button variant="outline-secondary" className='square-button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                            <i className="fas fa-angle-left"></i>
                        </Button>
                        &nbsp;
                        <Button variant="outline-secondary" className='square-button' onClick={() => nextPage()} disabled={!canNextPage}>
                            <i className="fas fa-angle-right"></i>
                        </Button>
                        &nbsp;
                        <Button variant="outline-secondary" className='square-button' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            <i className="fas fa-angle-double-right"></i>
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                            Page&nbsp;
                                <strong>{pageIndex + 1}</strong> of {pageOptions.length}
                        </span>
                    </span>
                </div> */}
            </Container>
        )
    }

    const CityStaff = () => {
        var disp = [];
        timesheets.forEach(sheet => {
            if (sheet.attributes.location === 'City') {
                var thisString = sheet.attributes.user.attributes.firstName + ' ' + sheet.attributes.user.attributes.lastName;
                disp.push(thisString);
            }
        });    

        const names = disp.map((name, index)=> 
            <div key={index}>{name}</div>
        )
        return <div>{names}</div>
    }

    const ParraStaff = () => {
        var disp = [];
        timesheets.forEach(sheet => {
            if (sheet.attributes.location === 'Parramatta') {
                var thisString = sheet.attributes.user.attributes.firstName + ' ' + sheet.attributes.user.attributes.lastName;
                disp.push(thisString);
            }
        });    

        const names = disp.map((name, index)=> 
            <div key={index}>{name}</div>
        )
        return <div>{names}</div>
    }

    const OtherStaff = () => {
        var disp = [];
        timesheets.forEach(sheet => {
            if (sheet.attributes.location !== 'City' && sheet.attributes.location !== 'Parramatta') {
                var thisString = sheet.attributes.user.attributes.firstName + ' ' + sheet.attributes.user.attributes.lastName;
                disp.push(thisString);
            }
        });    

        const names = disp.map((name, index)=> 
            <div key={index}>{name}</div>
        )
        return <div>{names}</div>
    }

    const LocationToday = () => {
        if (isTimesheetsLoaded) {
            return <>
                <Row>
                    <Col md={4}>
                        <h4>City</h4>
                    </Col>
                    <Col md={4}>
                        <h4>Parramatta</h4>
                    </Col>
                    <Col md={4}>
                        <h4>Remote</h4>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <CityStaff />
                    </Col>
                    <Col md={4}>
                        <ParraStaff />
                    </Col>
                    <Col md={4}>
                        <OtherStaff />
                    </Col>
                </Row>
            </>
        } else {
            return <>
                <em>Loading team locations...</em>
            </>
        }
        
    }

    return <>
        {/* <Container>
            <Breadcrumb className='container'>
                <Breadcrumb.Item href='/staff'>Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Lote Team</Breadcrumb.Item>
            </Breadcrumb>
        </Container> */}
        <Container>
            <div className="home-header">
                <h3>Currently Active</h3>
            </div>
            <hr/>
            <div className="table-homepage">
                <div>
                    <LocationToday />
                </div>
            </div>
            <hr/>
            <div className="home-header">
                <h3>Lote Team</h3>
            </div>
            <hr/>
            <div className="table-homepage">
                <div>
                    <StaffTable />
                </div>
            </div>
        </Container>
    </>
}
