import React, { useEffect } from 'react';
import { Breadcrumb, Container } from 'react-bootstrap';

export default function Privacy() {

    useEffect(() => {
        document.title = "Lote Portal – Privacy Policy";
    }, []);

    return <>
        {/* <Container>
            <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active >Privacy Policy</Breadcrumb.Item>
            </Breadcrumb>
        </Container> */}
        <Container>
            <div className="home-header">
                <h2>Privacy Policy</h2>
            </div>
            <hr/>
            <div className="container">
                <p>Content</p>
            </div>
        </Container>
    </>
}
