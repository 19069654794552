import { useState, useEffect } from 'react';
import { Button, Form, Modal, Spinner, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import moment from 'moment';
import 'moment/locale/en-au';
import { customStyles } from '../../data/Data.js'
import axios from 'axios';

export default function AddProject(props) {

    const Parse = require('parse');

    const [show, setShow] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [projectNumber, setProjectNumber] = useState('');
    const [projectName, setProjectName] = useState('');
    const [projectManager, setProjectManager] = useState();
    const [projectSecond, setProjectSecond] = useState();
    const [submitString, setSubmitString] = useState('Create Project');
    const [isDisabled, setIsDisabled] = useState(true);
    const [firstSet, setFirstSet] = useState(false);

    useEffect(()=>{
        handleProjectManagerDefault(props.projectManagerOptions);
        setProjectNumber(props.nextProjectNumber.toString());
    }, [props.projectManagerOptions])

    const handleProjectManagerDefault = (options) => {
        setProjectManager(options[0]);
    }

    useEffect(()=> {
        if ((projectNumber.length === 6) && (checkProjNum(projectNumber))) {
        // if ((projectNumber.length === 6) && (checkProjNum(projectNumber)) && (projectManager !== projectSecond)) {
            setErrorText('');
            (!projectNumber || !projectName || !projectManager || !projectSecond) ? setIsDisabled(true) : setIsDisabled(false);
        } else if (projectNumber.length !== 6) {
            setErrorText('Project number must be six digits');
            setIsDisabled(true);
        } 
        // else if (projectManager === projectSecond) {
        //     setErrorText('Pilot and copilot cannot be same');
        //     setIsDisabled(true);
        // } 
        else {
            setErrorText('Project number already exists');
            setIsDisabled(true);
        }
    }, [projectNumber, projectName, projectManager, projectSecond]);

    const checkProjNum = (projNum) => {
        var sender = true;
        for (let i = 0; i < props.projectData.length; i++) {
            if (props.projectData[i].projectNumberDisplay == projNum) {
                sender =  false;
            }
        }
        return sender;
    }

    const handleClose = () =>  {
        setShow(false);
        setErrorText('');
        setProjectNumber('');
        setProjectName('');
        handleProjectManagerDefault(props.projectManagerOptions);
        setProjectSecond();
        setSubmitString('Create Project');
        setFirstSet(false);
    }

    const handleShow = () => {
        setShow(true);
        setProjectNumber(props.nextProjectNumber.toString());
    }
      
    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitString(<>
            Creating&nbsp;
            <Spinner
                as="span"
                animation="border"
                size="sm"
            />
        </>);

        const Project = Parse.Object.extend('Project');
        const newProject = new Project();
        const query = new Parse.Query(Project);
        query.equalTo('projectId', parseInt(projectNumber));
        query.find()
        .then(function(res) {
            if (res.length < 1 && projectNumber.length === 6) {

                newProject.set('projectId', parseInt(projectNumber));

                if (!projectManager) {
                    alert('Please select pilot.')
                    return 0;
                } else {
                    newProject.set('managerId', projectManager.object);
                }

                if (!projectSecond) {
                    alert('Please select copilot.')
                    return 0;
                } else {
                    newProject.set('secondId', projectSecond.object);
                }

                if (!projectName) {
                    alert('Please set project name.')
                    return 0;
                } else {
                    newProject.set('name', projectName);
                }

                newProject.set('createdBy', props.currentUser);
                newProject.set('status', 'Quoting');
                newProject.set('organisationId', props.dummyOrg);
                const thisYear = new Date().getFullYear();
                newProject.set('year', thisYear);

                const today = new Date();
                const twodays = new Date(today);
                twodays.setDate(twodays.getDate() + 2);
                newProject.set('deadline', twodays);

                var projectACL = new Parse.ACL();
                projectACL.setRoleReadAccess('staff', true);
                projectACL.setRoleWriteAccess('staff', true);
                newProject.setACL(projectACL);

                newProject.save().then((projectRes) => {
                    projectRes.relation('users').add(props.dummyUser);
                    projectRes.save().then((newProjectRes)=>{
                        if (props.taskMode) {
                            var newProjectOptions = props.localProjectOptions;
                            var newProjectItem = {
                                "value" : newProjectRes.id,
                                "label" : projectNumber + ' — ' + projectName,
                                "object" : newProjectRes,
                                "number" : projectNumber,
                            };
                            newProjectOptions.unshift(newProjectItem);
                            props.setLocalProjectOptions(newProjectOptions);
                            props.handleProjectSelect(newProjectItem);
                        }
                        if (projectManager.object !== undefined) {
                            if ((projectManager.object.id !== props.currentUser.id) && (projectManager.object.attributes.slackNotifHook !== undefined)) {
                                const url = projectManager.object.attributes.slackNotifHook;
                                var userString = '';
                                if (projectManager.object.attributes.slackUserId.length > 0) {
                                    userString = '<@' + projectManager.object.attributes.slackUserId + '>';
                                } else {
                                    userString = projectManager.object.attributes.firstName + ' ' + projectManager.object.attributes.lastName;
                                }
                                const data = { text: 'Hi '+ userString + ' ' + 
                                    props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + 
                                    ' has assigned you a project:\n' + '<https://portal.loteconsulting.com/staff/' + 
                                    projectNumber + '|' + projectNumber + ' — ' + projectName + '>'};
            
                                axios.post(url, JSON.stringify(data), {
                                    withCredentials: false,
                                    transformRequest: [
                                        (data, headers) => {
                                            return data;
                                        },
                                    ],
                                });
                            }
                        }
                        if (projectSecond.object !== undefined) {
                            if ((projectSecond.object.id !== props.currentUser.id) && (projectSecond.object.attributes.slackNotifHook !== undefined) && (projectSecond.object.id !== projectManager.object.id)) {
                                const url = projectSecond.object.attributes.slackNotifHook;
                                var userString = '';
                                if (projectSecond.object.attributes.slackUserId.length > 0) {
                                    userString = '<@' + projectSecond.object.attributes.slackUserId + '>';
                                } else {
                                    userString = projectSecond.object.attributes.firstName + ' ' + projectSecond.object.attributes.lastName;
                                }
                                const data = { text: 'Hi '+ userString + ' ' + 
                                    props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + 
                                    ' has set you as copilot for a project:\n' + '<https://portal.loteconsulting.com/staff/' + 
                                    projectNumber + '|' + projectNumber + ' — ' + projectName + '>'};
            
                                axios.post(url, JSON.stringify(data), {
                                    withCredentials: false,
                                    transformRequest: [
                                        (data, headers) => {
                                            return data;
                                        },
                                    ],
                                });
                            }
                        }
                        // createSlackChannel(projectName, projectNumber, projectManager, projectSecond);
                        window.open('https://forms.office.com/Pages/ResponsePage.aspx?id=s7vl7bsdK0u5roqdJ5kw132Yt_zARDFCrA9KrbI6BXBUNzBUQzM1VDlHNkMwRDdaUk9KR0QxVkdBWS4u','_newtab' + Date.now());
                        handleClose();
                    }).catch((error)=> {
                        alert('Please send screenshot of this error message: ' + error.message);
                        setSubmitString('Create Project');
                    })
                }).catch((error)=> {
                    alert('Please send screenshot of this error message: ' + error.message);
                    setSubmitString('Create Project');
                })
            } else if (res.length >= 1) {
                alert('Error: This project number already exists. Look for the project in the database!');
                setSubmitString('Create Project');
            } else {
                alert('Error: Project number incorrect format');
                setSubmitString('Create Project');
            }
        }, (error) => {
            alert('Please send screenshot of this error message: ' + error.message);
            setSubmitString('Create Project');
        });
    }

    // async function createSlackChannel (projname, projnumber, projmanager, projsecond) {
    //     const { WebClient } = require('@slack/web-api');
    //     const token = process.env.REACT_APP_SLACK_WEBHOOK_URL;
    //     const web = new WebClient(token);
    //     const namestring = projnumber + '-' + projname.toLowerCase();
    //     try {
    //         const result = await web.conversations.create({
    //             name: namestring,
    //             is_private: true
    //         });
    //         console.log(result);
    //         const chanString = result. channel.id;
    //         const userString = projmanager.object.attributes.slackUserId + "," + projsecond.object.attributes.slackUserId;
    //         const result2 = await web.conversations.invite({
    //             channel: chanString,
    //             users: userString
    //         });
    //         console.log(result2);
    //     }
    //     catch (error) {
    //         console.error(error);
    //     }
    // }

    function handleProjectManager(e) {
        setProjectManager(e);
        if (!firstSet) {
            setProjectSecond(e);
            setFirstSet(true);
        }
    }

    return <>
        {(!props.taskMode && props.noText && !props.fromDashboard) && (
            <Button onClick={handleShow}>
                <i className="fas fa-plus"></i>
            </Button>
        )}
        {(!props.taskMode && props.noText && props.fromDashboard) && (
            <Button variant="outline-primary" className='inline-button' onClick={handleShow}>
                <i className="fas fa-plus"></i>
            </Button>
        )}
        {(!props.taskMode && !props.noText) && (
            <Button onClick={handleShow}>
                New Fee
            </Button>
        )}
        {(props.taskMode && !props.noText) && (
            <Button variant="outline-primary" onClick={handleShow}>
                <i className="fas fa-plus"></i>
            </Button> 
        )}
        {(props.taskMode && props.noText) && (
            <Button variant="outline-primary" className='inline-button' onClick={handleShow}>
                <i className="fas fa-plus"></i>
            </Button> 
        )}
        <Modal show={show} onHide={handleClose} onExited={props.handleExit} centered backdrop="static"> 
            <Modal.Header className='modal-header' closeButton>
                <Modal.Title>New Fee</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <Form onSubmit={handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group>
                            <Form.Label className='pl-4'>Project Number</Form.Label>
                            <NumberFormat
                                className="form-control"
                                placeholder=""
                                onChange={(e) => setProjectNumber(e.target.value)}
                                value={projectNumber}
                                displayType="input"
                                type="text"
                                autoFocus
                                id='form-project-number'
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group>
                            <Form.Label className='pl-4'>Project Name</Form.Label>
                            <Form.Control
                                id='form-project-name'
                                type="text"
                                size="sm"
                                autoFocus
                                value={projectName}
                                placeholder=""
                                onChange={(e) => setProjectName(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group>
                            <Form.Label className='pl-4'>Pilot</Form.Label>
                            <Select
                                styles={customStyles}
                                id='form-project-manager'
                                options={props.projectManagerOptions}
                                onChange={(e)=>handleProjectManager(e)}
                                value={projectManager}
                                defaultValue={projectManager}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group>
                            <Form.Label className='pl-4'>Copilot</Form.Label>
                            <Select
                                styles={customStyles}
                                id='form-project-manager'
                                options={props.projectManagerOptions}
                                onChange={(e)=>setProjectSecond(e)}
                                value={projectSecond}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <div className='form-fail'>
                            {errorText}
                        </div>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                >
                    {submitString}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
