import { useState, useEffect } from 'react';
import { Button, Form, Modal, FloatingLabel } from 'react-bootstrap';
import Select from 'react-select';
import { knowledgeCatOptions, customStyles } from '../../data/Data';

export default function AddKnowledgeCard(props) {

    const [show, setShow] = useState(false);
    const [heading, setHeading] = useState('');
    const [keywords, setKeywords] = useState('');
    const [type, setType] = useState('link');
    const [answer, setAnswer] = useState('n/a');
    const [url, setUrl] = useState('');
    const [category, setCategory] = useState([]);

    const [headingText, setHeadingText] = useState('Heading');
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(()=> {
        (!heading || !type || !answer || !url || !category) ? setIsDisabled(true) : setIsDisabled(false)
    }, [heading, type, answer, url, category]);

    const handleClose = () =>  {
        setHeading('');
        setKeywords('');
        setType('link');
        setAnswer('n/a');
        setUrl('');
        setCategory([]);
        setShow(false);
    }

    const handleShow = () => {
        setShow(true);
    }

    const isValidHttpUrl = (string) => {
        let url;
        
        try {
            url = new URL(string);
        } catch (_) {
            return false;  
        }
      
        return url.protocol === "http:" || url.protocol === "https:";
    }
      
    const handleSubmit = (e) => {

        e.preventDefault();

        if ((isValidHttpUrl(url)) || (url === 'n/a')) {
            document.getElementById('url-invalid').classList.remove('toggle');
            document.getElementById('url-invalid').classList.add('none');

            const Parse = require('parse');

            const Knowledge = Parse.Object.extend('Knowledge');
            const knowledge = new Knowledge();

            knowledge.set('createdBy', Parse.User.current());
            knowledge.set('heading', heading);
            knowledge.set('keywords', keywords);
            knowledge.set('type', type);
            knowledge.set('answer', answer);
            knowledge.set('url', url);
            knowledge.set('category', category);

            var knowledgeACL = new Parse.ACL();
            knowledgeACL.setRoleReadAccess('staff', true);
            knowledgeACL.setRoleWriteAccess('staff', true);
            knowledge.setACL(knowledgeACL);

            knowledge.save()
            .then((res)=> {
                const dataCopy = props.knowledge;
                dataCopy.push({
                    rowKey: props.knowledge.length,
                    id: res.id,
                    heading: heading,
                    keywords: keywords,
                    type: type,
                    answer : answer,
                    url : url,
                    category : category,
                })
                props.setKnowledge(dataCopy);
                props.renderCards();
                var contributions = props.userContributions;
                contributions++;
                props.setUserContributions(contributions);
                handleClose();
                props.setShowAlert(true);
                props.fadeAlert();
            }, (error) => {
                alert('Please send screenshot of this error message: ' + error.message);
            });            
        } else {
            document.getElementById('url-invalid').classList.remove('none');
            document.getElementById('url-invalid').classList.add('toggle');
        }
    }

    const typeOptions = [
        { value: 'link', label: 'Link'},
        { value: 'response', label: 'Response / Q&A'},
    ]

    const handleTypeChange = (e) => {
        setType(e);
        switch (e) {
            case 'link':
                setAnswer('n/a');
                setUrl('');
                setHeadingText('Heading');
                document.getElementById('answer-field').classList.remove('toggle');
                document.getElementById('answer-field').classList.add('none');
                document.getElementById('url-field').classList.remove('none');
                document.getElementById('url-field').classList.add('toggle');
                break;
            case 'response':
                setAnswer('');
                setUrl('n/a');
                setHeadingText('Heading / Question');
                document.getElementById('url-field').classList.remove('toggle');
                document.getElementById('url-field').classList.add('none');
                document.getElementById('answer-field').classList.remove('none');
                document.getElementById('answer-field').classList.add('toggle');
                break;
            default:
                break;
        }
    }

    return <>
        <Button onClick={handleShow}> 
            Add Card
            {/* <i className="fas fa-plus"></i> */}
        </Button>
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton className='modal-header'>
                <Modal.Title>Add Knowledge Card</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">  
                        <Form.Label>Type</Form.Label>      
                        <Select 
                            styles={customStyles}
                            options={typeOptions}
                            defaultValue={typeOptions[0]}
                            onChange={(e)=>handleTypeChange(e.value)}
                        /> 
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{headingText}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder='Heading'
                            onChange={(e)=>setHeading(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3 none" id='answer-field'>
                        <Form.Label>Response</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder='Response'
                            onChange={(e)=>setAnswer(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" id='url-field'>  
                        <Form.Label>Url</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder='https://www.loteconsulting.com/'
                                onChange={(e)=>setUrl(e.target.value)}
                            />
                        <div className="text-danger none" id='url-invalid'>
                            <small>Please provide a valid url (e.g. starting with https:// – ideally copy and paste link).</small>
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3">  
                        <Form.Label>Categories</Form.Label>        
                        <Select 
                            styles={customStyles}
                            options={knowledgeCatOptions}
                            onChange={(e)=>setCategory(e)}
                            isMulti
                            placeholder='Select Category'
                        /> 
                    </Form.Group>
                    <Form.Group className="mb-3"> 
                        <Form.Label>Keywords</Form.Label> 
                        <Form.Control
                            type="text"
                            placeholder='FAQ, Help, Customer Service'
                            onChange={(e)=>setKeywords(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
