import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

export default function ScriptTemplate() {

    const [show, setShow] = useState(false);

    const handleClose = () =>  {
        setShow(false);
    }

    const handleShow = () => {
        setShow(true);
    }
      
    const handleSubmit = (e) => {

        e.preventDefault();

        alert('hiiiiii');
    }

    return <>
        <Button onClick={handleShow}> 
            Template Script
        </Button>
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Confirmation Dialogue</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Confirm run template script script
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                >
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
