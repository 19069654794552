import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, FloatingLabel, Form, InputGroup, Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

export default function AddInvoice(props) {

    // Status Modal
    const [show, setShow] = useState(false);
    const [count, setCount] = useState(0);
    const [name, setName] = useState('');
    const [feeName, setFeeName] = useState('');
    const [amount, setAmount] = useState('');
    const [currency, setCurrency] = useState('aud');

    // Multi-Fee
    const [firstFee, setFirstFee] = useState(100);
    const [totalFee, setTotalFee] = useState(100);
    const [laterFee, setLaterFee] = useState(0);

    const history = useHistory();

    useEffect(()=> {
        if(show) {
            if(count>0) {
                document.getElementById('payment-form-fee-1').disabled = false;
            } else {
                setFirstFee(100);
                setTotalFee(100);
                document.getElementById('payment-form-fee-1').disabled = true;
            }
        }
    },[show,count])

    useEffect(() => {
        const calculateTotalFee = () => {
            let totalFee = 0;
            for (let i = 0; i < count+2; i++) {
                if(document.getElementById('payment-form-fee-'+i)) {
                    let feePercent = document.getElementById('payment-form-fee-'+i).value;
                    if (feePercent) {
                        feePercent = parseInt(feePercent);
                    } else {
                        feePercent = 0;
                    }
                    totalFee = totalFee + feePercent;
                }
            }
            setTotalFee(totalFee);
        }
        if(count>0) {
            calculateTotalFee();
        }
    },[firstFee, count, laterFee])

    const handleClose = () => {
        setFirstFee(100);
        setShow(false);
        setCount(0);
        setTotalFee(100);
    }

    const handleShow = () => {
        setShow(true);
    }

    function saveInvoice(invoice, a) {

        invoice.save().then(() => {
            if (a) {
                setTimeout(()=>{
                    history.go(0); 
                },1000)
                handleClose();
            }
        }, error => {
            alert('Please send screenshot of this error message: ' + error.message);
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if(totalFee === 100) {
            const Parse = require('parse');

            const Project = Parse.Object.extend('Project');
            const project = new Parse.Query(Project);
    
            project.first('projectId', props.projectId)
            .then((project) => {
                project.save()
                .then((project) => {
                    // console.info('Project object updated: ' + project.id);
                }, (error) => {
                    alert('Please send screenshot of this error message: ' + error.message);
                });
            }).catch((error) => {
                alert('Please send screenshot of this error message: ' + error.message);
            });
    
            const Fee = Parse.Object.extend('Fee');
            const Invoice = Parse.Object.extend('Invoice');
            
            if (count > 0) {
                var invoices = [];
                var calculatedTotal = 0;
                const agreedAmount = parseFloat(amount.replace(/[$,]/g,''));
                
                const fee = new Fee();
                fee.set('name', feeName);
                fee.set('createdBy', Parse.User.current());
                fee.set('projectId', props.project);
                fee.set('amount', amount.replace(/,/g,''));
                fee.set('currency', currency);

                var feeACL = new Parse.ACL();
                feeACL.setRoleReadAccess('staff', true);
                feeACL.setRoleWriteAccess('staff', true);
                feeACL.setReadAccess(props.clientId, true);
                fee.setACL(feeACL);

                fee.save()
                .then((fee) => {
                    for (let i = 1; i < count+2; i++) {
                        const invoice = new Invoice();
                        
                        const invoiceName = document.getElementById('payment-form-name-'+i).value; //Error Bug - Cannot read properties null (reading 'value')
                        const feePercent = parseFloat(document.getElementById('payment-form-fee-'+i).value);
    
                        const multiplyMoney = (amount, multiplyBy, significantDecimals = 2) => {
                            const precision = Math.pow(10, significantDecimals);
                            const wholeAmount = amount * precision;
                            const result = Math.floor(wholeAmount * multiplyBy);
                            return result / precision;
                        };
    
                        var calculatedAmount = multiplyMoney(agreedAmount, feePercent/100);
                        var money;
    
                        calculatedTotal = calculatedTotal + calculatedAmount;
    
                        if(i === count+1) {
                            const remainder = parseFloat((agreedAmount-calculatedTotal).toFixed(2));
                            money = parseFloat((remainder+calculatedAmount).toFixed(2));
                        } else {
                            money = calculatedAmount.toFixed(2);
                        }
    
                        invoice.set('projectId', props.project);
                        invoice.set('name', invoiceName);
                        invoice.set('value', money.toString());
                        invoice.set('currency', currency);
                        invoice.set('feeId', fee);
                        invoice.set('feeNumber', i);
                        invoice.set('feePercent', feePercent);

                        // Invoice visibility
                        invoice.set('paid', false);
                        invoice.set('archive', false);
                        invoice.set('showClient', false);

                        var invoiceACL = new Parse.ACL();
                        invoiceACL.setRoleReadAccess('staff', true);
                        invoiceACL.setRoleWriteAccess('staff', true);
                        invoiceACL.setReadAccess(props.clientId, true);
                        invoice.setACL(invoiceACL);
            
                        invoices.push(invoice);
                        let a = false;
                        if (i < count) {
                            a = !a;
                        }
    
                        Parse.Object.saveAll(invoices)
                        .then(() => {
                            setTimeout(()=>{
                                history.go(0); 
                            },1000);
                        }).catch((error) => {
                            alert('Please send screenshot of this error message: ' + error.message);
                        });
                    }
                }, (error) => {
                    alert('Please send screenshot of this error message: ' + error.message);
                });
            } else {
                const fee = new Fee();
                fee.set('name', name);
                fee.set('createdBy', Parse.User.current());
                fee.set('projectId', props.project);
                fee.set('amount', amount.replace(/,/g,''));
                fee.set('currency', currency);

                var singleFeeACL = new Parse.ACL();
                singleFeeACL.setRoleReadAccess('staff', true);
                singleFeeACL.setRoleWriteAccess('staff', true);
                singleFeeACL.setReadAccess(props.clientId, true);
                fee.setACL(singleFeeACL);

                fee.save()
                .then((fee) => {
                    const invoice = new Invoice();
                    invoice.set('projectId', props.project);
                    invoice.set('name', name);
                    invoice.set('value', amount.replace(/,/g,''));
                    invoice.set('currency', currency);
                    invoice.set('feeId', fee);
                    invoice.set('feeNumber', 1);
                    invoice.set('feePercent', 100);
                    invoice.set('paid', false);
                    invoice.set('archive', false);
                    invoice.set('showClient', false);

                    var invoiceACL = new Parse.ACL();
                    invoiceACL.setRoleReadAccess('staff', true);
                    invoiceACL.setRoleWriteAccess('staff', true);
                    invoiceACL.setReadAccess(props.clientId, true);
                    invoice.setACL(invoiceACL);
                    
                    saveInvoice(invoice, true);
                }, (error) => {
                    alert('Please send screenshot of this error message: ' + error.message);
                });
            }
        } else {
            alert('Total Fee % must equal 100');
        }
    }

    // Handles

    const addAnotherFee = () => {
        setCount(count+1);
        document.getElementById('fee-name-form').className = 'flex';
    }

    const firstFeeHandle = (e) => {
        setFirstFee(e.target.value);
    }

    const laterFeeHandle = (e) => {
        setLaterFee(e.target.value);
    }

    const RemoveFee = () => {
       const removeFeeRow = () => {
            setCount(count-1);
            if (count === 1) {
                document.getElementById('fee-name-form').className = 'none';
            }
        }

        if(count > 0) {
            return (
                <> 
                    <Button variant="danger" id={'removeFeeButton'} onClick={removeFeeRow}>
                        <i className="fas fa-minus"></i>
                    </Button>
                </>
            )
        } else {
            return null;
        }
        
    }

    const ValidateTotalFee = () => {
        let style;
        if (totalFee === 100) {
            style = 'form-control validate-success'
        } else if (totalFee) {
            style = 'form-control validate-error'
        } else {
            style = 'form-control'
        }
        return (
            <NumberFormat
                id='feeTotalText'
                placeholder='Fee %'
                className={style}
                value={totalFee}
                style={{height:'100%'}}
                disabled
            />
        )
    }

    // Ultilities 

    const MAX_VAL = 100;
    const withValueCap = (inputObj) => {
        const { value } = inputObj;
        if (value <= MAX_VAL) return true;
        return false;
    };
    
    // const MIN_VAL = 100;
    // const minimumAmount = (inputObj) => {
    //     const { value } = inputObj;
    //     if (value <= MIN_VAL) return true;
    //     return false;
    // };

    return(
        <div>
            <Button onClick={handleShow}>
                <i className="fas fa-plus"></i>
            </Button>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header className='modal-header' closeButton>
                    <Modal.Title>Add Fee</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body'>
                    <Form id="payment-form" onSubmit={handleSubmit}>
                        <>
                            <Form.Group className='mb-2 flexbox-column' controlId="validationAmount">
                                <Form.Label>Agreed Amount</Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <NumberFormat
                                        id={'payment-form-amount'}
                                        className='form-control'
                                        displayType={'input'}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        placeholder={'000.00'}
                                        onChange={(e)=>setAmount(e.target.value)}
                                        // isAllowed={minimumAmount}
                                    />
                                    <Form.Control
                                        style={{maxWidth:'77.66px'}}
                                        value={currency}
                                        disabled
                                        onChange={(e)=>setCurrency(e.target.value)}
                                    />
                                </InputGroup>
                                <div id='fee-name-form' className='none'>
                                    <Form.Label>Fee Name</Form.Label>
                                    <Form.Control
                                        id='payment-form-fee-name'
                                        className='custom-input-group-left'
                                        type="text"
                                        placeholder="Fee stage name"
                                        onChange={(e)=>setFeeName(e.target.value)}
                                    />
                                </div>
                            </Form.Group>
                            <hr />
                            <div id='payment-1'>
                                <InputGroup className='mb-2' id='feeRow-1' controlid="validationFee-1">
                                    <div className='col'>
                                        <FloatingLabel
                                            label='Invoice 1'
                                        >
                                            <Form.Control
                                                id='payment-form-name-1'
                                                className='custom-input-group-left'
                                                type="text"
                                                placeholder="Enter invoice name (stage)"
                                                onChange={(e)=>setName(e.target.value)}
                                            />
                                        </FloatingLabel>
                                    </div>
                                    <div className='col-2'>
                                        <NumberFormat
                                            id='payment-form-fee-1'
                                            placeholder='Fee %'
                                            className='form-control custom-input-group-right'
                                            format={'###'}
                                            isAllowed={withValueCap}
                                            onChange={firstFeeHandle}
                                            value={firstFee}
                                            style={{height:'100%'}}
                                            required
                                            disabled
                                        />
                                    </div>
                                </InputGroup>
                            </div>
                            {[...Array(count)].map((_, i) =>
                                <div id={'payment-'+(i+2)} key={i}>
                                    <Form.Group className='mb-2' id={'feeRow-'+(i+2)} controlid={"validationFee-"+(i+2)}>
                                        <div style={{display:'flex'}}>
                                            <div className='col'>
                                                <FloatingLabel label={'Invoice '+ (i+2)}>
                                                    <Form.Control
                                                        id={'payment-form-name-'+(i+2)}
                                                        className='custom-input-group-left'
                                                        type="text"
                                                        placeholder="Enter invoice name (stage)"
                                                    />
                                                </FloatingLabel>
                                            </div>
                                            <div className='col-2'>
                                                <NumberFormat
                                                    id={'payment-form-fee-'+(i+2)}
                                                    placeholder='Fee %'
                                                    className='form-control custom-input-group-right'
                                                    format={'###'}
                                                    isAllowed={withValueCap}
                                                    onChange={laterFeeHandle}
                                                    defaultValue={0}
                                                    style={{height:'100%'}}
                                                />
                                            </div>
                                        </div>
                                    </Form.Group>
                                </div>
                            )}
                            <Form.Group className="mt-3" style={{display:'flex'}} controlid="validationFee">
                                <Button onClick={addAnotherFee} style={{marginRight:'0.5rem'}}>
                                    <i className="fas fa-plus"></i>
                                </Button>
                                <RemoveFee />
                                <Form.Text className="col" style={{display:'flex',justifyContent:'flex-end',margin:'.5rem'}}>Total Fee %</Form.Text>
                                <div className='col-2'>
                                    <ValidateTotalFee />
                                </div>
                            </Form.Group>
                        </>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='modal-header'>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        onClick={name || amount || totalFee ? handleSubmit : null}
                        disabled={!name || !amount || !totalFee}
                    >
                        Create Fee
                    </Button>
                </Modal.Footer>
            </Modal>  
        </div>
    )
}