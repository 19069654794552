import { useState, useEffect } from 'react';
import { Button, Form, Modal, Row, Col, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import { customStyles } from '../../data/Data.js'


export default function AddClient(props) {

    const Parse = require('parse');

    const [show, setShow] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const [isIndividual, setIsIndividual] = useState(false);
    const [creatingOrg, setCreatingOrg] = useState(true);
    const [organisation, setOrganisation] = useState(null);
    const [newOrg, setNewOrg] = useState('');

    const [submitString, setSubmitString] = useState('Create Client');

    useEffect(()=> {
        if (isIndividual) {
            (!firstName || !lastName || !email || !phone) ? setIsDisabled(true) : setIsDisabled(false)
        } else if (organisation !== null) {
            (!firstName || !lastName || !email || !phone || !organisation) ? setIsDisabled(true) : setIsDisabled(false)
        } else {
            (!firstName || !lastName || !email || !phone || !newOrg) ? setIsDisabled(true) : setIsDisabled(false)
        }
    }, [firstName, lastName, email, phone, isIndividual, organisation, newOrg]);

    const handleClose = () =>  {
        setShow(false);
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setIsIndividual(false);
        setOrganisation(null);
        setNewOrg('');
        setCreatingOrg(true);
        setSubmitString('Create Client');
    }

    const handleShow = () => {
        setShow(true);
    }
      
    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitString(<>
            Creating&nbsp;
            <Spinner
                as="span"
                animation="border"
                size="sm"
            />
        </>);

        if (creatingOrg) {
            var newOrgName = newOrg;
            if (isIndividual) {
                newOrgName = firstName + ' ' + lastName;
            }
            const Organisation = Parse.Object.extend('Organisation');
            const query = new Parse.Query(Organisation);
            query.equalTo('name', newOrgName);
            query.find()
            .then(function(res) {
                if (res.length < 1) {
                    const newOrg = new Organisation();
                    newOrg.set('name', newOrgName);
                    if (isIndividual) {
                        newOrg.set('type', 'Individual');
                    } else {
                        newOrg.set('type', 'Org');
                    }
    
                    var orgACL = new Parse.ACL();
                    orgACL.setRoleReadAccess('staff', true);
                    orgACL.setRoleWriteAccess('staff', true);
                    newOrg.setACL(orgACL);
    
                    newOrg.save().then((orgRes) => {
                        createUserFunction(orgRes, newOrgName);
                    }).catch((error)=> {
                        alert('Please send screenshot of this error message: ' + error.message);
                        setSubmitString('Create Client');
                    });
                } else {
                    alert('Error: An organisation with this name already exists.');
                    setSubmitString('Create Client');
                }
            }, (error) => {
                alert('Please send screenshot of this error message: ' + error.message);
                setSubmitString('Create Client');
            });
        } else {
            createUserFunction(null, null);
        }
    }

    const createUserFunction = (orgRes, newOrgName) => {
        const User = Parse.Object.extend('User');
        const query = new Parse.Query(User);
        query.equalTo('email', email);
        query.find()
        .then(function(res) {
            if (res.length < 1) {

                var clientRole;

                const queryRole = new Parse.Query(Parse.Role);
                queryRole.equalTo('name','client').first().then(clientRoleRes => { 
                    clientRole = clientRoleRes;
                }, (error) => {
                    alert('Please send screenshot of this error message: ' + error.message);
                });

                const user = new User();
                user.set('firstName', firstName);
                user.set('lastName', lastName);
                user.set('username', email);
                user.set('email', email);
                user.set('phone', phone);
                user.set('slimView', false);
                user.set('darkMode', false);
                user.set('status', 'Inactive');

                if (creatingOrg) {
                    user.set('organisation', orgRes);
                } else {
                    user.set('organisation', organisation.object);
                }

                var userACL = new Parse.ACL();
                userACL.setRoleReadAccess('staff', true);
                userACL.setRoleWriteAccess('staff', true);
                user.setACL(userACL);

                const password = Math.random().toString(36) + Math.random().toString(36).toUpperCase();
                user.set('password', password);

                user.save().then((clientRes) => {

                    if (orgRes !== null) {
                        var orgAcl = new Parse.ACL();
                        orgAcl.setReadAccess(clientRes, true);
                        orgAcl.setWriteAccess(clientRes, false);
                        orgAcl.setRoleReadAccess('staff', true);
                        orgAcl.setRoleWriteAccess('staff', true);
                        orgRes.setACL(orgAcl);
                        orgRes.save().catch((error)=>{
                            alert('Please send screenshot of this error message: ' + error.message);
                            setSubmitString('Create Client');
                        });
                    }
                    
                    clientRole.relation('users').add(clientRes);
                    clientRole.save().then((clientRoleRes) => {
                        if (creatingOrg && !isIndividual) {
                            handleAddOrgDisplay(orgRes);
                        }
                        handleAddUserDisplay(clientRes, orgRes, newOrgName);
                    }).catch((error)=>{
                        alert('Please send screenshot of this error message: ' + error.message);
                        setSubmitString('Create Client');
                    });
                }).catch((error)=> {
                    alert('Please send screenshot of this error message: ' + error.message);
                    setSubmitString('Create Client');
                });
            } else {
                if (creatingOrg) {
                    orgRes.destroy().then((myObject) => {
                        setSubmitString('Create Client');
                    }, (error) => {
                        alert('Please send screenshot of this error message: ' + error.message);
                        setSubmitString('Create Client');
                    });
                }
                alert('Error: A user with this email address already exists.');
                setSubmitString('Create Client');
            }
        }, (error) => {
            alert('Please send screenshot of this error message: ' + error.message);
            setSubmitString('Create Client');
        });
    }

    const handleAddOrgDisplay = (orgRes, newOrgName) => {
        const dataCopy = props.orgData;
        dataCopy.unshift({
            id: 0,
            idDisplay: orgRes.id,
            nameDisplay: newOrg,
        });
        props.setOrgData(dataCopy);
    }

    const handleAddUserDisplay = (clientRes, orgRes, newOrgName) => {
        var orgIdDisp, orgNameDisp;
        if (orgRes !== null) {
            orgIdDisp = orgRes.id;
            orgNameDisp = newOrgName;
        } else {
            orgIdDisp = organisation.value;
            orgNameDisp = organisation.label;
        }
        const dataCopy = props.userData;
        dataCopy.unshift({
            id: 0,
            idDisplay: clientRes.id,
            firstNameDisplay: firstName,
            lastNameDisplay: lastName,
            nameDisplay: firstName + ' ' + lastName,
            phoneDisplay: phone,
            emailDisplay: email,
            orgIdDisplay: orgIdDisp,
            organisationDisplay: orgNameDisp,
            verifiedDisplay: <i className="fas fa-lock" style={{color: '#990000'}}></i>,

        });
        props.setUserData(dataCopy);
        handleClose();
    }

    const handleTypeNewOrg = (textTyped) => {
        setNewOrg(textTyped);
        setOrganisation(null);
        setCreatingOrg(true);
    }

    const handleOrgSelect = (selectedOrgOption) => {
        setNewOrg('');
        setOrganisation(selectedOrgOption);
        if (selectedOrgOption.value === 'Individual') {
            document.getElementById('indiv-switch').checked = true;
            setIsIndividual(true);
            setCreatingOrg(true);
        } else {
            document.getElementById('indiv-switch').checked = false;
            setIsIndividual(false);
            setCreatingOrg(false);
        }
    }

    const handleIndividualSelect = (checkMe) => {
        setIsIndividual(checkMe);
        setCreatingOrg(true);
        if (checkMe) {
            setOrganisation({
                "value" : 'Individual',
                "label" : 'Trading as an individual',
                "object" : 'null',
            })
        } else {
            setOrganisation(null);
        }
    }

    function Asterisks() {
        return <span style={{color: "var(--danger)"}}> *</span>
    }

    return <>
        <Button onClick={handleShow}> 
            Add Client
            {/* <i className="fas fa-plus"></i> */}
        </Button>
        <Modal show={show} onHide={handleClose} centered backdrop="static">
            <Modal.Header closeButton className='modal-header'>
                <Modal.Title>Add Client</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>First Name <Asterisks /></Form.Label>
                            <Form.Control
                                id="new-client-first-name"
                                type="text"
                                className="mb-2"
                                placeholder="First Name"
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>Last Name <Asterisks /></Form.Label>
                            <Form.Control
                                id="new-client-last-name"
                                type="text"
                                className="mb-2"
                                placeholder="Last Name"
                                onChange={(e) => setLastName(e.target.value)}
                            /> 
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>Email <Asterisks /></Form.Label>
                            <Form.Control
                                id="new-client-email"
                                type="email"
                                className="mb-2"
                                placeholder="name@example.com"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>Phone <Asterisks /></Form.Label>
                            <NumberFormat
                                id="new-client-phone" 
                                format="#### ### ### ### ### ###"
                                className="form-control mb-2"
                                placeholder="04XY ABC DEF"
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>Organisation <Asterisks /></Form.Label>
                            {!isIndividual && (
                                <Form.Control
                                    id="org-input"
                                    type="text"
                                    className="mb-2"
                                    placeholder="New org name"
                                    value={newOrg}
                                    onChange={(e) => handleTypeNewOrg(e.target.value)}
                                /> 
                            )}
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>Existing org</Form.Label>
                            <Select 
                                styles={customStyles}
                                options={props.orgOptions}
                                onChange={(e)=>handleOrgSelect(e)}
                                value={organisation}
                                defaultValue={organisation}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Check
                                type="switch"
                                id='indiv-switch'
                                label="Trading as an individual"
                                value={lastName}
                                onChange={(e) => handleIndividualSelect(e.target.checked)}
                            />
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                >
                    {submitString}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
