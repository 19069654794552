import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Cookies from 'js-cookie';
import history from '../../utils/History';

export default function Login() {

    const logoutHandler = () => {
        const Parse = require('parse');

        Cookies.remove('email');
        Cookies.remove('user');
        Cookies.remove('sessionToken');

        Parse.User.logOut()
        .then(() => {
            history.push('/');
            history.go(0);
        }).catch((error) => {
            alert('Refresh the page after logging out if you wish to login again');
            // alert('Please send screenshot of this error message: ' + error.message);
        });
    }
    
    return <>
        <Button variant="outline-primary" onClick={logoutHandler}>
            Logout
        </Button>
    </> 
}
