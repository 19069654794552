import { useState } from 'react';
import { Button, Modal, Spinner} from 'react-bootstrap';
import axios from 'axios';

export default function FlagProject(props) {

    const [show, setShow] = useState(false);
    const [projDisplayString, setProjDisplayString] = useState('');
    const [submitString, setSubmitString] = useState('Send');

    const handleClose = () =>  {
        setProjDisplayString('');
        setSubmitString('Send');
        setShow(false);
    }

    const handleShow = () => {
        if (props.row.values.flagDisplay === 'true') {
            setSubmitString('Remove');
        } else {
            setSubmitString('Send');
        };
        setProjDisplayString(props.row.values.projectNumberDisplay + ' — ' + props.row.values.projectNameDisplay);
        setShow(true);
    }

    const flagThisProject = () => {
        setSubmitString(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const Parse = require('parse');
        const query = new Parse.Query(Parse.Object.extend('Project'));
        query.get(props.row.values.idDisplay)
        .then((project) => {
            project.set('flag', true);
            project.save()
            .then((res)=>{
                const url = 'https://hooks.slack.com/services/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';
                const data = { text: 'Hi ' + 
                    props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + 
                    ' has requested help with project:\n' + projDisplayString};

                axios.post(url, JSON.stringify(data), {
                    withCredentials: false,
                    transformRequest: [
                        (data, headers) => {
                            return data;
                        },
                    ],
                });
                const dataCopy = props.projectData;
                var thisProject = dataCopy[props.row.index];
                thisProject.flagDisplay = 'true';
                dataCopy[props.row.index] = thisProject;
                props.setProjectData(dataCopy);
                handleClose();
            }).catch((error)=>{
                alert('Please send screenshot of this error message: ' + error.message);
                setSubmitString('Send');
            })
        }).catch((error)=>{
            alert('Please send screenshot of this error message: ' + error.message);
            setSubmitString('Send');
        })
    }

    const unflagThisProject = () => {
        setSubmitString(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const Parse = require('parse');
        const query = new Parse.Query(Parse.Object.extend('Project'));
        query.get(props.row.values.idDisplay)
        .then((project) => {
            project.set('flag', false);
            project.save()
            .then((res)=>{
                const dataCopy = props.projectData;
                var thisProject = dataCopy[props.row.index];
                thisProject.flagDisplay = 'false';
                dataCopy[props.row.index] = thisProject;
                props.setProjectData(dataCopy);
                handleClose();
            }).catch((error)=>{
                alert('Please send screenshot of this error message: ' + error.message);
                setSubmitString('Remove');
            })
        }).catch((error)=>{
            alert('Please send screenshot of this error message: ' + error.message);
            setSubmitString('Remove');
        })
    }

    if (props.row.values.isComplete === 'true') {
        return null;
    } else if (props.row.values.flagDisplay === 'true') {
        return <>
            <Button variant='link' size="sm" className="help" onClick={handleShow}>
                <i className="fas fa-hand-paper"></i>
            </Button>
            <Modal show={show} onHide={handleClose} onExited={props.handleExit} centered size='md' backdrop="static">
                <Modal.Header className='modal-header' closeButton>
                    <Modal.Title>Help Request</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body'>
                    {projDisplayString}
                </Modal.Body>
                <Modal.Footer className='modal-header'>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={unflagThisProject}
                    >
                        {submitString}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    } else {
        return <>
            <Button variant='link' className="help" size="sm" onClick={handleShow}>
                <i className="far fa-hand-paper"></i>
            </Button>
            <Modal show={show} onHide={handleClose} onExited={props.handleExit} centered size='md' backdrop="static">
                <Modal.Header className='modal-header' closeButton>
                    <Modal.Title>Help Request</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body'>
                    {projDisplayString}
                </Modal.Body>
                <Modal.Footer className='modal-header'>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={flagThisProject}
                    >
                        {submitString}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    }
}
