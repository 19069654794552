import { useState, useEffect } from 'react';
import { Button, Form, Modal, FloatingLabel, Spinner, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { customStyles } from '../../data/Data.js'


export default function EditClient(props) {

    const [show, setShow] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [pfirstName, setpFirstName] = useState();
    const [plastName, setpLastName] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [org, setOrg] = useState();
    const [submitString, setSubmitString] = useState('Save Client');

    const defaultSingleValue = (options, propsValue) => {
        return options.filter(option => option.value === propsValue);
    }

    useEffect(()=>{
        setpFirstName(props.row.values.firstNameDisplay);
        setpLastName(props.row.values.lastNameDisplay);
        setFirstName(props.row.values.firstNameDisplay);
        setLastName(props.row.values.lastNameDisplay);
        setEmail(props.row.values.emailDisplay);
        setPhone(props.row.values.phoneDisplay);
        setOrg(defaultSingleValue(props.orgOptions, props.row.values.orgIdDisplay));
    }, [show])

    useEffect(()=> {
        (!firstName || !lastName || !email || !phone || !org) ? setIsDisabled(true) : setIsDisabled(false)
    }, [firstName, lastName, email, phone, org]);

    const handleClose = () =>  {
        setShow(false);
        setSubmitString('Save Client');
        setpFirstName('');
        setpLastName('');
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setOrg('');
    }

    const handleShow = () => {
        setShow(true);
    }
      
    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitString(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const userId = props.row.values.idDisplay;

        const Parse = require('parse');
        const query = new Parse.Query(Parse.Object.extend('User'));
        query.get(userId)
        .then((user) => {
            var isIndividual = false;
            var isNewOrg = false;
            var hasNameChanged = false;
            if (props.row.values.orgIdDisplay === 'Individual' || org.value === 'Individual') {
                isIndividual = true;
            }
            if (org.value !== props.row.values.orgIdDisplay) {
                isNewOrg = true;
            }
            if ((pfirstName !== firstName) || (plastName !== lastName)) {
                hasNameChanged = true;
            }
            if (hasNameChanged && isIndividual && firstName && lastName && pfirstName && plastName) {
                const nameDisp = pfirstName + ' ' + plastName;
                const newNameDisp = firstName + ' ' + lastName;
                const queryOrg = new Parse.Query(Parse.Object.extend('Organisation'));
                queryOrg.equalTo('name', nameDisp);
                queryOrg.first()
                .then(function(res) {
                    res.set('name', newNameDisp);
                    res.save()
                    .then((newRes)=> {
                        // do nothing
                    }, (error) => {
                        alert('Please send screenshot of this error message: ' + error.message);
                        setSubmitString('Save Client');
                    });
                }).catch((error) => {
                    alert('Please send screenshot of this error message: ' + error.message);
                    setSubmitString('Save Client');
                });
            }
            if (isNewOrg && !isIndividual) {
                user.set('organisation', org.object);
            }

            user.set('firstName', firstName);
            user.set('lastName', lastName);
            user.set('email', email);
            user.set('username', email);
            user.set('phone', phone);
            user.save()
            .then((res)=>{
                const dataCopy = props.userData;
                var thisUser = dataCopy[props.row.index];
                if (firstName) {
                    thisUser.firstNameDisplay = firstName;
                }
                if (lastName) {
                    thisUser.lastNameDisplay = lastName;
                }
                if (firstName && lastName) {
                    thisUser.nameDisplay = firstName + ' ' + lastName;
                }
                if (email) {
                    thisUser.emailDisplay = email;
                }
                if (phone) {
                    thisUser.phoneDisplay = phone;
                }
                if (isNewOrg && !isIndividual && org.value && org.label) {
                    thisUser.orgIdDisplay = org.value;
                    thisUser.organisationDisplay = org.label;
                }
                dataCopy[props.row.index] = thisUser;
                props.setUserData(dataCopy);
                handleClose();
            }).catch((error)=>{
                alert('Please send screenshot of this error message: ' + error.message);
                setSubmitString('Save Client');
            })
        }).catch((error)=>{
            alert('Please send screenshot of this error message: ' + error.message);
            setSubmitString('Save Client');
        })
    }

    return <>
        <Button variant='outline-secondary' onClick={handleShow}>
            <i className="fas fa-pen"></i>
        </Button>
        <Modal show={show} onHide={handleClose} centered backdrop="static">
            <Modal.Header closeButton className='modal-header'>
                <Modal.Title>Edit Client</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                id='first-name-text'
                                type='text'
                                value={firstName}
                                onChange={(e) => {setFirstName(e.target.value);}}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                id='last-name-text'
                                type='text'
                                value={lastName}
                                onChange={(e) => {setLastName(e.target.value);}}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                id='email-text'
                                type='text'
                                value={email}
                                onChange={(e) => {setEmail(e.target.value);}}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                id='phone-text'
                                type='text'
                                value={phone}
                                onChange={(e) => {setPhone(e.target.value);}}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Organisation</Form.Label>
                            <Select 
                                styles={customStyles}
                                options={props.orgOptions}
                                value={org}
                                defaultValue={org}
                                onChange={e=>setOrg(e)}
                            />
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                >
                    {submitString}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
