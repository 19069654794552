import { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { Button, Form, Modal } from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import DatePicker from 'react-datepicker';
import PdfUpload from './PdfUpload';

export default function IssueInvoice(props) {
    
    var today = new Date();

    const [show,setShow] = useState(false);
    const [invoiceNo, setInvoiceNo] = useState('');
    const [dueDate, setDueDate] = useState(new Date(today.getFullYear(), today.getMonth(), today.getDate()+14));

    const [acceptedFiles, setAcceptFiles] = useState([]);


    const handleClose = () => {
        setShow(false);
        setAcceptFiles('');
    }
    const handleShow = () => {
        setShow(true);
        // console.log(props);
    }

    const history = useHistory();

    const handleSubmit = () => {

        const Parse = require('parse');

        const Invoice = Parse.Object.extend('Invoice');
        const invoice = new Parse.Query(Invoice);
        
        invoice.get(props.invoice.id)
        .then((invoice) => {
            // const noQuery = new Parse.Query(Invoice);
            // noQuery.equalTo('invoiceNo', invoiceNo);

            invoice.set('invoiceNo', invoiceNo.trim());
            invoice.set('dueBy', dueDate);
            invoice.set('showClient', true);

            if (acceptedFiles.length > 0) {
                const file = acceptedFiles[0];
                const name = acceptedFiles[0].name;
                invoice.set('file', new Parse.File(name, file));
            }

            const query = new Parse.Query(Parse.Object.extend('Project'));
            invoice.save().then(()=> {
                query.get(props.project.id).then((project)=>{

                    const newAction = {
                        type:'issue-invoice',
                        invoiceNo: invoiceNo.trim(),
                        createdBy: Parse.User.current().id,
                        createdAt: new Date()
                    };

                    props.action.push(newAction);
                    project.set('actionHistory', props.action);

                    project.save().then(()=>{
                        setTimeout(()=> {
                        history.go(0);
                        },1000)
                    })
                })
            }).catch((error) => {
                alert('Please send screenshot of this error message: ' + error.message);
            });
        }).catch((error) => {
            alert('Please send screenshot of this error message: ' + error.message);
        });
        // Close Modal
        handleClose();
    }

    const highlightDate = [
        {
            "react-datepicker__day--highlighted-custom-3": [
                new Date()
            ]
        }
    ]

    if (props.invoice.get('invoiceNo')) {
        return null;
    } else {
        return (
            <>
                <Button
                    variant="primary"
                    style={{fontSize: ".7rem",padding: "0 .5rem", textTransform: 'capitalize', letterSpacing: '0.01rem'}}
                    onClick={handleShow}
                >
                    Issue Invoice
                </Button>
                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header className='modal-header' closeButton>
                        <Modal.Title>Issue Invoice</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='modal-body'>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-2">
                                <Form.Label>Invoice #</Form.Label>
                                <NumberFormat
                                    id={'payment-form-invoiceId'}
                                    className='form-control'
                                    displayType={'input'}
                                    prefix={'INV-'}
                                    placeholder={'INV-'}
                                    format={'INV-########'}
                                    onChange={(e)=>setInvoiceNo(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Label>Due Date</Form.Label>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className='form-control'
                                    selected={dueDate}
                                    highlightDates={highlightDate}
                                    // minDate={moment().toDate()}
                                    onChange={(date)=> setDueDate(date)}
                                    fixedHeight
                                />
                            </Form.Group>
                            <Form.Group className='mb-4'>
                                <PdfUpload
                                    file={setAcceptFiles}
                                    list={acceptedFiles}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer className='modal-header'>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={invoiceNo ? handleSubmit : null}
                            disabled={!invoiceNo || !dueDate}
                        >
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>  
            </>
        );
    }
}
