import { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { Button, Form, Modal } from 'react-bootstrap'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/en-au';

export default function PaidStatus(props) {

    var today = new Date();
    const [paymentDate, setPaymentDate] = useState(new Date(today.getFullYear(), today.getMonth(), today.getDate()));

    // Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const history = useHistory();

    const handleSubmit = () => {
        const Parse = require('parse');

        const Invoice = Parse.Object.extend('Invoice');
        const invoice = new Parse.Query(Invoice);

        const checkbox = document.getElementById('form-invoice-paid-checkbox').checked;

        invoice.get(props.id)
        .then((res)=> {
            res.set('paid', checkbox);
            res.set('paymentDate', paymentDate);
            res.save();
        }).then(()=>{
            const Project = Parse.Object.extend('Project');
            const p = new Parse.Query(Project);
    
            p.first(props.project).then((res) => {
                const actions = res.get('customerActions');
                const newAction = {
                    type: props.action,
                    createdBy: Parse.User.current().id,
                    archived: false
                }
                // console.log('adding to action history..');
                actions.push(newAction);
                res.set('actionHistory', actions);
                res.save();
            })
        }).then(() => {
            setTimeout(()=> {
                history.go(0);
            }, 1000);
            handleClose();
        }).catch(error => {
            alert('Please send screenshot of this error message: ' + error.message);
        });
    }

    const PaidModal = (props) => {
        return (
            <>
                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Body className="space-between">
                        <Form onSubmit={handleSubmit}>
                            <Form.Group>
                                <Form.Check 
                                    id='form-invoice-paid-checkbox'
                                    type='switch'
                                    label="Has invoice been paid?"
                                    defaultChecked={props.paid}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Payment Date</Form.Label>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className='form-control'
                                    selected={paymentDate}
                                    onChange={(date)=> setPaymentDate(date)}
                                    fixedHeight
                                />
                            </Form.Group>
                        </Form>
                        <Form.Group>
                            <Button variant="secondary" style={{marginRight:'.25rem'}} onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleSubmit}>
                                Confirm
                            </Button>
                        </Form.Group>
                    </Modal.Body>
                </Modal>  
            </>
        )
    }

    if (props.paid){
        let paymentDate = props.paymentDate;
        // if (paymentDate) {
        //     paymentDate = moment(props.paymentDate).locale('en-au').format('LL');
        // }
        return (
            <div className="space-between">
                <Button
                    variant="secondary"
                    style={{fontSize: ".75rem",padding: "0 .5rem"}}
                    onClick={handleShow}
                >
                    {/* Paid {props.paymentDate} */}
                    Paid {moment(paymentDate).locale('en-au').format('LL')}
                </Button>
                <i
                    className="fas fa-check-circle icon-flex"
                    style={{color: 'var(--success)', minWidth: '20px'}}
                />
                <PaidModal
                    paid={props.paid}
                />
            </div>
        ) 
    } else if (props.hasInvoice){
        if(moment(new Date()).isAfter(props.dueBy)) {
            return (
                <div className="space-between">
                    <Button
                        variant="danger"
                        style={{fontSize: ".7rem",padding: "0 .5rem", letterSpacing: "0.02rem"}}
                        onClick={handleShow}
                    >
                        Mark Payment
                    </Button>
                    <i 
                        className="fas fa-exclamation-circle icon-flex"
                        style={{color: 'var(--danger)', minWidth: '20px'}}
                    />
                    <PaidModal 
                        paid={props.paid}
                    />
                </div>
            )
        } else {
            return (
                <div className="space-between">
                    <Button
                        variant="primary"
                        style={{fontSize: ".7rem",padding: "0 .5rem", letterSpacing: "0.02rem"}}
                        onClick={handleShow}
                    >
                        Mark Payment
                    </Button>
                    <i 
                        className="fas fa-hourglass-start icon-flex"
                        style={{color: '#var(--info)', minWidth: '20px'}}
                    />
                    <PaidModal 
                        paid={props.paid}
                    />
                </div>
            )
        }
    } else {
        return null;
    }
}
