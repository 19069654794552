import { useState, useEffect } from 'react';
import { Button, Modal, Form, FloatingLabel } from 'react-bootstrap';
import Select from 'react-select';
import { knowledgeCatOptions, customStyles } from '../../data/Data';


export default function EditKnowledgeCard(props) {

    const [show, setShow] = useState(false);
    const [showDel, setShowDel] = useState(false);

    const [heading, setHeading] = useState('');
    const [keywords, setKeywords] = useState('');
    const [type, setType] = useState('');
    const [answer, setAnswer] = useState('');
    const [url, setUrl] = useState('');
    const [category, setCategory] = useState([]);
    const [updatedCategory, setUpdatedCategory] = useState([]);

    useEffect(()=>{
        setHeading(props.heading);
        setKeywords(props.keywords);
        setType(props.type);
        setAnswer(props.answer);
        setUrl(props.url);
        setCategory(props.category);
    }, [show])

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => {
        setShow(true);
    }

    const handleDelClose = () => {
        handleShow();
        setShowDel(false);
    }
    const handleDelShow = () => {
        handleClose();
        setShowDel(true);
    }

    const handleSubmit = () => {
        const Parse = require('parse');
        const query = new Parse.Query(Parse.Object.extend('Knowledge'));

        query.get(props.id)
        .then((res) =>{
            res.set('heading', heading);
            res.set('keywords', keywords);
            res.set('type', type);
            res.set('answer', answer);
            res.set('url', url);
            if (updatedCategory.length > 0 && updatedCategory !== category) {
                res.set('category', updatedCategory);
            }
            res.save()
            .then((info)=>{
                const dataCopy = props.knowledge;
                var thisCard = dataCopy[props.rowKey];
                if (heading) {
                    thisCard.heading = heading;
                }
                if (keywords) {
                    thisCard.keywords = keywords;
                }
                if (type) {
                    thisCard.type = type;
                }
                if (answer) {
                    thisCard.answer = answer;
                }
                if (url) {
                    thisCard.url = url;
                }
                if (updatedCategory.length > 0 && updatedCategory !== category) {
                    thisCard.category = updatedCategory;
                }
                dataCopy[props.rowKey] = thisCard;
                props.setKnowledge(dataCopy);
                props.renderCards();
                handleClose();
            }).catch((error)=>{
                alert('Please send screenshot of this error message: ' + error.message);
            })
        }).catch((error)=>{
            alert('Please send screenshot of this error message: ' + error.message);
        })
    }

    const handleDelete = () => {
        const Parse = require('parse');
        const query = new Parse.Query(Parse.Object.extend('Knowledge'));

        query.get(props.id)
        .then((res) =>{
            res.destroy().then((myObject) => {
                const dataCopy = props.knowledge;
                dataCopy.splice(props.rowKey, 1);
                props.setKnowledge(dataCopy);
                props.renderCards();
                handleDelClose();
                handleClose();
            }, (error) => {
                alert('Please send screenshot of this error message: ' + error.message);
            });
        }).catch((error)=>{
            alert('Please send screenshot of this error message: ' + error.message);
        })
    }

    switch (props.type) {
        case 'link':
            return <>
            <Button variant='outline-secondary' onClick={handleShow}>
                <i className="fas fa-pen"></i>
            </Button>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton className='modal-header'>
                    <Modal.Title>Update Knowledge Card</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body'>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-2">
                            <Form.Label>Heading</Form.Label>    
                            <Form.Control
                                type='text'
                                defaultValue={heading}
                                onChange={(e)=>setHeading(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-2">
                        <Form.Label>Link</Form.Label>    
                            <Form.Control
                                type='text'
                                defaultValue={url}
                                onChange={(e)=>setUrl(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-2">        
                            <Form.Label>Categories</Form.Label>    
                            <Select 
                                styles={customStyles}
                                options={knowledgeCatOptions}
                                onChange={(e)=>setUpdatedCategory(e)}
                                isMulti
                                defaultValue={category}
                            /> 
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Keywords</Form.Label>    
                            <Form.Control
                                type='text'
                                defaultValue={keywords}
                                onChange={(e)=>setKeywords(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='modal-header'>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleDelShow}>Delete</Button>
                    <Button variant="primary" onClick={handleSubmit}>Update</Button>
                </Modal.Footer>
            </Modal> 
            <Modal show={showDel} onHide={handleDelClose} centered>
                <Modal.Header closeButton className='modal-header'>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body'>
                    Are you sure you want to delete this card?
                </Modal.Body>
                <Modal.Footer className='modal-header'>
                    <Button variant="secondary" onClick={handleDelClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>Delete Card</Button>
                </Modal.Footer>
            </Modal>
        </>               
        default:
            return <>
            <Button variant='outline-secondary' onClick={handleShow}>
                <i className="fas fa-pen"></i>
            </Button>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton className='modal-header'>
                    <Modal.Title>Update Knowledge Card</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body'>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-2">
                            <Form.Label>Heading / Question</Form.Label>    
                            <Form.Control
                                type='text'
                                defaultValue={heading}
                                onChange={(e)=>setHeading(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Response</Form.Label>    
                            <Form.Control
                                type='text'
                                defaultValue={answer}
                                onChange={(e)=>setAnswer(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-2">        
                            <Form.Label>Categories</Form.Label>    
                            <Select 
                                styles={customStyles}
                                options={knowledgeCatOptions}
                                onChange={(e)=>setUpdatedCategory(e)}
                                isMulti
                                defaultValue={category}
                            /> 
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Keywords</Form.Label>    
                            <Form.Control
                                type='text'
                                defaultValue={keywords}
                                onChange={(e)=>setKeywords(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='modal-header'>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleDelShow}>Delete</Button>
                    <Button variant="primary" onClick={handleSubmit}>Update</Button>
                </Modal.Footer>
            </Modal> 
            <Modal show={showDel} onHide={handleDelClose} centered>
                <Modal.Header closeButton className='modal-header'>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body'>
                    Are you sure you want to delete this card?
                </Modal.Body>
                <Modal.Footer className='modal-header'>
                    <Button variant="secondary" onClick={handleDelClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>Delete Card</Button>
                </Modal.Footer>
            </Modal>
        </>
    }
}
