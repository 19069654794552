import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Card, Container, Form, Row, Col } from 'react-bootstrap';

export default function Account() {

    useEffect(() => {
        document.title = "Portal – Account";
    }, []);

    const Parse = require('parse');
    const user = Parse.User.current();

    const [name, setName ] = useState('');
    const [username, setUsername ] = useState('');
    const [phone, setPhone ] = useState('');
    const [buttonPath, setButtonPath] = useState('');
    const [cardMode, setCardMode] = useState('light');
    const [slimView, setSlimView] = useState(user.attributes.slimView);
    const [darkMode, setDarkMode] = useState(user.attributes.darkMode);


    const getName = () => {
        setName(user.attributes.firstName + ' ' + user.attributes.lastName);
    }

    const getUsername = () => {
        setUsername(user.attributes.username);
    }

    const getPhone = () => {
        setPhone(user.attributes.phone);
    }

    const getButtonPath = () => {
        setButtonPath('account/password');
    }

    useEffect(() => {
        getName();
        getPhone();
        getUsername();
        getButtonPath();
    },[])

    useEffect(() => {
        if (user.attributes.darkMode) {
            setCardMode('dark');
        } else {
            setCardMode('light');
        }
    },[user.attributes.darkMode])

    const handleSlimViewSet = (e) => {
        const query = new Parse.Query(Parse.Object.extend('User'));
        query.get(user.id)
        .then((user) => {
            user.set('slimView', e);
            user.save()
            .then((res)=>{
                setSlimView(e);
            }).catch((error)=>{
                alert('Please send screenshot of this error message: ' + error.message);
            })
        }).catch((error)=>{
            alert('Please send screenshot of this error message: ' + error.message);
        })
    }

    const handleDarkModeSet = (e) => {
        const query = new Parse.Query(Parse.Object.extend('User'));
        query.get(user.id)
        .then((user) => {
            user.set('darkMode', e);
            user.save()
            .then((res)=>{
                setDarkMode(e);
            }).catch((error)=>{
                alert('Please send screenshot of this error message: ' + error.message);
            })
        }).catch((error)=>{
            alert('Please send screenshot of this error message: ' + error.message);
        })
    }
    
    return <>
        {/* <Container>
            <Breadcrumb className='container'>
                <Breadcrumb.Item href='/'>Home</Breadcrumb.Item>
                <Breadcrumb.Item active >Manage Account</Breadcrumb.Item>
            </Breadcrumb>
        </Container> */}
        <Container>
            <div className="client-homepage">
                <div className="home-header">
                    <h2>Manage Account</h2>
                </div>
                <hr/>
                <Card bg={cardMode} className='text-center' border="secondary">
                    <Card.Header as='h4'>{name}</Card.Header>
                    <Card.Body>
                        <Card.Text>{phone}</Card.Text>
                        <Card.Text>{username}</Card.Text>
                        <Row className='mt-3'>
                            <Col md={5}>
                                &nbsp;
                            </Col>
                            <Col md={2}>
                                <Form.Group>
                                    <Form.Check
                                        type="switch"
                                        label="Slim view"
                                        checked={slimView}
                                        onChange={(e) => handleSlimViewSet(e.target.checked)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={5}>
                                &nbsp;
                            </Col>
                        </Row>
                        <Row className='mt-3 mb-3'>
                            <Col md={5}>
                                &nbsp;
                            </Col>
                            <Col md={2}>
                                <Form.Group>
                                    <Form.Check
                                        type="switch"
                                        label="Dark Mode"
                                        checked={darkMode}
                                        onChange={(e) => handleDarkModeSet(e.target.checked)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={5}>
                                &nbsp;
                            </Col>
                        </Row>
                        <Button href={buttonPath} variant='primary'>Change Password</Button>
                    </Card.Body>
                </Card>
            </div>
        </Container>
    </>
}
