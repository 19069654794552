import { useState, useEffect } from 'react';
import { Button, Modal, Form, Row, Col, Spinner, Stack } from 'react-bootstrap';
import NumericFormat from 'react-number-format';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/en-au';
import { yearOptions, locationOptions, stageOptions, classOptions, sectorOptions, lossOptions, 
    servicesOptions, reportIncludesOptions, insuranceOptions, customStyles } from '../../data/Data.js'
import RangeSlider from 'react-bootstrap-range-slider';
import ProjectTasks from './ProjectTasks';
import ProjectClient from './ProjectClient';

export default function ProgressProject(props) {

    const Parse = require('parse');

    const [mode, setMode] = useState('pipeline');
    
    const [showLoss, setShowLoss] = useState(false);
    const [showWin, setShowWin] = useState(false);
    const [showComplete, setShowComplete] = useState(false);

    const [submitStringLoss, setSubmitStringLoss] = useState('Mark as Lost');
    const [submitStringWin, setSubmitStringWin] = useState('Mark as Won');
    const [submitStringComplete, setSubmitStringComplete] = useState('Mark as complete');

    const [isDisabledLoss, setIsDisabledLoss] = useState(true);
    const [isDisabledWin, setIsDisabledWin] = useState(true);
    const [isDisabledComplete, setIsDisabledComplete] = useState(true);

    const [formLoad, setFormLoad] = useState(false);
    const [foldForm, setFoldForm] = useState(false);
    const [foldButtonString, setFoldButtonString] = useState(<i className="fas fa-angle-up"></i>);

    const [hasClient, setHasClient] = useState(false);

    const [year, setYear] = useState('');
    const [location, setLocation] = useState('');
    const [stage, setStage] = useState('');
    const [buildingClass, setBuildingClass] = useState([]);
    const [sector, setSector] = useState([]);
    const [services, setServices] = useState([]);

    const [lossReason, setLossReason] = useState('');

    const [cladding, setCladding] = useState(false);
    const [dcd, setDcd] = useState(false);
    const [cfd, setCfd] = useState(false);
    const [over25, setOver25] = useState(false);
    const [contract, setContract] = useState(false);
    const [insurance, setInsurance] = useState('');
    const [fee, setFee] = useState('');
    const [commencedOn, setCommencedOn] = useState('');

    const [completedOn, setCompletedOn] = useState('');
    const [reportIncludes, setReportIncludes] = useState([]);

    const [contractTask, setContractTask] = useState(true);

    const [contributorCount, setContributorCount] = useState(1);
    const [contMin, setContMin] = useState(true);
    const [contMax, setContMax] = useState(false);
    const [rangeTotal, setRangeTotal] = useState(0);
    const [rangeError, setRangeError] = useState(false);

    const [subbie1, setSubbie1] = useState(false);
    const [staff1, setStaff1] = useState({"value" : props.currentUser.id,
    "label" : props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName,
    "object": props.currentUser});
    const [range1, setRange1] = useState(0);
    const [subbieName1, setSubbieName1] = useState('');

    const [show2, setShow2] = useState(false);
    const [subbie2, setSubbie2] = useState(false);
    const [staff2, setStaff2] = useState();
    const [range2, setRange2] = useState(0);
    const [subbieName2, setSubbieName2] = useState('');

    const [show3, setShow3] = useState(false);
    const [subbie3, setSubbie3] = useState(false);
    const [staff3, setStaff3] = useState();
    const [range3, setRange3] = useState(0);
    const [subbieName3, setSubbieName3] = useState('');

    const [show4, setShow4] = useState(false);
    const [subbie4, setSubbie4] = useState(false);
    const [staff4, setStaff4] = useState();
    const [range4, setRange4] = useState(0);
    const [subbieName4, setSubbieName4] = useState('');

    const [show5, setShow5] = useState(false);
    const [subbie5, setSubbie5] = useState(false);
    const [staff5, setStaff5] = useState();
    const [range5, setRange5] = useState(0);
    const [subbieName5, setSubbieName5] = useState('');

    const [comment, setComment] = useState('');

    const today = new Date();

    const defaultSingleValue = (options, propsValue) => {
        return options.filter(option => option.value === propsValue);
    }

    const defaultMultiValue = (options, propsValue) => {
        return options.filter(option => 
            propsValue.map(propOption => {return propOption}).includes(option.value));
    }

    const simplifyMultiArray = (arrayToMod) => {
        var toReturn = [];
        for (let i = 0; i < arrayToMod.length; i++) {
            toReturn[i] = arrayToMod[i].value
        }
        return toReturn;
    }

    useEffect(()=>{
        if (props.pipeSwitch) {
            setMode('pipeline');
        } else if (props.databaseSwitch) {
            setMode('database');
        } else {
            setMode('current');
        }
    }, [props.pipeSwitch,props.databaseSwitch])

    useEffect(()=>{
        if (rangeTotal === 100) {
            setRangeError(false);
        } else {
            setRangeError(true);
        }
    }, [rangeTotal])

    useEffect(()=> {
        if (year.length === 0 || location.length === 0 || stage.length === 0) {
            setIsDisabledLoss(true);
        } else {
            (!buildingClass[0] || !sector[0] || !services[0] || lossReason[0]) ? setIsDisabledLoss(true) : setIsDisabledLoss(false)
            if (buildingClass[0] && sector[0] && services[0]) {
                if (!formLoad) {
                    setFormLoad(true);
                    handleFoldButton();
                }
            }
        }
    }, [year, location, stage, buildingClass, sector, services, lossReason]);

    useEffect(()=> {
        if (year.length === 0 || location.length === 0 || stage.length === 0 || insurance.length === 0 || fee.length < 1 || commencedOn === '') {
            setIsDisabledWin(true);
        } else {
            (!buildingClass[0] || !sector[0] || !services[0] || !commencedOn || !hasClient) ? setIsDisabledWin(true) : setIsDisabledWin(false)
        }
    }, [year, location, stage, buildingClass, sector, services, insurance, fee, commencedOn, hasClient]);

    useEffect(()=> {
        if ((completedOn === '') || (rangeError) || (rangeTotal !== 100)) {
            setIsDisabledComplete(true);
        } else if ((range1 === 0) || (subbie1 && (subbieName1 === '')) || (!subbie1 && !staff1)) {
            setIsDisabledComplete(true);
        } else if (show2 && ((range2 === 0) || (subbie2 && (subbieName2 === '')) || (!subbie2 && !staff2))) {
            setIsDisabledComplete(true);
        } else if (show3 && ((range3 === 0) || (subbie3 && (subbieName3 === '')) || (!subbie3 && !staff3))) {
            setIsDisabledComplete(true);
        } else if (show5 && ((range5 === 0) || (subbie5 && (subbieName5 === '')) || (!subbie5 && !staff5))) {
            setIsDisabledComplete(true);
        } else if (show4 && ((range4 === 0) || (subbie4 && (subbieName4 === '')) || (!subbie4 && !staff4))) {
            setIsDisabledComplete(true);
        } else {
            (!reportIncludes[0] || !completedOn || !comment) ? setIsDisabledComplete(true) : setIsDisabledComplete(false)
        }
    }, [reportIncludes, completedOn, rangeError, rangeTotal, subbie1, subbie2, subbie3, subbie4, subbie5, 
        subbieName1, subbieName2, subbieName3, subbieName4, subbieName5, show2, show3, show4, show5,
        staff1, staff2, staff3, staff4, staff5, range1, range2, range3, range4, range5, comment]);

    const loadMetas = () => {
        if (props.fromDashboard) {
            props.hideLastPopover();
        }
        if(props.row.values.orgDisplay.id !== 'FjB0exq7ZX' && (props.row.values.orgDisplay.id)) {
            setHasClient(true);
        }

        setYear(defaultSingleValue(yearOptions, props.row.values.yearDisplay));
        if (props.row.values.locationDisplay === '') {
            setLocation(defaultSingleValue(locationOptions, 'NSW'));
        } else {
            setLocation(defaultSingleValue(locationOptions, props.row.values.locationDisplay));
        }
        setStage(defaultSingleValue(stageOptions, props.row.values.stageDisplay));
        setBuildingClass(defaultMultiValue(classOptions, props.row.values.classDisplay.split(',')))
        setSector(defaultMultiValue(sectorOptions, props.row.values.sectorDisplay.split(',')))
        setServices(defaultMultiValue(servicesOptions, props.row.values.servicesDisplay.split(',')))

        if (props.row.values.claddingDisplay === 'true') {
            setCladding(true);
        }
        if (props.row.values.dcdDisplay === 'true') {
            setDcd(true);
        }
        if (props.row.values.cfdDisplay === 'true') {
            setCfd(true);
        }
        if (props.row.values.over25Display === 'true') {
            setOver25(true);
        }
        if (props.row.values.contractDisplay === 'true') {
            setContract(true);
        }
        setFee(props.row.values.feeDisplay);
        
        setReportIncludes(defaultMultiValue(reportIncludesOptions, props.row.values.reportIncludesDisplay.split(',')));

        if (props.row.values.insuranceDisplay === '') {
            setInsurance(defaultSingleValue(insuranceOptions, '$1 million'));
        } else {
            setInsurance(defaultSingleValue(insuranceOptions, props.row.values.insuranceDisplay));
        }

        if (props.row.values.commencedOnDisplay === '') {
            setCommencedOn(today);
        } else {
            setCommencedOn(props.row.values.commencedOnDisplay);
        }

        if (props.row.values.completedOnDisplay === '') {
            setCompletedOn(today);
        } else {
            setCompletedOn(props.row.values.completedOnDisplay);
        }

        // setComment(props.row.values.commentDisplay);

        setStaff1({"value" : props.currentUser.id,
            "label" : props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName,
            "object": props.currentUser});
    }

    const closeMetas = () => {
        setFormLoad(false);
        setFoldForm(false);
        setFoldButtonString(<i className="fas fa-angle-up"></i>);
        setHasClient(false);
        setYear('');
        setLocation('');
        setStage('');
        setBuildingClass([]);
        setSector([]);
        setServices([]);
        setCladding(false);
        setDcd(false);
        setCfd(false);
        setOver25(false);
        setContract(false);
        setInsurance('');
        setLossReason('');
        setFee('');
        setCommencedOn('');
        setCompletedOn('');
        setReportIncludes([]);
        setContractTask(true);
        setSubbie1(false);
        setRange1(0);
        setStaff1();
        setSubbieName1('');
        setContributorCount(1);
        setContMin(true);
        setContMax(false);
        setRangeTotal(0);
        setRangeError(false);

        setShow2(false);
        setSubbie2(false);
        setStaff2();
        setRange2(0);
        setSubbieName2('');

        setShow3(false);
        setSubbie3(false);
        setStaff3();
        setRange3(0);
        setSubbieName3('');

        setShow4(false);
        setSubbie4(false);
        setStaff4();
        setRange4(0);
        setSubbieName4('');

        setShow5(false);
        setSubbie5(false);
        setStaff5();
        setRange5(0);
        setSubbieName5('');

        setComment('');
    }

    const handleCloseLoss = () =>  {
        closeMetas()
        setSubmitStringLoss('Mark as Lost');
        setShowLoss(false);
    }

    const handleShowLoss = () => {
        loadMetas();
        setShowLoss(true);
    }

    const handleSubmitLoss = () => {
        setSubmitStringLoss(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const query = new Parse.Query(Parse.Object.extend('Project'));
        query.get(props.row.values.idDisplay)
        .then((project) =>{
            project.set('status', 'Loss');

            if (year.value) {
                project.set('year', parseInt(year.value));
            }
            if (location.value) {
                project.set('location', location.value);
            }
            if (stage.value) {
                project.set('stage', stage.value);
            }
            if (buildingClass[0]) {
                project.set('class', simplifyMultiArray(buildingClass));
            }
            if (sector[0]) {
                project.set('sector', simplifyMultiArray(sector));
            }
            if (services[0]) {
                project.set('service', simplifyMultiArray(services));
            }

            if (lossReason[0]) {
                project.set('lossFeedback', lossReason.value);
            }

            project.set('star', false);
            project.save()
            .then((res2)=>{
                handleCloseLoss();
            }).catch((error)=>{
                alert('Please send screenshot of this error message: ' + error.message);
                setSubmitStringLoss('Mark as Lost');
            })
        }).catch((error)=>{
            alert('Please send screenshot of this error message: ' + error.message);
            setSubmitStringLoss('Mark as Lost');
        })
    }

    const handleCloseWin = () =>  {
        closeMetas();
        setSubmitStringWin('Mark as won');
        setShowWin(false);
    }

    const handleShowWin = () => {
        loadMetas();
        setShowWin(true);
    }

    const handleSubmitWin = () => {
        setSubmitStringWin(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const query = new Parse.Query(Parse.Object.extend('Project'));
        query.get(props.row.values.idDisplay)
        .then((project) =>{
            project.set('status', 'Active');

            if (year.value) {
                project.set('year', parseInt(year.value));
            }
            if (location.value) {
                project.set('location', location.value);
            }
            if (stage.value) {
                project.set('stage', stage.value);
            }
            if (buildingClass[0]) {
                project.set('class', simplifyMultiArray(buildingClass));
            }
            if (sector[0]) {
                project.set('sector', simplifyMultiArray(sector));
            }
            if (services[0]) {
                project.set('service', simplifyMultiArray(services));
            }

            if (fee) {
                project.set('fee', parseInt(fee.slice(1)));
            }
            project.set('cladding', cladding);
            project.set('dcd', dcd);
            project.set('cfd', cfd);
            project.set('over25', over25);
            project.set('nonStandardContract', contract);
            if (insurance.value) {
                project.set('piInsurance', insurance.value);
            }
            if (commencedOn !== '') {
                project.set('commencedOn', commencedOn);
            }
            const today = new Date();
            const fourweeks = new Date(today);
            fourweeks.setDate(fourweeks.getDate() + 28);
            project.set('deadline', fourweeks);

            if (contract && contractTask) {
                const Task = Parse.Object.extend('Task');
                const task = new Task();

                var reviewUser = defaultSingleValue(props.projectManagerOptions, 'BKD0LlA2EC');
                var existingProject = defaultSingleValue(props.projectOptions, props.row.values.idDisplay);;

                task.set('assignedTo', reviewUser[0].object);
                task.set('action', 'Review contract');
                task.set('recurring', false);
                task.set('link', false);
                task.set('complete', false);
                task.set('deadline', today);
                task.set('createdBy', props.currentUser);

                var taskACL = new Parse.ACL();
                taskACL.setRoleReadAccess('staff', true);
                taskACL.setRoleWriteAccess('staff', true);
                task.setACL(taskACL);

                task.set('project', existingProject[0].object);
                task.save()
                .then((task)=>{
                    setContractTask(false);
                }, (error) => {
                    alert('Please send screenshot of this error message: ' + error.message);
                });
            }
            project.set('star', false);
            project.save()
            .then((res2)=>{
                handleCloseWin();
                props.handleCelebrate();
            }).catch((error)=>{
                alert('Please send screenshot of this error message: ' + error.message);
                setSubmitStringWin('Mark as Won');
            })
        }).catch((error)=>{
            alert('Please send screenshot of this error message: ' + error.message);
            setSubmitStringWin('Mark as Won');
        })
    }

    const handleCloseComplete = () =>  {
        closeMetas();
        setSubmitStringComplete('Mark as complete');
        setShowComplete(false);
    }

    const handleShowComplete = () => {
        loadMetas();
        setShowComplete(true);
    }

    const handleSubmitComplete = () => {
        setSubmitStringComplete(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const query = new Parse.Query(Parse.Object.extend('Project'));
        query.get(props.row.values.idDisplay)
        .then((project) =>{
            project.set('status', 'Complete');

            if (props.row.values.feedbackDisplay !== 'Feedback sent') {
                project.set('sendFeedback', 'To send');
            }

            if (reportIncludes[0]) {
                project.set('reportIncludes', simplifyMultiArray(reportIncludes));
            }
            if (completedOn !== '') {
                project.set('completedOn', completedOn);
            }
            project.set('star', false);
            project.set('comment', comment)
            project.save()
            .then((res2)=>{
                const Cont = Parse.Object.extend('Contribution');
                const cont = new Cont();

                var quarterNa = moment(today).quarter();
                var quarterAu = 0;
                var yearNa = moment(today).year();
                var yearAu = 0;

                switch (quarterNa) {
                    case 1:
                        quarterAu = 3;
                        yearAu = yearNa;
                        break;
                    case 2:
                        quarterAu = 4;
                        yearAu = yearNa;
                        break;
                    case 3:
                        quarterAu = 1;
                        yearAu = yearNa + 1;
                        break;
                    case 4:
                        quarterAu = 2;
                        yearAu = yearNa + 1;
                        break;
                
                    default:
                        break;
                }

                var contArr = [];

                if (subbie1) {
                    contArr.push(subbieName1 + ': ' + range1);
                } else {
                    contArr.push(staff1.label + ': ' + range1);
                }

                if (show2) {
                    if (subbie2) {
                        contArr.push(subbieName2 + ': ' + range2);
                    } else {
                        contArr.push(staff2.label + ': ' + range2);
                    }
                }

                if (show3) {
                    if (subbie3) {
                        contArr.push(subbieName3 + ': ' + range3);
                    } else {
                        contArr.push(staff3.label + ': ' + range3);
                    }
                }

                if (show4) {
                    if (subbie4) {
                        contArr.push(subbieName4 + ': ' + range4);
                    } else {
                        contArr.push(staff4.label + ': ' + range4);
                    }
                }

                if (show5) {
                    if (subbie5) {
                        contArr.push(subbieName5 + ': ' + range5);
                    } else {
                        contArr.push(staff5.label + ': ' + range5);
                    }
                }

                cont.set('createdBy', props.currentUser);
                cont.set('project', res2);
                cont.set('quarter', parseInt(quarterAu));
                cont.set('year', parseInt(yearAu));
                cont.set('comment', '');
                cont.set('approved', true);
                cont.set('contributions', contArr);

                var contACL = new Parse.ACL();
                contACL.setRoleReadAccess('staff', true);
                contACL.setRoleWriteAccess('staff', true);
                cont.setACL(contACL);

                cont.save()
                .then((cont)=>{
                    handleCloseComplete();
                    props.handleCelebrate();
                }, (error) => {
                    alert('Please send screenshot of this error message: ' + error.message);
                    setSubmitStringComplete('Mark as complete');
                });
            }).catch((error)=>{
                alert('Please send screenshot of this error message: ' + error.message);
                setSubmitStringComplete('Mark as complete');
            })
        }).catch((error)=>{
            alert('Please send screenshot of this error message: ' + error.message);
            setSubmitStringComplete('Mark as complete');
        })
    }

    const addContribution = () => {
        var cont = contributorCount;
        setContributorCount(cont + 1);
        switch (cont + 1) {
            case 1:
                // doesn't exist
                break;
            case 2:
                setShow2(true);
                setContMin(false);
                break;
            case 3:
                setShow3(true);
                break;
            case 4:
                setShow4(true);
                break;
            case 5:
                setShow5(true);
                setContMax(true);
                break;
            default:
                break;
        }
    }

    const removeContribution = () => {
        var cont = contributorCount;
        setContributorCount(cont - 1);
        switch (cont) {
            case 1:
                // doesn't exist
                break;
            case 2:
                setShow2(false);
                setContMin(true);
                setRange2(0);
                break;
            case 3:
                setShow3(false);
                setRange3(0);
                break;
            case 4:
                setShow4(false);
                setRange4(0);
                break;
            case 5:
                setShow5(false);
                setRange5(0);
                setContMax(false);
                break;
            default:
                break;
        }
    }

    const handleRange1 = (range) => {
        setRange1(range);
        setRangeTotal(parseInt(range) + parseInt(range2) + parseInt(range3) + parseInt(range4) + parseInt(range5)); 
    }

    const handleRange2 = (range) => {
        setRange2(range);
        setRangeTotal(parseInt(range1) + parseInt(range) + parseInt(range3) + parseInt(range4) + parseInt(range5)); 
    }

    const handleRange3 = (range) => {
        setRange3(range);
        setRangeTotal(parseInt(range1) + parseInt(range2) + parseInt(range) + parseInt(range4) + parseInt(range5)); 
    }

    const handleRange4 = (range) => {
        setRange4(range);
        setRangeTotal(parseInt(range1) + parseInt(range2) + parseInt(range3) + parseInt(range) + parseInt(range5)); 
    }

    const handleRange5 = (range) => {
        setRange5(range);
        setRangeTotal(parseInt(range1) + parseInt(range2) + parseInt(range3) + parseInt(range4) + parseInt(range)); 
    }

    const handleFoldButton = () => {
        if (foldForm) {
            setFoldForm(false);
            setFoldButtonString(<i className="fas fa-angle-up"></i>);
        } else {
            setFoldForm(true);
            setFoldButtonString(<i className="fas fa-angle-down"></i>);
        }
    }
    
    switch (mode) {
        case 'pipeline':
            return <>
                <Stack direction="horizontal" gap={1} >
                    <div>
                        <Button variant='outline-success' className='square-button' onClick={handleShowWin}>
                            <i className="fas fa-check"></i>
                        </Button>
                    </div>
                    <div>
                        <Button variant='outline-danger' className='square-button' onClick={handleShowLoss}>
                            <i className="fas fa-times"></i>
                        </Button>
                    </div>
                </Stack>
                <Modal show={showLoss} onHide={handleCloseLoss} onExited={props.handleExit} centered size='lg' backdrop="static">
                    <Modal.Header className='modal-header' closeButton>
                        <Modal.Title>Mark Loss: {props.row.values.projectNumberDisplay} — {props.row.values.projectNameDisplay}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='modal-body'>
                        <Form onSubmit={handleSubmitLoss}>
                            <Row className="mb-4">
                                <Form.Group as={Col} md="4">
                                    <Form.Label>Reason</Form.Label>
                                    <Select
                                        styles={customStyles}
                                        options={lossOptions}
                                        value={lossReason}
                                        defaultValue={lossReason}
                                        onChange={e=>setLossReason(e)}
                                    />
                                </Form.Group>
                                <Button
                                    variant="outline-secondary"
                                    className='square-button ms-auto'
                                    onClick={handleFoldButton}
                                >
                                    {foldButtonString}
                                </Button>
                            </Row>
                            {!foldForm && (
                                <span>
                                    <Row className="mb-4">
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Year</Form.Label>
                                            <Select
                                                styles={customStyles}
                                                options={yearOptions}
                                                value={year}
                                                defaultValue={year}
                                                onChange={e=>setYear(e)}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Location</Form.Label>
                                            <Select
                                                styles={customStyles}
                                                options={locationOptions}
                                                value={location}
                                                defaultValue={location}
                                                onChange={e=>setLocation(e)}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Stage</Form.Label>
                                            <Select
                                                styles={customStyles}
                                                options={stageOptions}
                                                value={stage}
                                                defaultValue={stage}
                                                onChange={e=>setStage(e)}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-4">
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Class</Form.Label>
                                            <Select
                                                styles={customStyles}
                                                options={classOptions}
                                                value={buildingClass}
                                                defaultValue={buildingClass}
                                                onChange={e=>setBuildingClass(e)}
                                                isMulti
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Sector</Form.Label>
                                            <Select
                                                styles={customStyles}
                                                options={sectorOptions}
                                                value={sector}
                                                defaultValue={sector}
                                                onChange={e=>setSector(e)}
                                                isMulti
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Services</Form.Label>
                                            <Select
                                                styles={customStyles}
                                                options={servicesOptions}
                                                value={services}
                                                defaultValue={services}
                                                onChange={e=>setServices(e)}
                                                isMulti
                                            />
                                        </Form.Group>
                                    </Row>
                                </span>
                            )}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer className='modal-header'>
                        <Button variant="secondary" onClick={handleCloseLoss}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleSubmitLoss}
                            disabled={isDisabledLoss}
                        >
                            {submitStringLoss}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showWin} onHide={handleCloseWin} onExited={props.handleExit} centered size='lg' backdrop="static">
                    <Modal.Header className='modal-header' closeButton>
                        <Modal.Title>Mark Win: {props.row.values.projectNumberDisplay} — {props.row.values.projectNameDisplay}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='modal-body'>
                        <Form onSubmit={handleSubmitWin}>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="4">
                                    <Form.Label>Fee (AUD)</Form.Label>
                                    <NumericFormat
                                        className="form-control"
                                        displayType="input"
                                        prefix={'$'}
                                        allowNegative={false}
                                        value = {fee}
                                        onChange={(e) => setFee(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label>Commenced On</Form.Label>
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        className='form-control'
                                        selected={commencedOn}
                                        onChange={(date)=> setCommencedOn(date)}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label>PI Insurance</Form.Label>
                                    <Select
                                        styles={customStyles}
                                        options={insuranceOptions}
                                        value={insurance}
                                        defaultValue={insurance}
                                        onChange={e=>setInsurance(e)}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="2">
                                    <Form.Label>Cladding</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        label=""
                                        checked={cladding}
                                        onChange={(e) => setCladding(e.target.checked)}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="2">
                                    <Form.Label>DCD</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        label=""
                                        checked={dcd}
                                        onChange={(e) => setDcd(e.target.checked)}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="2">
                                    <Form.Label>CFD</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        label=""
                                        checked={cfd}
                                        onChange={(e) => setCfd(e.target.checked)}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="2">
                                    <Form.Label>Over 25m</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        label=""
                                        checked={over25}
                                        onChange={(e) => setOver25(e.target.checked)}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label>Non-Standard Contract</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        label=""
                                        checked={contract}
                                        onChange={(e) => setContract(e.target.checked)}
                                    />
                                    {contract && (
                                        <Form.Check
                                            label="Create contract review task"
                                            checked={contractTask}
                                            onChange={(e) => setContractTask(e.target.checked)}
                                        />
                                    )}
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Button
                                    variant="outline-secondary"
                                    className='square-button ms-auto'
                                    onClick={handleFoldButton}
                                >
                                    {foldButtonString}
                                </Button>
                            </Row>
                            {!foldForm && (
                                <span>
                                    <Row className="mb-2">
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Year</Form.Label>
                                            <Select
                                                styles={customStyles}
                                                options={yearOptions}
                                                value={year}
                                                defaultValue={year}
                                                onChange={e=>setYear(e)}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Location</Form.Label>
                                            <Select
                                                styles={customStyles}
                                                options={locationOptions}
                                                value={location}
                                                defaultValue={location}
                                                onChange={e=>setLocation(e)}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Stage</Form.Label>
                                            <Select
                                                styles={customStyles}
                                                options={stageOptions}
                                                value={stage}
                                                defaultValue={stage}
                                                onChange={e=>setStage(e)}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-4">
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Class</Form.Label>
                                            <Select
                                                styles={customStyles}
                                                options={classOptions}
                                                value={buildingClass}
                                                defaultValue={buildingClass}
                                                onChange={e=>setBuildingClass(e)}
                                                isMulti
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Sector</Form.Label>
                                            <Select
                                                styles={customStyles}
                                                options={sectorOptions}
                                                value={sector}
                                                defaultValue={sector}
                                                onChange={e=>setSector(e)}
                                                isMulti
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Services</Form.Label>
                                            <Select
                                                styles={customStyles}
                                                options={servicesOptions}
                                                value={services}
                                                defaultValue={services}
                                                onChange={e=>setServices(e)}
                                                isMulti
                                            />
                                        </Form.Group>
                                    </Row>
                                </span>
                            )}
                            
                            {!hasClient && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="12">
                                        <Stack direction='horizontal' gap={1}>
                                            <ProjectClient
                                                row={props.row}
                                                handleExit={props.handleExit}
                                                progressMode={true}
                                                users={props.users}
                                                setUsers={props.setUsers}
                                                userOptions={props.userOptions}
                                                setUserOptions={props.setUserOptions}
                                                orgs={props.orgs}
                                                setOrgs={props.setOrgs}
                                                orgOptions={props.orgOptions}
                                                setOrgOptions={props.setOrgOptions}
                                                projectData={[...props.projectData]}
                                                setProjectData={props.setProjectData}
                                                hasClient={hasClient}
                                                setHasClient={setHasClient}
                                            />
                                            <span>&nbsp;&nbsp;&nbsp;
                                                <span className='form-fail'>
                                                    No client linked to project
                                                </span>
                                            </span>
                                            <span
                                                className='ms-auto'
                                            >
                                                &nbsp;
                                            </span>
                                        </Stack>
                                    </Form.Group>
                                </Row>
                            )}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer className='modal-header'>
                        <Button variant="secondary" onClick={handleCloseWin}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleSubmitWin}
                            disabled={isDisabledWin}
                        >
                            {submitStringWin}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        case 'current':
            return <>
                <Button variant='outline-success' className='square-button' onClick={handleShowComplete}>
                    <i className="fas fa-check"></i>
                </Button>
                <Modal show={showComplete} onHide={handleCloseComplete} onExited={props.handleExit} centered size='lg' backdrop="static">
                    <Modal.Header className='modal-header' closeButton>
                        <Modal.Title>Mark Complete: {props.row.values.projectNumberDisplay} — {props.row.values.projectNameDisplay}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='modal-body'>
                        <Form onSubmit={handleSubmitComplete}>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="8">
                                    <Form.Label>Performance Solutions <span style={{color: "var(--danger)"}}> *</span></Form.Label>
                                    <Select
                                        styles={customStyles}
                                        options={reportIncludesOptions}
                                        value={reportIncludes}
                                        defaultValue={reportIncludes}
                                        onChange={e=>setReportIncludes(e)}
                                        isMulti
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label>Completed On <span style={{color: "var(--danger)"}}> *</span></Form.Label>
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        className='form-control'
                                        selected={completedOn}
                                        onChange={(date)=> setCompletedOn(date)}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col}>
                                    <Form.Label>After Action Report <span style={{color: "var(--danger)"}}> *</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="12">
                                    <Form.Label>Project Contributions <span style={{color: "var(--danger)"}}> *</span></Form.Label>
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="1" className='subbie'>
                                    <Form.Check
                                        label="Sub"
                                        checked={subbie1}
                                        onChange={(e) => setSubbie1(e.target.checked)}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    {!subbie1 && (
                                        <Select
                                            styles={customStyles}
                                            options={props.staffOptions}
                                            value={staff1}
                                            onChange={e=>setStaff1(e)}
                                        />
                                    )}
                                    {subbie1 && (
                                        <Form.Control
                                            type="text"
                                            placeholder='Subbie name'
                                            value={subbieName1}
                                            onChange={(e) => setSubbieName1(e.target.value)}
                                        />
                                    )}
                                </Form.Group>
                                <Form.Group as={Col} md="7">
                                    <Form.Group as={Row}>
                                        <Col xs="10">
                                            <RangeSlider
                                                value={range1}
                                                onChange={e => handleRange1(e.target.value)}
                                                variant='success'
                                                step={10}
                                            />
                                        </Col>
                                        <Col xs="2">
                                            <Form.Control 
                                                value={range1}
                                                onChange={(e) => handleRange1(e.target.value)}
                                            />
                                        </Col>
                                    </Form.Group>
                                </Form.Group>
                            </Row>
                            {show2 && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="1" className='subbie'>
                                        <Form.Check
                                            label="Sub"
                                            checked={subbie2}
                                            onChange={(e) => setSubbie2(e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        {!subbie2 && (
                                            <Select
                                                placeholder='Select team member'
                                                styles={customStyles}
                                                options={props.staffOptions}
                                                value={staff2}
                                                onChange={e=>setStaff2(e)}
                                            />
                                        )}
                                        {subbie2 && (
                                            <Form.Control
                                                type="text"
                                                placeholder='Subbie name'
                                                value={subbieName2}
                                                onChange={(e) => setSubbieName2(e.target.value)}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} md="7">
                                        <Form.Group as={Row}>
                                            <Col xs="10">
                                                <RangeSlider
                                                    value={range2}
                                                    onChange={e => handleRange2(e.target.value)}
                                                    variant='danger'
                                                    step={10}
                                                />
                                            </Col>
                                            <Col xs="2">
                                                <Form.Control 
                                                    value={range2}
                                                    onChange={(e) => handleRange2(e.target.value)}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Form.Group>
                                </Row>
                            )}
                            {show3 && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="1" className='subbie'>
                                        <Form.Check
                                            label="Sub"
                                            checked={subbie3}
                                            onChange={(e) => setSubbie3(e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        {!subbie3 && (
                                            <Select
                                                placeholder='Select team member'
                                                styles={customStyles}
                                                options={props.staffOptions}
                                                value={staff3}
                                                onChange={e=>setStaff3(e)}
                                            />
                                        )}
                                        {subbie3 && (
                                            <Form.Control
                                                type="text"
                                                placeholder='Subbie name'
                                                value={subbieName3}
                                                onChange={(e) => setSubbieName3(e.target.value)}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} md="7">
                                        <Form.Group as={Row}>
                                            <Col xs="10">
                                                <RangeSlider
                                                    value={range3}
                                                    onChange={e => handleRange3(e.target.value)}
                                                    variant='primary'
                                                    step={10}
                                                />
                                            </Col>
                                            <Col xs="2">
                                                <Form.Control 
                                                    value={range3}
                                                    onChange={(e) => handleRange3(e.target.value)}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Form.Group>
                                </Row>
                            )}
                            {show4 && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="1" className='subbie'>
                                        <Form.Check
                                            label="Sub"
                                            checked={subbie4}
                                            onChange={(e) => setSubbie4(e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        {!subbie4 && (
                                            <Select
                                                placeholder='Select team member'
                                                styles={customStyles}
                                                options={props.staffOptions}
                                                value={staff4}
                                                onChange={e=>setStaff4(e)}
                                            />
                                        )}
                                        {subbie4 && (
                                            <Form.Control
                                                type="text"
                                                placeholder='Subbie name'
                                                value={subbieName4}
                                                onChange={(e) => setSubbieName4(e.target.value)}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} md="7">
                                        <Form.Group as={Row}>
                                            <Col xs="10">
                                                <RangeSlider
                                                    value={range4}
                                                    onChange={e => handleRange4(e.target.value)}
                                                    variant='warning'
                                                    step={10}
                                                />
                                            </Col>
                                            <Col xs="2">
                                                <Form.Control 
                                                    value={range4}
                                                    onChange={(e) => handleRange4(e.target.value)}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Form.Group>
                                </Row>
                            )}
                            {show5 && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="1" className='subbie'>
                                        <Form.Check
                                            label="Sub"
                                            checked={subbie5}
                                            onChange={(e) => setSubbie5(e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        {!subbie5 && (
                                            <Select
                                                placeholder='Select team member'
                                                styles={customStyles}
                                                options={props.staffOptions}
                                                value={staff5}
                                                onChange={e=>setStaff5(e)}
                                            />
                                        )}
                                        {subbie5 && (
                                            <Form.Control
                                                type="text"
                                                placeholder='Subbie name'
                                                value={subbieName5}
                                                onChange={(e) => setSubbieName5(e.target.value)}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} md="7">
                                        <Form.Group as={Row}>
                                            <Col xs="10">
                                                <RangeSlider
                                                    value={range5}
                                                    onChange={e => handleRange5(e.target.value)}
                                                    variant='info'
                                                    step={10}
                                                />
                                            </Col>
                                            <Col xs="2">
                                                <Form.Control 
                                                    value={range5}
                                                    onChange={(e) => handleRange5(e.target.value)}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Form.Group>
                                </Row>
                            )}
                            <Row className="mb-4">
                                <Form.Group as={Col} md="12">
                                    <Stack direction='horizontal' gap={1}>
                                        <ProjectTasks
                                            row={props.row}
                                            taskData={[...props.taskData]}
                                            pipeSwitch={props.pipeSwitch}
                                            databaseSwitch={props.databaseSwitch}
                                            staffOptions={props.staffOptions}
                                            projectManagerOptions={props.projectManagerOptions}
                                            projectOptions={props.projectOptions}
                                            projectData={[...props.projectData]}
                                            currentUser={props.currentUser}
                                            dummyOrg={props.dummyOrg}
                                            dummyUser={props.dummyUser}
                                            nextProjectNumber={props.nextProjectNumber}
                                            projectMode={false}
                                            noText={true}
                                            handleExit={props.handleExit}
                                            progressMode={true}
                                        />
                                        {rangeError && (
                                            <span>&nbsp;&nbsp;&nbsp;
                                                <span className='form-fail'>
                                                    Sum of contributions does not equal 100
                                                </span>
                                            </span>
                                        )}
                                        {!contMax && (
                                            <Button
                                                onClick={addContribution}
                                                className='ms-auto'
                                                variant='outline-primary'
                                            >
                                                <i className="fas fa-plus"/>
                                            </Button>
                                        )}
                                        {(!contMin && !contMax) && (
                                            <Button
                                                onClick={removeContribution}
                                                variant='outline-secondary'
                                            >
                                                <i className="fas fa-minus"/>
                                            </Button>
                                        )}
                                        {(contMax) && (
                                            <Button
                                                onClick={removeContribution}
                                                className='ms-auto'
                                                variant='outline-secondary'
                                            >
                                                <i className="fas fa-minus"/>
                                            </Button>
                                        )}
                                    </Stack>
                                </Form.Group>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer className='modal-header'>
                        <Button variant="secondary" onClick={handleCloseComplete}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleSubmitComplete}
                            disabled={isDisabledComplete}
                        >
                            {submitStringComplete}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        default:
            return <>
                <span></span>
            </>
    }
}
