import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Form, Row, Col, Spinner} from 'react-bootstrap';

export default function EditTaskAction(props) {

    const [show, setShow] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [action, setAction] = useState();
    const [submitString, setSubmitString] = useState('Save Task');

    useEffect(()=> {
        (!action) ? setIsDisabled(true) : setIsDisabled(false)
    }, [action]);

    const handleClose = () =>  {
        setAction('');
        setSubmitString('Save Task');
        setShow(false);
    }

    const handleShow = () => {
        setAction(props.row.values.actionTextDisplay);
        setShow(true);
    }

    const handleSubmit = () => {
        setSubmitString(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const taskId = props.row.values.idDisplay;

        const Parse = require('parse');
        const query = new Parse.Query(Parse.Object.extend('Task'));
        query.get(taskId)
        .then((task) => {
            task.set('action', action);
            task.save()
            .then((res)=>{
                const dataCopy = props.taskData;
                var thisTask = dataCopy[props.row.index];
                if (action) {
                    thisTask.actionTextDisplay = action;
                }
                if (thisTask.linkDisplay === 'true') {
                    thisTask.actionDisplay = <a href={thisTask.url} target='_blank'>{action}</a>;
                } else {
                    thisTask.actionDisplay = action;
                }
                dataCopy[props.row.index] = thisTask;
                props.setTaskData(dataCopy);
                handleClose();
            }).catch((error)=>{
                alert('Please send screenshot of this error message: ' + error.message);
                setSubmitString('Save Task');
            })
        }).catch((error)=>{
            alert('Please send screenshot of this error message: ' + error.message);
            setSubmitString('Save Task');
        })
    }

    const ActionDisplay = () => {
        var hasAssigned = false;
        var isRecurringTask = false;
        var hasProject = false;
        if (props.row.values.assignedDisplay.length > 2) {
            hasAssigned = true;
        } 
        if (props.row.values.projObjIdDisplay.length > 2) {
            hasProject = true;
        } 
        if (props.row.values.isRecurring) {
            isRecurringTask = true;
        } 

        if (!props.slimView) {
            if (hasAssigned && isRecurringTask) {
                return <>
                    <div onDoubleClick={handleShow}>
                        <span style={{color:'var(--text)', fontSize:'1.2em'}}>{props.cell}</span> <i style={{color:'var(--teal)'}} className="fas fa-history"></i>
                        <div style={{color:'var(--text-muted)', fontSize:'0.8em'}}>From: {props.row.values.assignedDisplay}</div>
                    </div>
                </>
            } else if (isRecurringTask) {
                return <>
                    <div onDoubleClick={handleShow}>
                        <span style={{color:'var(--text)', fontSize:'1.2em'}}>{props.cell}</span> <i style={{color:'var(--teal)'}} className="fas fa-history"></i>
                    </div>
                </>
            } else if (hasAssigned) {
                return <>
                    <div onDoubleClick={handleShow}>
                        <span style={{color:'var(--text)', fontSize:'1.2em'}}>{props.cell}</span>
                        <div style={{color:'var(--text-muted)', fontSize:'0.8em'}}>From: {props.row.values.assignedDisplay}</div>
                    </div>
                </>
            } else {
                return <>
                    <div onDoubleClick={handleShow}>
                        <span style={{color:'var(--text)', fontSize:'1.2em'}}>{props.cell}</span>
                    </div>
                </>
            }
        } else {
            if (hasProject && hasAssigned && isRecurringTask) {
                return <>
                    <div onDoubleClick={handleShow}>
                        <span style={{color:'var(--text)', fontSize:'1.2em'}}>{props.cell}</span> <i style={{color:'var(--teal)'}} className="fas fa-history"></i>
                        <div style={{color:'var(--text)', fontSize:'1em'}}><Link target='_blank' to={'/staff/' + props.row.values.projStringDisplay.slice(0,6)}>{props.row.values.projStringDisplay}</Link></div>
                        <div style={{color:'var(--text-muted)', fontSize:'0.8em'}}>From: {props.row.values.assignedDisplay}</div>
                    </div>
                </>
            } else if (hasProject && hasAssigned) {
                return <>
                    <div onDoubleClick={handleShow}>
                        <span style={{color:'var(--text)', fontSize:'1.2em'}}>{props.cell}</span>
                        <div style={{color:'var(--text)', fontSize:'1em'}}><Link target='_blank' to={'/staff/' + props.row.values.projStringDisplay.slice(0,6)}>{props.row.values.projStringDisplay}</Link></div>
                        <div style={{color:'var(--text-muted)', fontSize:'0.8em'}}>From: {props.row.values.assignedDisplay}</div>
                    </div>
                </>
            } else if (hasProject && isRecurringTask) {
                return <>
                    <div onDoubleClick={handleShow}>
                        <span style={{color:'var(--text)', fontSize:'1.2em'}}>{props.cell}</span> <i style={{color:'var(--teal)'}} className="fas fa-history"></i>
                        <div style={{color:'var(--text)', fontSize:'1em'}}><Link target='_blank' to={'/staff/' + props.row.values.projStringDisplay.slice(0,6)}>{props.row.values.projStringDisplay}</Link></div>
                    </div>
                </>
            } else if (hasAssigned && isRecurringTask) {
                return <>
                    <div onDoubleClick={handleShow}>
                        <span style={{color:'var(--text)', fontSize:'1.2em'}}>{props.cell}</span> <i style={{color:'var(--teal)'}} className="fas fa-history"></i>
                        <div style={{color:'var(--text-muted)', fontSize:'0.8em'}}>From: {props.row.values.assignedDisplay}</div>
                    </div>
                </>
            } else if (hasProject) {
                return <>
                    <div onDoubleClick={handleShow}>
                        <span style={{color:'var(--text)', fontSize:'1.2em'}}>{props.cell}</span>
                        <div style={{color:'var(--text)', fontSize:'1em'}}><Link target='_blank' to={'/staff/' + props.row.values.projStringDisplay.slice(0,6)}>{props.row.values.projStringDisplay}</Link></div>
                    </div>
                </>
            } else if (hasAssigned) {
                return <>
                    <div onDoubleClick={handleShow}>
                        <span style={{color:'var(--text)', fontSize:'1.2em'}}>{props.cell}</span>
                        <div style={{color:'var(--text-muted)', fontSize:'0.8em'}}>From: {props.row.values.assignedDisplay}</div>
                    </div>
                </>
            } else if (isRecurringTask) {
                return <>
                    <div onDoubleClick={handleShow}>
                        <span style={{color:'var(--text)', fontSize:'1.2em'}}>{props.cell}</span> <i style={{color:'var(--teal)'}} className="fas fa-history"></i>
                    </div>
                </>
            } else {
                return <>
                    <div onDoubleClick={handleShow}>
                        <span style={{color:'var(--text)', fontSize:'1.2em'}}>{props.cell}</span>
                    </div>
                </>
            }
        }
    }

    return <>
        <ActionDisplay />
        <Modal show={show} onHide={handleClose} onExited={props.handleExit} centered size='md' backdrop="static">
            <Modal.Header className='modal-header' closeButton>
                <Modal.Title>Edit Task</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Action</Form.Label>
                            <Form.Control
                                id='action-text'
                                type='text'
                                value={action}
                                autoFocus
                                onChange={(e) => {setAction(e.target.value)}}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                        handleSubmit();
                                    }
                                }}
                            />
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                >
                    {submitString}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
