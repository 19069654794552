import React, { useState, useEffect} from 'react';
import { Container, Row, Col, Button} from 'react-bootstrap';
import ScriptTemplate from './ScriptTemplate';
import AddSecondToAllProjects from './AddSecondToAllProjects';
import AddSettingsToUserRow from './AddSettingsToUserRow';
import AddOrgToUserRow from './AddOrgToUserRow';
import ProjectsDeadline from './ProjectsDeadline';
import PdfUpload from '../project-dashboard/PdfUpload';
import { read, utils } from 'xlsx';
import { CSVLink } from "react-csv";


import history from '../../utils/History';

export default function Danger() {

    useEffect(() => {
        document.title = "DANGER ZONE";
    }, []);

    const Parse = require('parse');
    const currentUser = Parse.User.current();

    const [userIsSuperAdmin, setUserIsSuperAdmin] = useState(false);
    const [projectsLoaded, setProjectsLoaded] = useState(false);
    const [projects, setProjects] = useState([]);
    const [contribs, setContribs] = useState([]);
    const [isContLoaded, setIsContLoaded] = useState(false);
    const [isProjTableLoaded, setIsProjTableLoaded] = useState(false);


    const [staff, setStaff] = useState([]);
    const [dummyOrg, setDummyOrg] = useState();
    const [dummyUser, setDummyUser] = useState();

    useEffect(() => {
        queryAdmin(); 
        queryDummyOrg();
        queryDummyUser();
        queryStaff();
        queryProjects();
        queryContribs();
    },[])

    const queryAdmin = () => {
        if (currentUser.id === 'NS7ecaLkAU') {
            setUserIsSuperAdmin(true);
        } else {
            history.push('/');
            history.go(0);
        }
    }

    const queryDummyOrg = () => {
        const Org = Parse.Object.extend('Organisation');
        const query = new Parse.Query(Org);
        query.get('FjB0exq7ZX').then((res) => {
            setDummyOrg(res);
        }).catch((error) =>  {
            alert(error);
        });
    }

    const queryDummyUser = () => {
        const User = Parse.Object.extend('User');
        const query = new Parse.Query(User);
        query.get('vebHQNgIU2').then((res) => {
            setDummyUser(res);
        }).catch((error) =>  {
            alert(error);
        });
    }

    const queryStaff = () => {
        const query = new Parse.Query(Parse.Role);
        query.equalTo('name','projectManagers').first().then(staff => { 
            const users = staff.getUsers().query();
            users.equalTo('status', 'Active');
            users.ascending('firstName');
            users.find()
            .then((userQueryRes) => {
                setStaff(userQueryRes);
            }, (error) => {
                alert(error);
            })
        }, (error) => {
            alert(error);
        })
    }

    const queryProjects = () => {
        const Project = Parse.Object.extend('Project');
        const query = new Parse.Query(Project);
        query.limit(999999);
        query.includeAll();
        query.find()
        .then(function(res) {
            setProjects(res);
            setProjectsLoaded(true);
        }, (error) => {
            alert(error);
        });
    }

    const queryContribs = () => {
        const Contribution = Parse.Object.extend('Contribution');
        const query = new Parse.Query(Contribution);
        query.limit(999999);
        query.includeAll();
        query.find()
        .then(function(res) {
            setContribs(res);
        }, (error) => {
            alert(error);
        });
    }

    const getContData = () => {

        var data2 = [];

        for (var i = 0; i < contribs.length; i++) {
            var cont = contribs[i];
            var projString = cont.get('project').attributes.projectId + ' — ' + cont.get('project').attributes.name;
            const contArr = cont.get('contributions');

            var n0 = '';
            var p0 = 0;
            var n1 = '';
            var p1 = 0;
            var n2 = '';
            var p2 = 0;
            var n3 = '';
            var p3 = 0;
            var n4 = '';
            var p4 = 0;

            if (contArr[0]) {
                n0 = contArr[0].substring(0, contArr[0].indexOf(":"));
                p0 = contArr[0].substring(contArr[0].indexOf(':') + 1);
            }
            if (contArr[1]) {
                n1 = contArr[1].substring(0, contArr[1].indexOf(":"));
                p1 = contArr[1].substring(contArr[1].indexOf(':') + 1);
            }
            if (contArr[2]) {
                n2 = contArr[2].substring(0, contArr[2].indexOf(":"));
                p2 = contArr[2].substring(contArr[2].indexOf(':') + 1);
            }
            if (contArr[3]) {
                n3 = contArr[3].substring(0, contArr[3].indexOf(":"));
                p3 = contArr[3].substring(contArr[3].indexOf(':') + 1);
            }
            if (contArr[4]) {
                n4 = contArr[4].substring(0, contArr[4].indexOf(":"));
                p4 = contArr[4].substring(contArr[4].indexOf(':') + 1);
            }
            data2.push({
                id: i,
                item: projString,
                n0: n0,
                p0: p0,
                n1: n1,
                p1: p1,
                n2: n2,
                p2: p2,
                n3: n3,
                p3: p3,
                n4: n4,
                p4: p4,
            })
        }

        return data2;
    }

    const [contData, setContData] = React.useState(React.useMemo(() => getContData, [projectsLoaded]));
    if (!isContLoaded) {
        if (contribs.length > 0) {
            setContData(getContData);
            setIsContLoaded(true);
        }
    }

    const csvData = [
        ["Item", "PM", "%", "Rev 1", "%", "Rev 2", "%", "Rev 3", "%", "Rev 4", "%"],
        ...contData.map(({ item, n0, p0, n1, p1, n2, p2, n3, p3, n4, p4 }) => [
            item, 
            n0, 
            p0, 
            n1, 
            p1, 
            n2, 
            p2, 
            n3, 
            p3, 
            n4, 
            p4
        ]),
    ];

    const getProjectData = () => {

        var data2 = [];

        for (var i = 0; i < projects.length; i++) {
            var project = projects[i];
            var statusDisp = project.get('status');
            var yearDisp = project.get('year');
            var projId = project.get('projectId');
            var nameDisp = project.get('name');
            var org, orgName, manager, personNameDisp, locationDisp, sectorDisp, classDisp;
            var thisOrg = project.get('organisationId');
            var thisManager = project.get('managerId');
            org = thisOrg;
            orgName = thisOrg.attributes.name;
            manager = thisManager;
            personNameDisp = manager.attributes.firstName + ' ' + manager.attributes.lastName;
            locationDisp = project.get('location');
            sectorDisp = project.get('sector');
            classDisp = project.get('class');

            // project.fetch().then((updateProject) => {
            //     org = updateProject.get('organisationId');
            //     orgName = updateProject.get('organisationId').attributes.name;
            //     manager = updateProject.get('managerId');
            //     personNameDisp = manager.attributes.firstName + ' ' + manager.attributes.lastName;

            // project.relation('users').query().first().then((res)=>{
            //         clientFirstNameDisp = res.get('firstName');
            //         clientLastNameDisp = res.get('lastName');
            //         clientEmailDisp = res.get('username');
    
                    
            //     }).catch((error) => {
            //         alert('Please send screenshot of this error message: ' + error.message);
            //     });

            // }, (error) => {
            //     alert('Please send screenshot of this error message: ' + error.message);
            // });

            data2.push({
                id: i,
                idDisplay: projId,
                nameDisplay: nameDisp,
                managerDisplay: personNameDisp,
                statusDisplay: statusDisp,
                yearDisplay: yearDisp,
                locationDisplay: locationDisp,
                sectorDisplay: sectorDisp,
                classDisplay: classDisp,
                // clientFirstNameDisplay: clientFirstNameDisp,
                // clientLastNameDisplay: clientLastNameDisp,
                // clientEmailDisplay: clientEmailDisp,
                clientOrgDisplay: orgName,
            })

        }

        return data2;
    }

    const [projectData, setProjectData] = React.useState(React.useMemo(() => getProjectData, [projectsLoaded]));
    if (!isProjTableLoaded) {
        if (projects.length > 0) {
            setProjectData(getProjectData);
            setIsProjTableLoaded(true);
        }
    }

    const csvData2 = [
        ["Project Number", "Project Name", "Project Manager", "Status", "Year", "Location", "Sector", "Class", "Client Organisation"],
        ...projectData.map(({ idDisplay, nameDisplay, managerDisplay, statusDisplay, yearDisplay, locationDisplay, sectorDisplay, classDisplay, clientOrgDisplay }) => [
            idDisplay,
            nameDisplay,
            managerDisplay,
            statusDisplay,
            yearDisplay,
            locationDisplay,
            sectorDisplay,
            classDisplay,
            clientOrgDisplay,
        ]),
    ];

    // const [acceptedFiles, setAcceptFiles] = useState([]);

    // const arrayFromString = (stringToMod) => {
    //     var toReturn = stringToMod.split(',');
    //     return toReturn;
    // }

    // const getStatus = (text, isExisting, currentStatus) => {
    //     var toReturn = '';
    //     if (isExisting) {
    //         if (currentStatus === 'Complete') {
    //             toReturn = 'Complete'
    //         } else {
    //             switch (text) {
    //                 case 'Lost':
    //                     toReturn = 'Loss'
    //                     break;
    //                 case 'Win':
    //                     toReturn = 'Active'
    //                     break;
    //                 case 'Undecided':
    //                     toReturn = 'Undecided'
    //                     break;
    //                 case 'On hold':
    //                     toReturn = 'FP on hold'
    //                     break;
    //                 case 'Quoting':
    //                     toReturn = 'Quoting'
    //                     break;
    //                 default:
    //                     toReturn = 'Complete'
    //                     break;
    //             }
    //         }
    //     } else {
    //         switch (text) {
    //             case 'Lost':
    //                 toReturn = 'Loss'
    //                 break;
    //             case 'Win':
    //                 toReturn = 'Complete'
    //                 break;
    //             case 'Undecided':
    //                 toReturn = 'Undecided'
    //                 break;
    //             case 'On hold':
    //                 toReturn = 'FP on hold'
    //                 break;
    //             case 'Quoting':
    //                 toReturn = 'Quoting'
    //                 break;
    //             default:
    //                 toReturn = 'Complete'
    //                 break;
    //         }
    //     }
    //     return toReturn;
    // }

    // const getManager = (managerName) => {
    //     var toReturn = staff[2];
    //     for (let k = 0; k < staff.length; k++) {
    //         const element = staff[k];
    //         const nameString = element.attributes.firstName + ' ' + element.attributes.lastName;
    //         if (managerName === nameString) {
    //             toReturn = element;
    //         }
    //     }
    //     return toReturn;
    // }

    // const fileAction = () => {
    //     setProjectsLoaded(false);
    //     var f = acceptedFiles[0];
    //     var reader = new FileReader();
    //     reader.onload = function(e) {
    //         const data = e.target.result;
    //         const workbook = read(data);
    //         const sheet = workbook.Sheets.Sheet1;
    //         const sheetObject = utils.sheet_to_json(sheet);
    //         const Project = Parse.Object.extend('Project');
    //         for (let i = 0; i < sheetObject.length; i++) {
    //             const row = sheetObject[i];
    //             var isExisting = false;
    //             for (let j = 0; j < projects.length; j++) {
    //                 if (row.Number === projects[j].attributes.projectId) {
    //                     isExisting = true;
    //                 }                    
    //             }

    //             if (isExisting) {
    //                 const queryExisting = new Parse.Query(Project);
    //                 queryExisting.equalTo('projectId', row.Number);
    //                 queryExisting.includeAll();
    //                 queryExisting.first()
    //                 .then((project) =>{
                        // project.set('year', row.Year);
                        // if (row.Stage) {
                        //     project.set('stage', row.Stage);
                        // }
                        // if (row.Sector) {
                        //     project.set('sector', arrayFromString(row.Sector));
                        // }

                        // if (row.Location) {
                        //     project.set('location', row.Location);
                        // }
                        // if (row.Comment) {
                        //     project.set('comment', row.Comment);
                        // }
                        // project.set('status', getStatus(row.Status, true, project.get('status')));

                //         if (row.Class) {
                //             project.set('class', arrayFromString(row.Class));
                //         }
                //         if (row.Service) {
                //             project.set('service', arrayFromString(row.Service));
                //         }
                //         if (row.Over25 === true) {
                //             project.set('over25', true);
                //         } else {
                //             project.set('over25', false);
                //         }
                //         if (row.PerformanceSolution) {
                //             project.set('reportIncludes', arrayFromString(row.PerformanceSolution));
                //         }

                //         project.save()
                //         .then((res2)=>{
                //             console.log(row.Number + ' data updated.')
                //         }).catch((error)=>{
                //             alert('Please send screenshot of this error message: ' + error.message);
                //         })
                //     }).catch((error)=>{
                //         alert('Please send screenshot of this error message: ' + error.message);
                //     })
                // }
                
                // else {
                //     const newProject = new Project();

                //     newProject.set('projectId', row.Number);
                //     newProject.set('managerId', getManager(row.Manager));
                //     newProject.set('name', row.Name);
                //     newProject.set('createdBy', currentUser);
                //     newProject.set('organisationId', dummyOrg);
                //     newProject.set('year', row.Year);
                //     if (row.Stage) {
                //         newProject.set('stage', row.Stage);
                //     }
                //     if (row.Class) {
                //         newProject.set('class', arrayFromString(row.Class));
                //     }
                //     if (row.Sector) {
                //         newProject.set('sector', arrayFromString(row.Sector));
                //     }
                //     if (row.Service) {
                //         newProject.set('service', arrayFromString(row.Service));
                //     }
                //     if (row.Location) {
                //         newProject.set('location', row.Location);
                //     }
                //     if (row.Comment) {
                //         newProject.set('comment', row.Comment);
                //     }
                //     newProject.set('status', getStatus(row.Status, false, ''));
                //     if (row.Over25 === true) {
                //         newProject.set('over25', true);
                //     } else {
                //         newProject.set('over25', false);
                //     }
                //     if (row.PerformanceSolution) {
                //         newProject.set('reportIncludes', arrayFromString(row.PerformanceSolution));
                //     }
                //     var projectACL = new Parse.ACL();
                //     projectACL.setRoleReadAccess('staff', true);
                //     projectACL.setRoleWriteAccess('staff', true);
                //     newProject.setACL(projectACL);

                //     newProject.save().then((projectRes) => {
                //         projectRes.relation('users').add(dummyUser);
                //         projectRes.save().then((newProjectRes)=>{
                //             console.log(row.Number + ' data updated.')
                //         }).catch((error)=> {
                //             alert('Please send screenshot of this error message: ' + error.message);
                //         })
                //     }).catch((error)=> {
                //         alert('Please send screenshot of this error message: ' + error.message);
                //     })
                // }
    //         }
    //     };
    //     reader.readAsArrayBuffer(f);        
    // }

    return <>
        {userIsSuperAdmin && (
            <Container>
                <div className="home-header">
                    <h2>Danger Zone</h2>
                </div>
                <hr/>
                <p>
                    Here scripts that change the database are run
                </p>
                <hr/>
                <Row className='mb-4'>
                    <Col>
                        <ScriptTemplate />
                    </Col>
                    {/* <Col>
                        <AddSecondToAllProjects />
                    </Col> */}
                    {/* <Col>
                        <AddSettingsToUserRow />
                    </Col> */}
                    {/* <Col>
                        <AddOrgToUserRow />
                    </Col> */}
                    {/* <Col>
                        <ProjectsDeadline />
                    </Col> */}
                </Row>   
                <Row>
                    <Col md={10}>
                        {/* <PdfUpload
                            file={setAcceptFiles}
                            list={acceptedFiles}
                        /> */}
                    </Col>
                    <Col md={2}>
                        {/* {projectsLoaded && (
                            <Button onClick={fileAction}>
                                Import excel file
                            </Button>
                        )} */}
                        {/* <CSVLink className='cont' filename="lote-contributions.csv" data={csvData}>
                            <i className="fas fa-file-excel"></i>
                        </CSVLink>   */}
                        {isProjTableLoaded && (
                            <CSVLink className='cont' filename="lote-project-clients.csv" data={csvData2}>
                                <i className="fas fa-file-excel"></i>
                            </CSVLink>
                        )}
                    </Col>  
                </Row>
            </Container>
        )}
    </>
}
