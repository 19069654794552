import { useState, useRef } from 'react';
import { Button, Modal, Form, Row, Col, Spinner, Stack, Overlay } from 'react-bootstrap';
import NumericFormat from 'react-number-format';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { yearOptions, locationOptions, stageOptions, classOptions, sectorOptions, 
    servicesOptions, reportIncludesOptions, insuranceOptions, customStyles } from '../../data/Data.js'

export default function ProjectMetadata(props) {

    const target = useRef(null);
    const [showOverlay, setShowOverlay] = useState(false);

    const [show, setShow] = useState(false);
    const [submitString, setSubmitString] = useState('Save Project');

    const [active, setActive] = useState(false);
    const [complete, setComplete] = useState(false);

    const [year, setYear] = useState('');
    const [location, setLocation] = useState('');
    const [stage, setStage] = useState('');
    const [buildingClass, setBuildingClass] = useState([]);
    const [sector, setSector] = useState([]);
    const [services, setServices] = useState([]);

    const [cladding, setCladding] = useState(false);
    const [dcd, setDcd] = useState(false);
    const [cfd, setCfd] = useState(false);
    const [over25, setOver25] = useState(false);
    const [contract, setContract] = useState(false);
    const [insurance, setInsurance] = useState('');
    const [fee, setFee] = useState(0);
    const [commencedOn, setCommencedOn] = useState('');

    const [completedOn, setCompletedOn] = useState('');
    const [reportIncludes, setReportIncludes] = useState([]);

    const [photos, setPhotos] = useState();

    const numDisp = props.row.values.projectNumberDisplay;
    const clientDisp = props.row.values.orgDisplay.attributes.name;
    const yearDisp = props.row.values.yearDisplay;
    const locationDisp = props.row.values.locationDisplay;
    const stageDisp = props.row.values.stageDisplay;
    const servicesDisp = props.row.values.servicesDisplay

    const defaultSingleValue = (options, propsValue) => {
        return options.filter(option => option.value === propsValue);
    }

    const defaultMultiValue = (options, propsValue) => {
        return options.filter(option => 
            propsValue.map(propOption => {return propOption}).includes(option.value));
    }

    const handleClose = () =>  {
        setSubmitString('Save Project');
        setActive(false);
        setComplete(false);
        setYear('');
        setLocation('');
        setStage('');
        setBuildingClass([]);
        setSector([]);
        setServices([]);
        setCladding(false);
        setDcd(false);
        setCfd(false);
        setOver25(false);
        setContract(false);
        setInsurance('');
        setFee(0);
        setCommencedOn('');
        setCompletedOn('');
        setReportIncludes([]);
        setPhotos('');
        setShow(false);
    }

    const handleShow = () => {
        if (props.row.values.statusDisplay === 'Active' || props.row.values.statusDisplay === 'On hold'
            || props.row.values.statusDisplay === 'Internal Review' || props.row.values.statusDisplay === 'C10 Review'
            || props.row.values.statusDisplay === 'SD Review' || props.row.values.statusDisplay === 'Site Inspection') {
            setActive(true);
        }
        if (props.row.values.statusDisplay === 'Complete') {
            setComplete(true);
            setActive(true);
        }
        setYear(defaultSingleValue(yearOptions, props.row.values.yearDisplay));
        setLocation(defaultSingleValue(locationOptions, props.row.values.locationDisplay));
        setStage(defaultSingleValue(stageOptions, props.row.values.stageDisplay));
        setBuildingClass(defaultMultiValue(classOptions, props.row.values.classDisplay.split(',')))
        setSector(defaultMultiValue(sectorOptions, props.row.values.sectorDisplay.split(',')))
        setServices(defaultMultiValue(servicesOptions, props.row.values.servicesDisplay.split(',')))
        if (props.row.values.claddingDisplay === 'true') {
            setCladding(true);
        }
        if (props.row.values.dcdDisplay === 'true') {
            setDcd(true);
        }
        if (props.row.values.cfdDisplay === 'true') {
            setCfd(true);
        }
        if (props.row.values.over25Display === 'true') {
            setOver25(true);
        }
        if (props.row.values.contractDisplay === 'true') {
            setContract(true);
        }
        setInsurance(defaultSingleValue(insuranceOptions, props.row.values.insuranceDisplay));
        setFee(props.row.values.feeDisplay);
        setCommencedOn(props.row.values.commencedOnDisplay);
        setCompletedOn(props.row.values.completedOnDisplay);
        setReportIncludes(defaultMultiValue(reportIncludesOptions, props.row.values.reportIncludesDisplay.split(',')));
        setPhotos(props.row.values.photosDisplay);
        setShow(true);
    }

    const simplifyMultiArray = (arrayToMod) => {
        var toReturn = [];
        for (let i = 0; i < arrayToMod.length; i++) {
            toReturn[i] = arrayToMod[i].value
        }
        return toReturn;
    }


    const handleSubmit = () => {
        setSubmitString(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const Parse = require('parse');
        const query = new Parse.Query(Parse.Object.extend('Project'));
        query.get(props.row.values.idDisplay)
        .then((project) =>{
            if (year.value) {
                project.set('year', parseInt(year.value));
            }
            if (location.value) {
                project.set('location', location.value);
            }
            if (stage.value) {
                project.set('stage', stage.value);
            }
            if (buildingClass[0]) {
                project.set('class', simplifyMultiArray(buildingClass));
            }
            if (sector[0]) {
                project.set('sector', simplifyMultiArray(sector));
            }
            if (services[0]) {
                project.set('service', simplifyMultiArray(services));
            }

            if (active) {
                if (fee) {
                    project.set('fee', parseInt(fee.slice(1)));
                }
                project.set('cladding', cladding);
                project.set('dcd', dcd);
                project.set('cfd', cfd);
                project.set('over25', over25);
                project.set('nonStandardContract', contract);
                if (insurance.value) {
                    project.set('piInsurance', insurance.value);
                }
                if (commencedOn !== '') {
                    project.set('commencedOn', commencedOn);
                }
            }

            if (complete) {
                if (reportIncludes[0]) {
                    project.set('reportIncludes', simplifyMultiArray(reportIncludes));
                }
                if (completedOn !== '') {
                    project.set('completedOn', completedOn);
                }
            }

            project.set('photoLink', photos);

            project.save()
            .then((res2)=>{
                handleClose();
            }).catch((error)=>{
                alert('Please send screenshot of this error message: ' + error.message);
                setSubmitString('Save Project');
            })
        }).catch((error)=>{
            alert('Please send screenshot of this error message: ' + error.message);
            setSubmitString('Save Project');
        })
    }

    const MetadataIndicator = () => {
        var score = 0;

        if (!active && !complete) {
            if (props.row.values.orgDisplay.id !== 'FjB0exq7ZX') {
                score++;
            }
            if ((props.row.values.locationDisplay.length > 1) && (props.row.values.stageDisplay.length > 1) &&
                (props.row.values.classDisplay.length > 1) && (props.row.values.sectorDisplay.length > 1) && 
                (props.row.values.servicesDisplay.length > 1) && (props.row.values.photosDisplay.length > 1)) {
                score++;
                score++;
                score++;
            }
        } else if (active && !complete) {
            if (props.row.values.orgDisplay.id !== 'FjB0exq7ZX') {
                score++;
            }
            if ((props.row.values.locationDisplay.length > 1) && (props.row.values.stageDisplay.length > 1) &&
                (props.row.values.classDisplay.length > 1) && (props.row.values.sectorDisplay.length > 1) && 
                (props.row.values.servicesDisplay.length > 1) && (props.row.values.photosDisplay.length > 1)) {
                score++;
                score++;
            }
            if ((props.row.values.insuranceDisplay.length > 1) && (props.row.values.feeDisplay.length > 0) && 
                (props.row.values.commencedOnDisplay !== '')) {
                score++;
            }
        } else if (complete) {
            if (props.row.values.orgDisplay.id !== 'FjB0exq7ZX') {
                score++;
            }
            if ((props.row.values.locationDisplay.length > 1) && (props.row.values.stageDisplay.length > 1) &&
                (props.row.values.classDisplay.length > 1) && (props.row.values.sectorDisplay.length > 1) && 
                (props.row.values.servicesDisplay.length > 1) && (props.row.values.photosDisplay.length > 1)) {
                score++;
            }
            if ((props.row.values.insuranceDisplay.length > 1) && (props.row.values.feeDisplay.length > 0) && 
                (props.row.values.commencedOnDisplay !== '')) {
                score++;
            }
            if ((props.row.values.reportIncludesDisplay.length > 1) && (props.row.values.completedOnDisplay !== '')) {
                score++;
            }
        }

        switch (score) {
            case 0:
                return <>
                    <span className='hidden-button-3'>
                        <i style={{color:'var(--danger)'}} className="fas fa-circle"></i>
                    </span>
                </>
            case 4:
                return <>
                    <span className='hidden-button-3'>
                        <i style={{color:'var(--success)'}} className="fas fa-circle"></i>
                    </span>
                </>
            default:
                return <>
                <span className='hidden-button-3'>
                    <i style={{color:'var(--orange)'}} className="fas fa-circle"></i>
                </span>
            </>   
        }
    }
    
    return <>
        <Stack direction="horizontal" gap={2} >
            <div>
                <Button variant='outline-dark' className='cerulean-button square-button' onClick={handleShow}
                    ref={target} onMouseEnter={() => setShowOverlay(true)} onMouseLeave={() => setShowOverlay(false)}>
                    <i className="fas fa-database"></i>
                </Button>
                <Overlay target={target.current} show={showOverlay} placement="left">
                    {({
                        placement: _placement,
                        arrowProps: _arrowProps,
                        show: _show,
                        popper: _popper,
                        hasDoneInitialMeasure: _hasDoneInitialMeasure,
                        ...props
                    }) => (
                        <div
                            {...props}
                            style={{
                                position: 'absolute',
                                backgroundColor: 'var(--box-background)',
                                padding: '2px 10px',
                                color: 'var(--text)',
                                borderRadius: 7,
                                ...props.style,
                            }}
                        >
                            <h6>{numDisp} — Metadata</h6>
                                <ul>
                                    <li>Client: {clientDisp}</li>
                                    <li>Year: {yearDisp}</li>
                                    <li>Location: {locationDisp}</li>
                                    <li>Stage: {stageDisp}</li>
                                    <li>Services: {servicesDisp}</li>
                                </ul>
                        </div>
                    )}
        </Overlay>
            </div>
            <div>
                <MetadataIndicator />
            </div>
        </Stack>
        
        <Modal show={show} onHide={handleClose} onExited={props.handleExit} centered size='lg' backdrop="static">
            <Modal.Header className='modal-header' closeButton>
                <Modal.Title>Edit Metadata: {props.row.values.projectNumberDisplay} — {props.row.values.projectNameDisplay}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <Form onSubmit={handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="4">
                            <Form.Label>Year</Form.Label>
                            <Select
                                styles={customStyles}
                                options={yearOptions}
                                value={year}
                                defaultValue={year}
                                onChange={e=>setYear(e)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4">
                            <Form.Label>Location</Form.Label>
                            <Select
                                styles={customStyles}
                                options={locationOptions}
                                value={location}
                                defaultValue={location}
                                onChange={e=>setLocation(e)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4">
                            <Form.Label>Stage</Form.Label>
                            <Select
                                styles={customStyles}
                                options={stageOptions}
                                value={stage}
                                defaultValue={stage}
                                onChange={e=>setStage(e)}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-4">
                        <Form.Group as={Col} md="4">
                            <Form.Label>Class</Form.Label>
                            <Select
                                styles={customStyles}
                                options={classOptions}
                                value={buildingClass}
                                defaultValue={buildingClass}
                                onChange={e=>setBuildingClass(e)}
                                isMulti
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4">
                            <Form.Label>Sector</Form.Label>
                            <Select
                                styles={customStyles}
                                options={sectorOptions}
                                value={sector}
                                defaultValue={sector}
                                onChange={e=>setSector(e)}
                                isMulti
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4">
                            <Form.Label>Services</Form.Label>
                            <Select
                                styles={customStyles}
                                options={servicesOptions}
                                value={services}
                                defaultValue={services}
                                onChange={e=>setServices(e)}
                                isMulti
                            />
                        </Form.Group>
                    </Row>
                    {active && (
                        <span>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="4">
                                    <Form.Label>Fee (AUD)</Form.Label>
                                    <NumericFormat
                                        className="form-control"
                                        displayType="input"
                                        prefix={'$'}
                                        allowNegative={false}
                                        value = {fee}
                                        onChange={(e) => setFee(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label>PI Insurance</Form.Label>
                                    <Select
                                        styles={customStyles}
                                        options={insuranceOptions}
                                        value={insurance}
                                        defaultValue={insurance}
                                        onChange={e=>setInsurance(e)}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label>Commenced On</Form.Label>
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        className='form-control'
                                        selected={commencedOn}
                                        onChange={(date)=> setCommencedOn(date)}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-4">
                                <Form.Group as={Col} md="2">
                                    <Form.Label>Cladding</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        label=""
                                        checked={cladding}
                                        onChange={(e) => setCladding(e.target.checked)}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="2">
                                    <Form.Label>DCD</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        label=""
                                        checked={dcd}
                                        onChange={(e) => setDcd(e.target.checked)}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="2">
                                    <Form.Label>CFD</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        label=""
                                        checked={cfd}
                                        onChange={(e) => setCfd(e.target.checked)}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="2">
                                    <Form.Label>Over 25m</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        label=""
                                        checked={over25}
                                        onChange={(e) => setOver25(e.target.checked)}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label>Non-Standard Contract</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        label=""
                                        checked={contract}
                                        onChange={(e) => setContract(e.target.checked)}
                                    />
                                </Form.Group>
                            </Row>
                        </span>
                    )}
                    {complete && (
                        <span>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="8">
                                    <Form.Label>Performance Solutions</Form.Label>
                                    <Select
                                        styles={customStyles}
                                        options={reportIncludesOptions}
                                        value={reportIncludes}
                                        defaultValue={reportIncludes}
                                        onChange={e=>setReportIncludes(e)}
                                        isMulti
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label>Completed On</Form.Label>
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        className='form-control'
                                        selected={completedOn}
                                        onChange={(date)=> setCompletedOn(date)}
                                    />
                                </Form.Group>
                            </Row>
                        </span>
                    )}
                    <Row>
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Photos Link</Form.Label>
                            <Form.Control
                                type="text"
                                value={photos}
                                onChange={(e) => setPhotos(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                >
                    {submitString}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
