import React, { useState, useEffect} from 'react';
import { Table, Button, Container } from 'react-bootstrap';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination, useAsyncDebounce } from 'react-table'
import ListBuilder from './ListBuilder';

export default function Checklist() {

    const Parse = require('parse');

    const [lists, setLists] = useState([]);
    const [isListsLoaded, setIsListsLoaded] = useState(false);
    const [isListsTableLoaded, setIsListsTableLoaded] = useState(false);

    useEffect(() => {
        document.title = "Checklists";
        if (!isListsLoaded) {
            queryLists();
        }
    },[]);

    const queryLists = () => {
        const Checklist = Parse.Object.extend('Checklist');
        const query = new Parse.Query(Checklist);
        query.limit(999999);
        query.descending('updatedAt');
        query.find()
        .then(function(res) {
            setLists(res);
            setIsListsLoaded(true);
        }, (error) => {
            alert(error);
        });
    }

    const getListData = () => {

        var data2 = [];

        for (var i = 0; i < lists.length; i++) {
            var list = lists[i];
            var thisList = list.get('list').toString();
            var l = list.get('list').length.toString();
            data2.push({
                id: i,
                idDisplay: list.id,
                listDisplay: thisList,
                lengthDisplay: l,
                nameDisplay: list.get('name'),
            })
        }

        return data2;
    }

    const [listData, setListData] = React.useState(React.useMemo(() => getListData, [isListsLoaded]));
    if (!isListsTableLoaded) {
        if (lists.length > 0) {
            setListData(getListData);
            setIsListsTableLoaded(true);
        }
    }

    const listCols = React.useMemo(
        () => [
            {
                Header: 'List Id',
                accessor: 'idDisplay',
                show: false,
            },
            {
                Header: 'List Items',
                accessor: 'listDisplay',
                show: false,
            },
            {
                Header: 'List Length',
                accessor: 'lengthDisplay',
                show: false,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Name</span>,
                accessor: 'nameDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1.1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header: "",
                id: "update",
                accessor: "updateDisplay",
                Cell: (tableProps) => <>
                    <ListBuilder
                        listData={[...listData]}
                        setListData={setListData}
                        editMode={true}
                        row={tableProps.row}
                    />
                </>
            },
        ],
        [listData]
    );

    const ListTable = () => {
        if(isListsLoaded) {
            return <>
                <TableView columns={listCols} data={listData}/>
            </>    
        } else if (!isListsLoaded) {
            return <Table><tbody><tr><td colSpan={1} style={{textAlign:'center', color:'var(--text)'}}><em>Loading checklists...</em></td></tr></tbody></Table>
        } else {
            return <Table><tbody><tr><td colSpan={1} style={{textAlign:'center', color:'var(--text)'}}>No checklists found</td></tr></tbody></Table>
        }
    }

    function GlobalFilter({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter,
    }) {
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 200)
      
        return (
            <span>
                <input
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={'Search'}
                    style={{
                        fontSize: '1.1rem',
                        border: '0',
                        width: '100%',
                    }}
                />
            </span>
        )
    }

    function TableView ({ columns, data }) {

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            state: { pageIndex, pageSize },
            state,
            // setFilter,
            preGlobalFilteredRows,
            setGlobalFilter,
        } = useTable(
            {
                columns,
                data,
                initialState: {
                    pageIndex: 0,
                    pageSize: 50,
                    hiddenColumns: columns.map(column => {
                        if (column.show === false) {
                            return column.accessor || column.id;
                        }
                    }),
                },            
            },
            useFilters,
            useGlobalFilter,
            useSortBy,
            usePagination
        )

        // const [pageFilter, setPageFilter] = useState({
        //     "value" : "20",
        //     "label" : "Show 20",
        // });

        // const handlePageFilter = (e) => {
        //     setPageFilter(e);
        //     setPageSize(Number(e.value));
        // }

        return (
            <Container>

                <div>&nbsp;</div>
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
                <div>&nbsp;</div>

                <Table {...getTableProps()}>
                    
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                    {column.isSorted
                                    ? column.isSortedDesc
                                        ? <i className="fas fa-sort-down"></i>
                                        : <i className="fas fa-sort-up"></i>
                                    : ''}
                                </span>
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                            )
                        })}
                    </tbody>
                </Table>
                <div className="pagination">
                    <span>
                        <Button variant="outline-secondary" className='square-button' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            <i className="fas fa-angle-double-left"></i>
                        </Button>
                        &nbsp;
                        <Button variant="outline-secondary" className='square-button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                            <i className="fas fa-angle-left"></i>
                        </Button>
                        &nbsp;
                        <Button variant="outline-secondary" className='square-button' onClick={() => nextPage()} disabled={!canNextPage}>
                            <i className="fas fa-angle-right"></i>
                        </Button>
                        &nbsp;
                        <Button variant="outline-secondary" className='square-button' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            <i className="fas fa-angle-double-right"></i>
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                            Page&nbsp;
                                <strong>{pageIndex + 1}</strong> of {pageOptions.length}
                        </span>
                    </span>
                    {/* <Select 
                        styles={customStyles}
                        className="right-select"
                        options={paginationOptions}
                        value={pageFilter}
                        onChange={e => {handlePageFilter(e)}}
                    /> */}
                </div>
            </Container>
        )
    }


    return <>
    <Container>
            <div className="home-header mb-2">
                <h2>Checklists</h2>
                <ListBuilder
                    listData={[...listData]}
                    setListData={setListData}
                    editMode={false}
                />
            </div>
            {/* <hr/> */}
            <div className="table-homepage">
                <div>
                    <ListTable />
                </div>
            </div>
        </Container>
    </>
}
