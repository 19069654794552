import { useState, useEffect } from 'react';
import { Button, Form, Modal, Spinner, Row, Stack, Col, Tooltip, OverlayTrigger, Card } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import RangeSlider from 'react-bootstrap-range-slider';
import moment from 'moment';
import 'moment/locale/en-au';
import { customStyles, officeOptions, leaveOptions } from '../../data/Data.js'
import axios from 'axios';

export default function EditTimesheet(props) {

    const Parse = require('parse');

    const [show, setShow] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [submitString, setSubmitString] = useState('Save Timesheet');

    const [localProjectOptions, setLocalProjectOptions] = useState([]);
    
    const [start, setStart] = useState();
    const [location, setLocation] = useState();
    const [breakTime, setBreakTime] = useState(0);
    const [hours, setHours] = useState(0);
    const [end, setEnd] = useState();

    const [zeroTimesheet, setZeroTimesheet] = useState(false);
    const [submitStringDelete, setSubmitStringDelete] = useState('Delete Timesheet');

    const [rangeTotal, setRangeTotal] = useState(0);
    const [rangeError, setRangeError] = useState(false);
    const [projCount, setProjCount] = useState(1);
    const [projMin, setProjMin] = useState(true);
    const [projMax, setProjMax] = useState(false);

    const [show1, setShow1] = useState(true);
    const [manual1, setManual1] = useState(false);
    const [project1, setProject1] = useState();
    const [range1, setRange1] = useState(0);
    const [projectNumber1, setProjectNumber1] = useState('');

    const [show2, setShow2] = useState(false);
    const [manual2, setManual2] = useState(false);
    const [project2, setProject2] = useState();
    const [range2, setRange2] = useState(0);
    const [projectNumber2, setProjectNumber2] = useState('');

    const [show3, setShow3] = useState(false);
    const [manual3, setManual3] = useState(false);
    const [project3, setProject3] = useState();
    const [range3, setRange3] = useState(0);
    const [projectNumber3, setProjectNumber3] = useState('');

    const [show4, setShow4] = useState(false);
    const [manual4, setManual4] = useState(false);
    const [project4, setProject4] = useState();
    const [range4, setRange4] = useState(0);
    const [projectNumber4, setProjectNumber4] = useState('');

    const [show5, setShow5] = useState(false);
    const [manual5, setManual5] = useState(false);
    const [project5, setProject5] = useState();
    const [range5, setRange5] = useState(0);
    const [projectNumber5, setProjectNumber5] = useState('');

    const [show6, setShow6] = useState(false);
    const [manual6, setManual6] = useState(false);
    const [project6, setProject6] = useState();
    const [range6, setRange6] = useState(0);
    const [projectNumber6, setProjectNumber6] = useState('');

    const [show7, setShow7] = useState(false);
    const [manual7, setManual7] = useState(false);
    const [project7, setProject7] = useState();
    const [range7, setRange7] = useState(0);
    const [projectNumber7, setProjectNumber7] = useState('');

    const [show8, setShow8] = useState(false);
    const [manual8, setManual8] = useState(false);
    const [project8, setProject8] = useState();
    const [range8, setRange8] = useState(0);
    const [projectNumber8, setProjectNumber8] = useState('');

    const [show9, setShow9] = useState(false);
    const [manual9, setManual9] = useState(false);
    const [project9, setProject9] = useState();
    const [range9, setRange9] = useState(0);
    const [projectNumber9, setProjectNumber9] = useState('');

    const [show10, setShow10] = useState(false);
    const [manual10, setManual10] = useState(false);
    const [project10, setProject10] = useState();
    const [range10, setRange10] = useState(0);
    const [projectNumber10, setProjectNumber10] = useState('');

    const [show11, setShow11] = useState(false);
    const [manual11, setManual11] = useState(false);
    const [project11, setProject11] = useState();
    const [range11, setRange11] = useState(0);
    const [projectNumber11, setProjectNumber11] = useState('');

    const [show12, setShow12] = useState(false);
    const [manual12, setManual12] = useState(false);
    const [project12, setProject12] = useState();
    const [range12, setRange12] = useState(0);
    const [projectNumber12, setProjectNumber12] = useState('');

    const [projectNumbers, setProjectNumbers] = useState([]);

    const [errorText, setErrorText] = useState('');

    const defaultSingleValue = (options, propsValue) => {
        return options.filter(option => option.value === propsValue)[0];
    }

    const defaultSingleNumber = (options, propsValue) => {
        return options.filter(option => option.label.slice(0,6) === propsValue)[0];
    }

    useEffect(()=> {
        var newProjectOptions = props.projectOptions;
        if (newProjectOptions.length > 0) {
            if (newProjectOptions[0].value === 'null') {
                newProjectOptions.shift();
            }
            var arr = [];
            for (let index = 0; index < newProjectOptions.length; index++) {
                const element = newProjectOptions[index];
                arr.push(element.label.slice(0, 6));
            }
            setProjectNumbers(arr);
            setLocalProjectOptions(newProjectOptions);
        }
    }, [props.projectOptions]); 

    const handleShow = () => {
        setShow(true);
        if (!isDataLoaded) {
            setStart(props.row.values.startValueDisplay);
            setLocation(defaultSingleValue(officeOptions, props.row.values.locationDisplay));
            setBreakTime(props.row.values.breakDisplay);
            setEnd(props.row.values.endValueDisplay);
            calcHours(props.row.values.startValueDisplay, props.row.values.endValueDisplay, props.row.values.breakDisplay, 'init');

            const Timesheet = Parse.Object.extend('Timesheet');
            const query = new Parse.Query(Timesheet);
            query.get(props.row.values.idDisplay)
            .then(function(timesheet) {
                var projArr = timesheet.get('projects');
                var dataArr = timesheet.get('projectHours');

                switch (projArr.length) {
                    case 1:
                        setShow1(true);
                        if (projectNumbers.includes(projArr[0])) {
                            setProject1(defaultSingleNumber(localProjectOptions, projArr[0]));
                        } else {
                            setManual1(true);
                            setProjectNumber1(projArr[0]);
                        }
                        setRange1(dataArr[0].split(':')[1]);

                        setProjCount(1);
                        setProjMin(true);
                        break;
                    case 2:
                        setShow1(true);
                        if (projectNumbers.includes(projArr[0])) {
                           setProject1(defaultSingleNumber(localProjectOptions, projArr[0]));
                        } else {
                            setManual1(true);
                            setProjectNumber1(projArr[0]);
                        }
                        setRange1(dataArr[0].split(':')[1]);

                        setShow2(true);
                        if (projectNumbers.includes(projArr[1])) {
                            setProject2(defaultSingleNumber(localProjectOptions, projArr[1]));
                        } else {
                            setManual2(true);
                            setProjectNumber2(projArr[1]);
                        }
                        setRange2(dataArr[1].split(':')[1]);

                        setProjCount(2);
                        break;
                    case 3:
                        setShow1(true);
                        if (projectNumbers.includes(projArr[0])) {
                           setProject1(defaultSingleNumber(localProjectOptions, projArr[0]));
                        } else {
                            setManual1(true);
                            setProjectNumber1(projArr[0]);
                        }
                        setRange1(dataArr[0].split(':')[1]);

                        setShow2(true);
                        if (projectNumbers.includes(projArr[1])) {
                            setProject2(defaultSingleNumber(localProjectOptions, projArr[1]));
                         } else {
                             setManual2(true);
                             setProjectNumber2(projArr[1]);
                         }
                        setRange2(dataArr[1].split(':')[1]);

                        setShow3(true);
                        if (projectNumbers.includes(projArr[2])) {
                            setProject3(defaultSingleNumber(localProjectOptions, projArr[2]));
                        } else {
                            setManual3(true);
                            setProjectNumber3(projArr[2]);
                        }
                        setRange3(dataArr[2].split(':')[1]);

                        setProjCount(3);
                        break;
                    case 4:
                        setShow1(true);
                        if (projectNumbers.includes(projArr[0])) {
                           setProject1(defaultSingleNumber(localProjectOptions, projArr[0]));
                        } else {
                            setManual1(true);
                            setProjectNumber1(projArr[0]);
                        }
                        setRange1(dataArr[0].split(':')[1]);

                        setShow2(true);
                        if (projectNumbers.includes(projArr[1])) {
                            setProject2(defaultSingleNumber(localProjectOptions, projArr[1]));
                        } else {
                            setManual2(true);
                            setProjectNumber2(projArr[1]);
                        }
                        setRange2(dataArr[1].split(':')[1]);

                        setShow3(true);
                        if (projectNumbers.includes(projArr[2])) {
                            setProject3(defaultSingleNumber(localProjectOptions, projArr[2]));
                        } else {
                            setManual3(true);
                            setProjectNumber3(projArr[2]);
                        }
                        setRange3(dataArr[2].split(':')[1]);

                        setShow4(true);
                        if (projectNumbers.includes(projArr[3])) {
                            setProject4(defaultSingleNumber(localProjectOptions, projArr[3]));
                        } else {
                            setManual4(true);
                            setProjectNumber4(projArr[3]);
                        }
                        setRange4(dataArr[3].split(':')[1]);

                        setProjCount(4);
                        break;
                    case 5:
                        setShow1(true);
                        if (projectNumbers.includes(projArr[0])) {
                           setProject1(defaultSingleNumber(localProjectOptions, projArr[0]));
                        } else {
                            setManual1(true);
                            setProjectNumber1(projArr[0]);
                        }
                        setRange1(dataArr[0].split(':')[1]);

                        setShow2(true);
                        if (projectNumbers.includes(projArr[1])) {
                            setProject2(defaultSingleNumber(localProjectOptions, projArr[1]));
                        } else {
                            setManual2(true);
                            setProjectNumber2(projArr[1]);
                        }
                        setRange2(dataArr[1].split(':')[1]);

                        setShow3(true);
                        if (projectNumbers.includes(projArr[2])) {
                            setProject3(defaultSingleNumber(localProjectOptions, projArr[2]));
                         } else {
                             setManual3(true);
                             setProjectNumber3(projArr[2]);
                         }
                        setRange3(dataArr[2].split(':')[1]);

                        setShow4(true);
                        if (projectNumbers.includes(projArr[3])) {
                            setProject4(defaultSingleNumber(localProjectOptions, projArr[3]));
                         } else {
                             setManual4(true);
                             setProjectNumber4(projArr[3]);
                         }
                        setRange4(dataArr[3].split(':')[1]);

                        setShow5(true);
                        if (projectNumbers.includes(projArr[4])) {
                            setProject5(defaultSingleNumber(localProjectOptions, projArr[4]));
                         } else {
                             setManual5(true);
                             setProjectNumber5(projArr[4]);
                         }
                        setRange5(dataArr[4].split(':')[1]);

                        setProjCount(5);
                        break;
                    case 6:
                        setShow1(true);
                        if (projectNumbers.includes(projArr[0])) {
                           setProject1(defaultSingleNumber(localProjectOptions, projArr[0]));
                        } else {
                            setManual1(true);
                            setProjectNumber1(projArr[0]);
                        }
                        setRange1(dataArr[0].split(':')[1]);

                        setShow2(true);
                        if (projectNumbers.includes(projArr[1])) {
                            setProject2(defaultSingleNumber(localProjectOptions, projArr[1]));
                         } else {
                             setManual2(true);
                             setProjectNumber2(projArr[1]);
                         }
                        setRange2(dataArr[1].split(':')[1]);

                        setShow3(true);
                        if (projectNumbers.includes(projArr[2])) {
                            setProject3(defaultSingleNumber(localProjectOptions, projArr[2]));
                         } else {
                             setManual3(true);
                             setProjectNumber3(projArr[2]);
                         }
                        setRange3(dataArr[2].split(':')[1]);

                        setShow4(true);
                        if (projectNumbers.includes(projArr[3])) {
                            setProject4(defaultSingleNumber(localProjectOptions, projArr[3]));
                         } else {
                             setManual4(true);
                             setProjectNumber4(projArr[3]);
                         }
                        setRange4(dataArr[3].split(':')[1]);

                        setShow5(true);
                        if (projectNumbers.includes(projArr[4])) {
                            setProject5(defaultSingleNumber(localProjectOptions, projArr[4]));
                         } else {
                             setManual5(true);
                             setProjectNumber5(projArr[4]);
                         }
                        setRange5(dataArr[4].split(':')[1]);

                        setShow6(true);
                        if (projectNumbers.includes(projArr[5])) {
                            setProject6(defaultSingleNumber(localProjectOptions, projArr[5]));
                         } else {
                             setManual6(true);
                             setProjectNumber6(projArr[5]);
                         }
                        setRange6(dataArr[5].split(':')[1]);

                        setProjCount(6);
                        break;
                    case 7:
                        setShow1(true);
                        if (projectNumbers.includes(projArr[0])) {
                           setProject1(defaultSingleNumber(localProjectOptions, projArr[0]));
                        } else {
                            setManual1(true);
                            setProjectNumber1(projArr[0]);
                        }
                        setRange1(dataArr[0].split(':')[1]);

                        setShow2(true);
                        if (projectNumbers.includes(projArr[1])) {
                            setProject2(defaultSingleNumber(localProjectOptions, projArr[1]));
                         } else {
                             setManual2(true);
                             setProjectNumber2(projArr[1]);
                         }
                        setRange2(dataArr[1].split(':')[1]);

                        setShow3(true);
                        if (projectNumbers.includes(projArr[2])) {
                            setProject3(defaultSingleNumber(localProjectOptions, projArr[2]));
                         } else {
                             setManual3(true);
                             setProjectNumber3(projArr[2]);
                         }
                        setRange3(dataArr[2].split(':')[1]);

                        setShow4(true);
                        if (projectNumbers.includes(projArr[3])) {
                            setProject4(defaultSingleNumber(localProjectOptions, projArr[3]));
                         } else {
                             setManual4(true);
                             setProjectNumber4(projArr[3]);
                         }
                        setRange4(dataArr[3].split(':')[1]);

                        setShow5(true);
                        if (projectNumbers.includes(projArr[4])) {
                            setProject5(defaultSingleNumber(localProjectOptions, projArr[4]));
                         } else {
                             setManual5(true);
                             setProjectNumber5(projArr[4]);
                         }
                        setRange5(dataArr[4].split(':')[1]);

                        setShow6(true);
                        if (projectNumbers.includes(projArr[5])) {
                            setProject6(defaultSingleNumber(localProjectOptions, projArr[5]));
                         } else {
                             setManual6(true);
                             setProjectNumber6(projArr[5]);
                         }
                        setRange6(dataArr[5].split(':')[1]);

                        setShow7(true);
                        if (projectNumbers.includes(projArr[6])) {
                            setProject7(defaultSingleNumber(localProjectOptions, projArr[6]));
                         } else {
                             setManual7(true);
                             setProjectNumber7(projArr[6]);
                         }
                        setRange7(dataArr[6].split(':')[1]);

                        setProjCount(7);
                        break;
                    case 8:
                        setShow1(true);
                        if (projectNumbers.includes(projArr[0])) {
                           setProject1(defaultSingleNumber(localProjectOptions, projArr[0]));
                        } else {
                            setManual1(true);
                            setProjectNumber1(projArr[0]);
                        }
                        setRange1(dataArr[0].split(':')[1]);

                        setShow2(true);
                        if (projectNumbers.includes(projArr[1])) {
                            setProject2(defaultSingleNumber(localProjectOptions, projArr[1]));
                         } else {
                             setManual2(true);
                             setProjectNumber2(projArr[1]);
                         }
                        setRange2(dataArr[1].split(':')[1]);

                        setShow3(true);
                        if (projectNumbers.includes(projArr[2])) {
                            setProject3(defaultSingleNumber(localProjectOptions, projArr[2]));
                         } else {
                             setManual3(true);
                             setProjectNumber3(projArr[2]);
                         }
                        setRange3(dataArr[2].split(':')[1]);

                        setShow4(true);
                        if (projectNumbers.includes(projArr[3])) {
                            setProject4(defaultSingleNumber(localProjectOptions, projArr[3]));
                         } else {
                             setManual4(true);
                             setProjectNumber4(projArr[3]);
                         }
                        setRange4(dataArr[3].split(':')[1]);

                        setShow5(true);
                        if (projectNumbers.includes(projArr[4])) {
                            setProject5(defaultSingleNumber(localProjectOptions, projArr[4]));
                         } else {
                             setManual5(true);
                             setProjectNumber5(projArr[4]);
                         }
                        setRange5(dataArr[4].split(':')[1]);

                        setShow6(true);
                        if (projectNumbers.includes(projArr[5])) {
                            setProject6(defaultSingleNumber(localProjectOptions, projArr[5]));
                         } else {
                             setManual6(true);
                             setProjectNumber6(projArr[5]);
                         }
                        setRange6(dataArr[5].split(':')[1]);

                        setShow7(true);
                        if (projectNumbers.includes(projArr[6])) {
                            setProject7(defaultSingleNumber(localProjectOptions, projArr[6]));
                         } else {
                             setManual7(true);
                             setProjectNumber7(projArr[6]);
                         }
                        setRange7(dataArr[6].split(':')[1]);

                        setShow8(true);
                        if (projectNumbers.includes(projArr[7])) {
                            setProject8(defaultSingleNumber(localProjectOptions, projArr[7]));
                         } else {
                             setManual8(true);
                             setProjectNumber8(projArr[7]);
                         }
                        setRange8(dataArr[7].split(':')[1]);

                        setProjCount(8);
                        break;
                    case 9:
                        setShow1(true);
                        if (projectNumbers.includes(projArr[0])) {
                           setProject1(defaultSingleNumber(localProjectOptions, projArr[0]));
                        } else {
                            setManual1(true);
                            setProjectNumber1(projArr[0]);
                        }
                        setRange1(dataArr[0].split(':')[1]);

                        setShow2(true);
                        if (projectNumbers.includes(projArr[1])) {
                            setProject2(defaultSingleNumber(localProjectOptions, projArr[1]));
                         } else {
                             setManual2(true);
                             setProjectNumber2(projArr[1]);
                         }
                        setRange2(dataArr[1].split(':')[1]);

                        setShow3(true);
                        if (projectNumbers.includes(projArr[2])) {
                            setProject3(defaultSingleNumber(localProjectOptions, projArr[2]));
                         } else {
                             setManual3(true);
                             setProjectNumber3(projArr[2]);
                         }
                        setRange3(dataArr[2].split(':')[1]);

                        setShow4(true);
                        if (projectNumbers.includes(projArr[3])) {
                            setProject4(defaultSingleNumber(localProjectOptions, projArr[3]));
                         } else {
                             setManual4(true);
                             setProjectNumber4(projArr[3]);
                         }
                        setRange4(dataArr[3].split(':')[1]);

                        setShow5(true);
                        if (projectNumbers.includes(projArr[4])) {
                            setProject5(defaultSingleNumber(localProjectOptions, projArr[4]));
                         } else {
                             setManual5(true);
                             setProjectNumber5(projArr[4]);
                         }
                        setRange5(dataArr[4].split(':')[1]);

                        setShow6(true);
                        if (projectNumbers.includes(projArr[5])) {
                            setProject6(defaultSingleNumber(localProjectOptions, projArr[5]));
                         } else {
                             setManual6(true);
                             setProjectNumber6(projArr[5]);
                         }
                        setRange6(dataArr[5].split(':')[1]);

                        setShow7(true);
                        if (projectNumbers.includes(projArr[6])) {
                            setProject7(defaultSingleNumber(localProjectOptions, projArr[6]));
                         } else {
                             setManual7(true);
                             setProjectNumber7(projArr[6]);
                         }
                        setRange7(dataArr[6].split(':')[1]);

                        setShow8(true);
                        if (projectNumbers.includes(projArr[7])) {
                            setProject8(defaultSingleNumber(localProjectOptions, projArr[7]));
                         } else {
                             setManual8(true);
                             setProjectNumber8(projArr[7]);
                         }
                        setRange8(dataArr[7].split(':')[1]);

                        setShow9(true);
                        if (projectNumbers.includes(projArr[8])) {
                            setProject9(defaultSingleNumber(localProjectOptions, projArr[8]));
                         } else {
                             setManual9(true);
                             setProjectNumber9(projArr[8]);
                         }
                        setRange9(dataArr[8].split(':')[1]);

                        setProjCount(9);
                        break;
                        
                    case 10:
                        setShow1(true);
                        if (projectNumbers.includes(projArr[0])) {
                           setProject1(defaultSingleNumber(localProjectOptions, projArr[0]));
                        } else {
                            setManual1(true);
                            setProjectNumber1(projArr[0]);
                        }
                        setRange1(dataArr[0].split(':')[1]);

                        setShow2(true);
                        if (projectNumbers.includes(projArr[1])) {
                            setProject2(defaultSingleNumber(localProjectOptions, projArr[1]));
                         } else {
                             setManual2(true);
                             setProjectNumber2(projArr[1]);
                         }
                        setRange2(dataArr[1].split(':')[1]);

                        setShow3(true);
                        if (projectNumbers.includes(projArr[2])) {
                            setProject3(defaultSingleNumber(localProjectOptions, projArr[2]));
                         } else {
                             setManual3(true);
                             setProjectNumber3(projArr[2]);
                         }
                        setRange3(dataArr[2].split(':')[1]);

                        setShow4(true);
                        if (projectNumbers.includes(projArr[3])) {
                            setProject4(defaultSingleNumber(localProjectOptions, projArr[3]));
                         } else {
                             setManual4(true);
                             setProjectNumber4(projArr[3]);
                         }
                        setRange4(dataArr[3].split(':')[1]);

                        setShow5(true);
                        if (projectNumbers.includes(projArr[4])) {
                            setProject5(defaultSingleNumber(localProjectOptions, projArr[4]));
                         } else {
                             setManual5(true);
                             setProjectNumber5(projArr[4]);
                         }
                        setRange5(dataArr[4].split(':')[1]);

                        setShow6(true);
                        if (projectNumbers.includes(projArr[5])) {
                            setProject6(defaultSingleNumber(localProjectOptions, projArr[5]));
                         } else {
                             setManual6(true);
                             setProjectNumber6(projArr[5]);
                         }
                        setRange6(dataArr[5].split(':')[1]);

                        setShow7(true);
                        if (projectNumbers.includes(projArr[6])) {
                            setProject7(defaultSingleNumber(localProjectOptions, projArr[6]));
                         } else {
                             setManual7(true);
                             setProjectNumber7(projArr[6]);
                         }
                        setRange7(dataArr[6].split(':')[1]);

                        setShow8(true);
                        if (projectNumbers.includes(projArr[7])) {
                            setProject8(defaultSingleNumber(localProjectOptions, projArr[7]));
                         } else {
                             setManual8(true);
                             setProjectNumber8(projArr[7]);
                         }
                        setRange8(dataArr[7].split(':')[1]);

                        setShow9(true);
                        if (projectNumbers.includes(projArr[8])) {
                            setProject9(defaultSingleNumber(localProjectOptions, projArr[8]));
                         } else {
                             setManual9(true);
                             setProjectNumber9(projArr[8]);
                         }
                        setRange9(dataArr[8].split(':')[1]);

                        setShow10(true);
                        if (projectNumbers.includes(projArr[9])) {
                            setProject10(defaultSingleNumber(localProjectOptions, projArr[9]));
                         } else {
                             setManual10(true);
                             setProjectNumber10(projArr[9]);
                         }
                        setRange10(dataArr[9].split(':')[1]);

                        setProjCount(10);
                        break;
                        
                    case 11:
                        setShow1(true);
                        if (projectNumbers.includes(projArr[0])) {
                           setProject1(defaultSingleNumber(localProjectOptions, projArr[0]));
                        } else {
                            setManual1(true);
                            setProjectNumber1(projArr[0]);
                        }
                        setRange1(dataArr[0].split(':')[1]);

                        setShow2(true);
                        if (projectNumbers.includes(projArr[1])) {
                            setProject2(defaultSingleNumber(localProjectOptions, projArr[1]));
                         } else {
                             setManual2(true);
                             setProjectNumber2(projArr[1]);
                         }
                        setRange2(dataArr[1].split(':')[1]);

                        setShow3(true);
                        if (projectNumbers.includes(projArr[2])) {
                            setProject3(defaultSingleNumber(localProjectOptions, projArr[2]));
                         } else {
                             setManual3(true);
                             setProjectNumber3(projArr[2]);
                         }
                        setRange3(dataArr[2].split(':')[1]);

                        setShow4(true);
                        if (projectNumbers.includes(projArr[3])) {
                            setProject4(defaultSingleNumber(localProjectOptions, projArr[3]));
                         } else {
                             setManual4(true);
                             setProjectNumber4(projArr[3]);
                         }
                        setRange4(dataArr[3].split(':')[1]);

                        setShow5(true);
                        if (projectNumbers.includes(projArr[4])) {
                            setProject5(defaultSingleNumber(localProjectOptions, projArr[4]));
                         } else {
                             setManual5(true);
                             setProjectNumber5(projArr[4]);
                         }
                        setRange5(dataArr[4].split(':')[1]);

                        setShow6(true);
                        if (projectNumbers.includes(projArr[5])) {
                            setProject6(defaultSingleNumber(localProjectOptions, projArr[5]));
                         } else {
                             setManual6(true);
                             setProjectNumber6(projArr[5]);
                         }
                        setRange6(dataArr[5].split(':')[1]);

                        setShow7(true);
                        if (projectNumbers.includes(projArr[6])) {
                            setProject7(defaultSingleNumber(localProjectOptions, projArr[6]));
                         } else {
                             setManual7(true);
                             setProjectNumber7(projArr[6]);
                         }
                        setRange7(dataArr[6].split(':')[1]);

                        setShow8(true);
                        if (projectNumbers.includes(projArr[7])) {
                            setProject8(defaultSingleNumber(localProjectOptions, projArr[7]));
                         } else {
                             setManual8(true);
                             setProjectNumber8(projArr[7]);
                         }
                        setRange8(dataArr[7].split(':')[1]);

                        setShow9(true);
                        if (projectNumbers.includes(projArr[8])) {
                            setProject9(defaultSingleNumber(localProjectOptions, projArr[8]));
                         } else {
                             setManual9(true);
                             setProjectNumber9(projArr[8]);
                         }
                        setRange9(dataArr[8].split(':')[1]);

                        setShow10(true);
                        if (projectNumbers.includes(projArr[9])) {
                            setProject10(defaultSingleNumber(localProjectOptions, projArr[9]));
                         } else {
                             setManual10(true);
                             setProjectNumber10(projArr[9]);
                         }
                        setRange10(dataArr[9].split(':')[1]);

                        setShow11(true);
                        if (projectNumbers.includes(projArr[10])) {
                            setProject11(defaultSingleNumber(localProjectOptions, projArr[10]));
                         } else {
                             setManual11(true);
                             setProjectNumber11(projArr[10]);
                         }
                        setRange11(dataArr[10].split(':')[1]);

                        setProjCount(11);
                        break;
                        
                    case 12:
                        setShow1(true);
                        if (projectNumbers.includes(projArr[0])) {
                           setProject1(defaultSingleNumber(localProjectOptions, projArr[0]));
                        } else {
                            setManual1(true);
                            setProjectNumber1(projArr[0]);
                        }
                        setRange1(dataArr[0].split(':')[1]);

                        setShow2(true);
                        if (projectNumbers.includes(projArr[1])) {
                            setProject2(defaultSingleNumber(localProjectOptions, projArr[1]));
                         } else {
                             setManual2(true);
                             setProjectNumber2(projArr[1]);
                         }
                        setRange2(dataArr[1].split(':')[1]);

                        setShow3(true);
                        if (projectNumbers.includes(projArr[2])) {
                            setProject3(defaultSingleNumber(localProjectOptions, projArr[2]));
                         } else {
                             setManual3(true);
                             setProjectNumber3(projArr[2]);
                         }
                        setRange3(dataArr[2].split(':')[1]);

                        setShow4(true);
                        if (projectNumbers.includes(projArr[3])) {
                            setProject4(defaultSingleNumber(localProjectOptions, projArr[3]));
                         } else {
                             setManual4(true);
                             setProjectNumber4(projArr[3]);
                         }
                        setRange4(dataArr[3].split(':')[1]);

                        setShow5(true);
                        if (projectNumbers.includes(projArr[4])) {
                            setProject5(defaultSingleNumber(localProjectOptions, projArr[4]));
                         } else {
                             setManual5(true);
                             setProjectNumber5(projArr[4]);
                         }
                        setRange5(dataArr[4].split(':')[1]);

                        setShow6(true);
                        if (projectNumbers.includes(projArr[5])) {
                            setProject6(defaultSingleNumber(localProjectOptions, projArr[5]));
                         } else {
                             setManual6(true);
                             setProjectNumber6(projArr[5]);
                         }
                        setRange6(dataArr[5].split(':')[1]);

                        setShow7(true);
                        if (projectNumbers.includes(projArr[6])) {
                            setProject7(defaultSingleNumber(localProjectOptions, projArr[6]));
                         } else {
                             setManual7(true);
                             setProjectNumber7(projArr[6]);
                         }
                        setRange7(dataArr[6].split(':')[1]);

                        setShow8(true);
                        if (projectNumbers.includes(projArr[7])) {
                            setProject8(defaultSingleNumber(localProjectOptions, projArr[7]));
                         } else {
                             setManual8(true);
                             setProjectNumber8(projArr[7]);
                         }
                        setRange8(dataArr[7].split(':')[1]);

                        setShow9(true);
                        if (projectNumbers.includes(projArr[8])) {
                            setProject9(defaultSingleNumber(localProjectOptions, projArr[8]));
                         } else {
                             setManual9(true);
                             setProjectNumber9(projArr[8]);
                         }
                        setRange9(dataArr[8].split(':')[1]);

                        setShow10(true);
                        if (projectNumbers.includes(projArr[9])) {
                            setProject10(defaultSingleNumber(localProjectOptions, projArr[9]));
                         } else {
                             setManual10(true);
                             setProjectNumber10(projArr[9]);
                         }
                        setRange10(dataArr[9].split(':')[1]);

                        setShow11(true);
                        if (projectNumbers.includes(projArr[10])) {
                            setProject11(defaultSingleNumber(localProjectOptions, projArr[10]));
                         } else {
                             setManual11(true);
                             setProjectNumber11(projArr[10]);
                         }
                        setRange11(dataArr[10].split(':')[1]);

                        setShow12(true);
                        if (projectNumbers.includes(projArr[11])) {
                            setProject12(defaultSingleNumber(localProjectOptions, projArr[11]));
                         } else {
                             setManual12(true);
                             setProjectNumber12(projArr[11]);
                         }
                        setRange12(dataArr[11].split(':')[1]);

                        setProjCount(12);
                        setProjMax(true);
                        break;
                                        
                        
                    default:
                        break;
                }

            }, (error) => {
                alert('Please send screenshot of this error message: ' + error.message);
            });
            
            setIsDataLoaded(true);
        }
    }

    const handleClose = () =>  {
        setShow(false);
        setIsDataLoaded(false);
        setStart();
        setLocation();
        setSubmitString('Save Timesheet');

        setErrorText('');
        setSubmitStringDelete('Delete Timesheet');
        setEnd();
        setHours(0);
        setBreakTime(0);
        
        setRangeTotal(0);
        setRangeError(false);
        setProjCount(1);
        setProjMin(true);
        setProjMax(false);
    
        setShow1(true);
        setManual1(false);
        setProject1();
        setRange1(0);
        setProjectNumber1('');

        setShow2(false);
        setManual2(false);
        setProject2();
        setRange2(0);
        setProjectNumber2('');

        setShow3(false);
        setManual3(false);
        setProject3();
        setRange3(0);
        setProjectNumber3('');

        setShow4(false);
        setManual4(false);
        setProject4();
        setRange4(0);
        setProjectNumber4('');

        setShow5(false);
        setManual5(false);
        setProject5();
        setRange5(0);
        setProjectNumber5('');

        setShow6(false);
        setManual6(false);
        setProject6();
        setRange6(0);
        setProjectNumber6('');

        setShow7(false);
        setManual7(false);
        setProject7();
        setRange7(0);
        setProjectNumber7('');

        setShow8(false);
        setManual8(false);
        setProject8();
        setRange8(0);
        setProjectNumber8('');

        setShow9(false);
        setManual9(false);
        setProject9();
        setRange9(0);
        setProjectNumber9('');

        setShow10(false);
        setManual10(false);
        setProject10();
        setRange10(0);
        setProjectNumber10('');

        setShow11(false);
        setManual11(false);
        setProject11();
        setRange11(0);
        setProjectNumber11('');

        setShow12(false);
        setManual12(false);
        setProject12();
        setRange12(0);
        setProjectNumber12('');
    }

    const checkNumero = (num) => {
        let regex = /\D/;
        let bool = regex.test(num);

        if (!bool && (num.length === 6)) {
            return false;
        } else {
            return true;
        }
    }

    useEffect(()=> {
        if (show) {
            const rn = new Date();
            if (end > rn) {
                setErrorText('Cannot end timesheet in future');
                setIsDisabled(true);
            } else if (hours < 0.5) {
                setErrorText('Minimum 30 minute shift time');
                setIsDisabled(true);
            } else if (hours > 18) {
                setErrorText('Maximum shift time exceeded');
                setIsDisabled(true);
            } else if ((hours > 5) && (breakTime < 0.5)) {
                setErrorText('Minimum 30 minute break required');
                setIsDisabled(true);
            } else if ((hours > 10) && (breakTime < 1)) {
                setErrorText('Minimum 1 hour break required');
                setIsDisabled(true);
            } else if (rangeError) {
                setErrorText('Total hours must equal timesheet hours. Projects cannot be empty.');
                setIsDisabled(true);
            } else if (show1 && ((manual1 && (checkNumero(projectNumber1))) || (!manual1 && !project1))) {
                setErrorText('Projects cannot be empty, and project number must be for an active project.');
                setIsDisabled(true);
            } else if (show2 && ((manual2 && (checkNumero(projectNumber2))) || (!manual2 && !project2))) {
                setErrorText('Projects cannot be empty, and project number must be for an active project.');
                setIsDisabled(true);
            } else if (show3 && ((manual3 && (checkNumero(projectNumber3))) || (!manual3 && !project3))) {
                setErrorText('Projects cannot be empty, and project number must be for an active project.');
                setIsDisabled(true);
            } else if (show4 && ((manual4 && (checkNumero(projectNumber4))) || (!manual4 && !project4))) {
                setErrorText('Projects cannot be empty, and project number must be for an active project.');
                setIsDisabled(true);
            } else if (show5 && ((manual5 && (checkNumero(projectNumber5))) || (!manual5 && !project5))) {
                setErrorText('Projects cannot be empty, and project number must be for an active project.');
                setIsDisabled(true);
            } else if (show6 && ((manual6 && (checkNumero(projectNumber6))) || (!manual6 && !project6))) {
                setErrorText('Projects cannot be empty, and project number must be for an active project.');
                setIsDisabled(true);
            } else if (show7 && ((manual7 && (checkNumero(projectNumber7))) || (!manual7 && !project7))) {
                setErrorText('Projects cannot be empty, and project number must be for an active project.');
                setIsDisabled(true);
            } else if (show8 && ((manual8 && (checkNumero(projectNumber8))) || (!manual8 && !project8))) {
                setErrorText('Projects cannot be empty, and project number must be for an active project.');
                setIsDisabled(true);
            } else if (show9 && ((manual9 && (checkNumero(projectNumber9))) || (!manual9 && !project9))) {
                setErrorText('Projects cannot be empty, and project number must be for an active project.');
                setIsDisabled(true);
            } else if (show10 && ((manual10 && (checkNumero(projectNumber10))) || (!manual10 && !project10))) {
                setErrorText('Projects cannot be empty, and project number must be for an active project.');
                setIsDisabled(true);
            } else if (show11 && ((manual11 && (checkNumero(projectNumber11))) || (!manual11 && !project11))) {
                setErrorText('Projects cannot be empty, and project number must be for an active project.');
                setIsDisabled(true);
            } else if (show12 && ((manual12 && (checkNumero(projectNumber12))) || (!manual12 && !project12))) {
                setErrorText('Projects cannot be empty, and project number must be for an active project.');
                setIsDisabled(true);
            } else {
                setErrorText('');
                (!end || !breakTime || !hours) ? setIsDisabled(true) : setIsDisabled(false)
            }
        }
    }, [end, breakTime, hours, rangeError, 
        show1, range1, manual1, projectNumber1, project1,
        show2, range2, manual2, projectNumber2, project2,
        show3, range3, manual3, projectNumber3, project3,
        show4, range4, manual4, projectNumber4, project4,
        show5, range5, manual5, projectNumber5, project5,
        show6, range6, manual6, projectNumber6, project6,
        show7, range7, manual7, projectNumber7, project7,
        show8, range8, manual8, projectNumber8, project8,
        show9, range9, manual9, projectNumber9, project9,
        show10, range10, manual10, projectNumber10, project10,
        show11, range11, manual11, projectNumber11, project11,
        show12, range12, manual12, projectNumber12, project12]);

    useEffect(()=>{
        if (rangeTotal === hours) {
            setRangeError(false);
        } else {
            setRangeError(true);
        }
    }, [rangeTotal, hours])

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitString(<>
            Submitting&nbsp;
            <Spinner
                as="span"
                animation="border"
                size="sm"
            />
        </>);

        const Timesheet = Parse.Object.extend('Timesheet');
        const query = new Parse.Query(Timesheet);
        query.get(props.row.values.idDisplay)
        .then(function(timesheet) {
            timesheet.set('active', false);
            timesheet.set('start', start);
            timesheet.set('location', location.value);
            timesheet.set('end', end);
            timesheet.set('breakHours', breakTime);
            timesheet.set('hours', hours);

            var projArr = [];
            var projHoursArr = [];

            if (show1 && (range1 !== 0)) {
                if (manual1) {
                    projArr.push(projectNumber1);
                    projHoursArr.push(projectNumber1 + ':' + range1 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                } else {
                    const num1 = project1.label.slice(0,6);
                    projArr.push(num1);
                    projHoursArr.push(num1 + ':' + range1 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                }
            }

            if (show2 && (range2 !== 0)) {
                if (manual2) {
                    projArr.push(projectNumber2);
                    projHoursArr.push(projectNumber2 + ':' + range2 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                } else {
                    const num2 = project2.label.slice(0,6);
                    projArr.push(num2);
                    projHoursArr.push(num2 + ':' + range2 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                }
            }

            if (show3 && (range3 !== 0)) {
                if (manual3) {
                    projArr.push(projectNumber3);
                    projHoursArr.push(projectNumber3 + ':' + range3 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                } else {
                    const num3 = project3.label.slice(0,6);
                    projArr.push(num3);
                    projHoursArr.push(num3 + ':' + range3 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                }
            }

            if (show4 && (range4 !== 0)) {
                if (manual4) {
                    projArr.push(projectNumber4);
                    projHoursArr.push(projectNumber4 + ':' + range4 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                } else {
                    const num4 = project4.label.slice(0,6);
                    projArr.push(num4);
                    projHoursArr.push(num4 + ':' + range4 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                }
            }

            if (show5 && (range5 !== 0)) {
                if (manual5) {
                    projArr.push(projectNumber5);
                    projHoursArr.push(projectNumber5 + ':' + range5 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                } else {
                    const num5 = project5.label.slice(0,6);
                    projArr.push(num5);
                    projHoursArr.push(num5 + ':' + range5 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                }
            }

            if (show6 && (range6 !== 0)) {
                if (manual6) {
                    projArr.push(projectNumber6);
                    projHoursArr.push(projectNumber6 + ':' + range6 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                } else {
                    const num6 = project6.label.slice(0,6);
                    projArr.push(num6);
                    projHoursArr.push(num6 + ':' + range6 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                }
            }

            if (show7 && (range7 !== 0)) {
                if (manual7) {
                    projArr.push(projectNumber7);
                    projHoursArr.push(projectNumber7 + ':' + range7 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                } else {
                    const num7 = project7.label.slice(0,6);
                    projArr.push(num7);
                    projHoursArr.push(num7 + ':' + range7 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                }
            }

            if (show8 && (range8 !== 0)) {
                if (manual8) {
                    projArr.push(projectNumber8);
                    projHoursArr.push(projectNumber8 + ':' + range8 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                } else {
                    const num8 = project8.label.slice(0,6);
                    projArr.push(num8);
                    projHoursArr.push(num8 + ':' + range8 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                }
            }

            if (show9 && (range9 !== 0)) {
                if (manual9) {
                    projArr.push(projectNumber9);
                    projHoursArr.push(projectNumber9 + ':' + range9 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                } else {
                    const num9 = project9.label.slice(0,6);
                    projArr.push(num9);
                    projHoursArr.push(num9 + ':' + range9 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                }
            }

            if (show10 && (range10 !== 0)) {
                if (manual10) {
                    projArr.push(projectNumber10);
                    projHoursArr.push(projectNumber10 + ':' + range10 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                } else {
                    const num10 = project10.label.slice(0,6);
                    projArr.push(num10);
                    projHoursArr.push(num10 + ':' + range10 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                }
            }

            if (show11 && (range11 !== 0)) {
                if (manual11) {
                    projArr.push(projectNumber11);
                    projHoursArr.push(projectNumber11 + ':' + range11 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                } else {
                    const num11 = project11.label.slice(0,6);
                    projArr.push(num11);
                    projHoursArr.push(num11 + ':' + range11 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                }
            }

            if (show12 && (range12 !== 0)) {
                if (manual12) {
                    projArr.push(projectNumber12);
                    projHoursArr.push(projectNumber12 + ':' + range12 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                } else {
                    const num12 = project12.label.slice(0,6);
                    projArr.push(num12);
                    projHoursArr.push(num12 + ':' + range12 + ':' + props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + ':' + props.currentUser.attributes.billableRate);
                }
            }

            timesheet.set('projects', projArr);
            timesheet.set('projectHours', projHoursArr);

            timesheet.save()
            .then((res)=>{
                const dataCopy = props.timesData;
                var thisSheet = dataCopy[props.row.index];
                if (start) {
                    thisSheet.startValueDisplay = start;
                    thisSheet.startDisplay = moment(start).locale('en-au').calendar({
                        // sameDay: '[Today]',
                        // nextDay: '[Tomorrow]',
                        nextWeek: 'ddd DD MMM h:mm A',
                        // lastDay: '[Yesterday]',
                        lastWeek: 'ddd DD MMM h:mm A',
                        sameElse: 'ddd DD MMM h:mm A'   
                    });
                } 
                if (location) {
                    thisSheet.locationDisplay = location.value;
                } 
                if (end) {
                    thisSheet.endValueDisplay = end;
                    thisSheet.endDisplay = moment(end).locale('en-au').calendar({
                        // sameDay: '[Today]',
                        // nextDay: '[Tomorrow]',
                        nextWeek: 'ddd DD MMM h:mm A',
                        // lastDay: '[Yesterday]',
                        lastWeek: 'ddd DD MMM h:mm A',
                        sameElse: 'ddd DD MMM h:mm A'   
                    });
                } 
                if (breakTime) {
                    thisSheet.breakDisplay = breakTime;
                } 
                if (hours) {
                    thisSheet.hoursDisplay = hours;
                } 
                dataCopy[props.row.index] = thisSheet;
                props.setTimesData(dataCopy);
                handleClose();
            }, (error) => {
                alert('Please send screenshot of this error message: ' + error.message);
                setSubmitString('Save Timesheet');
            });
        }, (error) => {
            alert('Please send screenshot of this error message: ' + error.message);
            setSubmitString('Save Timesheet');
        });
    }

    const handleSubmitDelete = (e) => {
        e.preventDefault();
        setSubmitStringDelete(<>
            Deleting&nbsp;
            <Spinner
                as="span"
                animation="border"
                size="sm"
            />
        </>);

        const Timesheet = Parse.Object.extend('Timesheet');
        const query = new Parse.Query(Timesheet);
        query.get(props.row.values.idDisplay)
        .then(function(timesheet) {
            timesheet.destroy()
            .then((res)=>{
                const dataCopy = props.timesData;
                dataCopy.splice(props.row.index, 1);
                props.setTimesData(dataCopy);
                handleClose();
            }, (error) => {
                alert('Please send screenshot of this error message: ' + error.message);
                setSubmitStringDelete('Delete Timesheet');
            });
        }, (error) => {
            alert('Please send screenshot of this error message: ' + error.message);
            setSubmitStringDelete('Delete Timesheet');
        });
    }

    const renderTooltip1 = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Meal breaks are required under fair work legislation.
        </Tooltip>
    );

    const renderTooltip2 = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Hours are rounded to the nearest 15 minute increment in order to 
            facilitate easy input of project hours. 
        </Tooltip>
    );

    const handleLocationSelect = (e) => {
        setLocation(e);
    }

    const handleDayStart = (date) => {
        var s = moment(date);
        var e = moment(end);
        if (e < s) {
            // setErrorText('Shift cannot end before it starts');
            // setStart();
        } else {
            setErrorText('');
            setStart(date);
            calcHours(date, end, breakTime);
        }
    }

    const handleDayEnd = (date) => {
        var s = moment(start);
        var e = moment(date);
        if (e < s) {
            // setErrorText('Shift cannot end before it starts');
            setEnd();
        } else {
            setErrorText('');
            setEnd(date);
            calcHours(start, date, breakTime);
        }
    }

    const handleBreakTime = (e) => {
        var b = Math.round(e.target.value / 0.25) * 0.25;
        setBreakTime(b);
        calcHours(start, end, b);
    }

    const setBreak30 = () => {
        setBreakTime(0.5);
        calcHours(start, end, 0.5);
    }

    const setBreak60 = () => {
        setBreakTime(1);
        calcHours(start, end, 1);
    }

    const setBreak90 = () => {
        setBreakTime(1.5);
        calcHours(start, end, 1.5);
    }

    const setBreak120 = () => {
        setBreakTime(2);
        calcHours(start, end, 2);
    }

    const calcHours = (s1, e1, b1, param) => {
        var s = moment(s1);
        var e = moment(e1);
        var m = (e.diff(s, 'minutes'));
        var h1 = +(((m/60) - b1));
        var h = Math.round(h1 / 0.25) * 0.25;
        setHours(h);
        if (param === 'init') {
            setRangeTotal(h);
        }
        if (h < 0.5) {
            setZeroTimesheet(true);
        } else {
            setZeroTimesheet(false);
        }
    }

    const addProj = () => {
        var cont = projCount;
        setProjCount(cont + 1);
        switch (cont + 1) {
            case 1:
                // doesn't exist
                break;
            case 2:
                setShow2(true);
                setProjMin(false);
                break;
            case 3:
                setShow3(true);
                break;
            case 4:
                setShow4(true);
                break;
            case 5:
                setShow5(true);
                break;
            case 6:
                setShow6(true);
                break;
            case 7:
                setShow7(true);
                break;
            case 8:
                setShow8(true);
                break;
            case 9:
                setShow9(true);
                break;
            case 10:
                setShow10(true);
                break;
            case 11:
                setShow11(true);
                break;
            case 12:
                setShow12(true);
                setProjMax(true);
                break;
            default:
                break;
        }
    }

    const removeProj = () => {
        var cont = projCount;
        setProjCount(cont - 1);
        switch (cont) {
            case 1:
                // doesn't exist
                break;
            case 2:
                setShow2(false);
                setProjMin(true);
                setRange2(0);
                break;
            case 3:
                setShow3(false);
                setRange3(0);
                break;
            case 4:
                setShow4(false);
                setRange4(0);
                break;
            case 5:
                setShow5(false);
                setRange5(0);
                break;
            case 6:
                setShow6(false);
                setRange6(0);
                break;
            case 7:
                setShow7(false);
                setRange7(0);
                break;
            case 8:
                setShow8(false);
                setRange8(0);
                break;
            case 9:
                setShow9(false);
                setRange9(0);
                break;
            case 10:
                setShow10(false);
                setRange10(0);
                break;
            case 11:
                setShow11(false);
                setRange11(0);
                break;
            case 12:
                setShow12(false);
                setRange12(0);
                setProjMax(false);
                break;
            default:
                break;
        }
    }

    const handleRange1 = (range) => {
        setRange1(range);
        var h1 = (parseFloat(range) + parseFloat(range2) + parseFloat(range3) + parseFloat(range4) + parseFloat(range5) + parseFloat(range6) + 
                    parseFloat(range7) + parseFloat(range8) + parseFloat(range9) + parseFloat(range10) + parseFloat(range11) + parseFloat(range12))
        var h = Math.round(h1 / 0.25) * 0.25;
        setRangeTotal(h);
    }

    const handleRange2 = (range) => {
        setRange2(range);
        var h1 = (parseFloat(range1) + parseFloat(range) + parseFloat(range3) + parseFloat(range4) + parseFloat(range5) + parseFloat(range6) + 
                    parseFloat(range7) + parseFloat(range8) + parseFloat(range9) + parseFloat(range10) + parseFloat(range11) + parseFloat(range12))
        var h = Math.round(h1 / 0.25) * 0.25;
        setRangeTotal(h);    
    }

    const handleRange3 = (range) => {
        setRange3(range);
        var h1 = (parseFloat(range1) + parseFloat(range2) + parseFloat(range) + parseFloat(range4) + parseFloat(range5) + parseFloat(range6) + 
                    parseFloat(range7) + parseFloat(range8) + parseFloat(range9) + parseFloat(range10) + parseFloat(range11) + parseFloat(range12))
        var h = Math.round(h1 / 0.25) * 0.25;
        setRangeTotal(h);    
    }

    const handleRange4 = (range) => {
        setRange4(range);
        var h1 = (parseFloat(range1) + parseFloat(range2) + parseFloat(range3) + parseFloat(range) + parseFloat(range5) + parseFloat(range6) + 
                    parseFloat(range7) + parseFloat(range8) + parseFloat(range9) + parseFloat(range10) + parseFloat(range11) + parseFloat(range12))
        var h = Math.round(h1 / 0.25) * 0.25;
        setRangeTotal(h);        
    }

    const handleRange5 = (range) => {
        setRange5(range);
        var h1 = (parseFloat(range1) + parseFloat(range2) + parseFloat(range3) + parseFloat(range4) + parseFloat(range) + parseFloat(range6) + 
                    parseFloat(range7) + parseFloat(range8) + parseFloat(range9) + parseFloat(range10) + parseFloat(range11) + parseFloat(range12))
        var h = Math.round(h1 / 0.25) * 0.25;
        setRangeTotal(h);        
    }

    const handleRange6 = (range) => {
        setRange6(range);
        var h1 = (parseFloat(range1) + parseFloat(range2) + parseFloat(range3) + parseFloat(range4) + parseFloat(range5) + parseFloat(range) + 
                    parseFloat(range7) + parseFloat(range8) + parseFloat(range9) + parseFloat(range10) + parseFloat(range11) + parseFloat(range12))
        var h = Math.round(h1 / 0.25) * 0.25;
        setRangeTotal(h);        
    }

    const handleRange7 = (range) => {
        setRange7(range);
        var h1 = (parseFloat(range1) + parseFloat(range2) + parseFloat(range3) + parseFloat(range4) + parseFloat(range5) + parseFloat(range6) + 
                    parseFloat(range) + parseFloat(range8) + parseFloat(range9) + parseFloat(range10) + parseFloat(range11) + parseFloat(range12))
        var h = Math.round(h1 / 0.25) * 0.25;
        setRangeTotal(h);        
    }

    const handleRange8 = (range) => {
        setRange8(range);
        var h1 = (parseFloat(range1) + parseFloat(range2) + parseFloat(range3) + parseFloat(range4) + parseFloat(range5) + parseFloat(range6) + 
                    parseFloat(range7) + parseFloat(range) + parseFloat(range9) + parseFloat(range10) + parseFloat(range11) + parseFloat(range12))
        var h = Math.round(h1 / 0.25) * 0.25;
        setRangeTotal(h);        
    }

    const handleRange9 = (range) => {
        setRange9(range);
        var h1 = (parseFloat(range1) + parseFloat(range2) + parseFloat(range3) + parseFloat(range4) + parseFloat(range5) + parseFloat(range6) + 
                    parseFloat(range7) + parseFloat(range8) + parseFloat(range) + parseFloat(range10) + parseFloat(range11) + parseFloat(range12))
        var h = Math.round(h1 / 0.25) * 0.25;
        setRangeTotal(h);        
    }

    const handleRange10 = (range) => {
        setRange10(range);
        var h1 = (parseFloat(range1) + parseFloat(range2) + parseFloat(range3) + parseFloat(range4) + parseFloat(range5) + parseFloat(range6) + 
                    parseFloat(range7) + parseFloat(range8) + parseFloat(range9) + parseFloat(range) + parseFloat(range11) + parseFloat(range12))
        var h = Math.round(h1 / 0.25) * 0.25;
        setRangeTotal(h);        
    }

    const handleRange11 = (range) => {
        setRange11(range);
        var h1 = (parseFloat(range1) + parseFloat(range2) + parseFloat(range3) + parseFloat(range4) + parseFloat(range5) + parseFloat(range6) + 
                    parseFloat(range7) + parseFloat(range8) + parseFloat(range9) + parseFloat(range10) + parseFloat(range) + parseFloat(range12))
        var h = Math.round(h1 / 0.25) * 0.25;
        setRangeTotal(h);        
    }

    const handleRange12 = (range) => {
        setRange12(range);
        var h1 = (parseFloat(range1) + parseFloat(range2) + parseFloat(range3) + parseFloat(range4) + parseFloat(range5) + parseFloat(range6) + 
                    parseFloat(range7) + parseFloat(range8) + parseFloat(range9) + parseFloat(range10) + parseFloat(range11) + parseFloat(range))
        var h = Math.round(h1 / 0.25) * 0.25;
        setRangeTotal(h);        
    }

    const SumText = () => {
        return <>
            Hours: {hours}<br />
            Total: {rangeTotal}
        </>
    }

    return <>
        {(!props.isEditable) && (
            <Button variant='outline-secondary' onClick={handleShow}>
                <i className="fas fa-pen"></i>
            </Button>
        )}
        <Modal show={show} onHide={handleClose} centered backdrop="static" size='lg'>
            <Modal.Header closeButton className='modal-header'>
                <Modal.Title>Edit Timesheet</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Form.Group as={Col} md="4" className="mb-1">
                            <Form.Label>Start Time</Form.Label>
                            <DatePicker
                                dateFormat="dd/MM/yyyy h:mm aa"
                                className='form-control'
                                selected={start}
                                showTimeSelect
                                timeIntervals={15}
                                maxDate={new Date()}
                                // minTime={midnight}
                                // maxTime={new Date()}
                                onChange={(date)=> handleDayStart(date)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="2">
                            <Form.Label className='pl-4'>Break (hours)</Form.Label>
                            <NumberFormat
                                className="form-control"
                                placeholder=""
                                onChange={(e) => handleBreakTime(e)}
                                value={breakTime}
                                displayType="input"
                                type="text"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                            <Form.Label className='pl-4'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Form.Label>
                            <Stack direction='horizontal' gap={1}>
                                <Button onClick={setBreak30} variant="outline-secondary">
                                    30 min
                                </Button>
                                <Button onClick={setBreak60} variant="outline-secondary">
                                    60 min  
                                </Button>
                                <Button onClick={setBreak90} variant="outline-secondary">
                                    1.5 hours  
                                </Button>
                                <Button onClick={setBreak120} variant="outline-secondary">
                                    2 hours  
                                </Button>
                            </Stack>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md="4" className="mb-1">
                            <Form.Label>End Time</Form.Label>
                            <DatePicker
                                dateFormat="dd/MM/yyyy h:mm aa"
                                className='form-control'
                                selected={end}
                                showTimeSelect
                                timeIntervals={15}
                                maxDate={new Date()}
                                // minTime={midnight}
                                // maxTime={new Date()}
                                onChange={(date)=> handleDayEnd(date)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" className="mb-1">
                            <Form.Label>Location</Form.Label>
                            <Select 
                                styles={customStyles}
                                options={officeOptions}
                                onChange={(e)=>handleLocationSelect(e)}
                                value={location}
                                defaultValue={location}
                            />
                        </Form.Group>
                        
                        <Form.Group as={Col} md={4} style={{fontSize:'.875em'}}>
                            <Row className='mt-4'>
                                <Col md={12}>
                                    <Stack direction='horizontal' gap={1}>
                                        <span>
                                            Break: {breakTime} hours    
                                        </span>
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip1}
                                        >
                                            <i className="fas fa-info-circle"></i>
                                        </OverlayTrigger>
                                    </Stack>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Stack direction='horizontal' gap={1}>
                                        <span>
                                            Hours: {hours} hours 
                                        </span>
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip2}
                                        >
                                            <i className="fas fa-info-circle"></i>
                                        </OverlayTrigger>
                                    </Stack>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Row>
                    {/* <Row className='mt-3 mb-3'>
                        <HoursText />   
                    </Row> */}
                     {(!zeroTimesheet) && (
                        <Row className='mt-3 mb-3'>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="5">
                                    <Form.Label>Project hours</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="7">
                                    <Form.Group as={Row}>
                                        <Col xs="9">
                                            &nbsp;
                                        </Col>
                                        <Col xs="3">
                                            <SumText/> 
                                        </Col>
                                    </Form.Group>
                                </Form.Group>
                            </Row>                        
                            {show1 && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="1" className='subbie'>
                                        <Form.Check
                                            label=""
                                            checked={manual1}
                                            onChange={(e) => setManual1(e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        {!manual1 && (
                                            <Select
                                                placeholder='Select project'
                                                styles={customStyles}
                                                options={localProjectOptions}
                                                value={project1}
                                                onChange={e=>setProject1(e)}
                                            />
                                        )}
                                        {manual1 && (
                                            <Form.Control
                                                type="text"
                                                placeholder='Project Number'
                                                value={projectNumber1}
                                                onChange={(e) => setProjectNumber1(e.target.value)}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} md="7">
                                        <Form.Group as={Row}>
                                            <Col xs="9">
                                                <RangeSlider
                                                    value={range1}
                                                    onChange={e => handleRange1(e.target.value)}
                                                    variant='primary'
                                                    step={0.25}
                                                    max={hours}
                                                />
                                            </Col>
                                            <Col xs="3">
                                                <Form.Control 
                                                    value={range1}
                                                    onChange={(e) => handleRange1(e.target.value)}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Form.Group>
                                </Row>
                            )}
                            {show2 && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="1" className='subbie'>
                                        <Form.Check
                                            label=""
                                            checked={manual2}
                                            onChange={(e) => setManual2(e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        {!manual2 && (
                                            <Select
                                                placeholder='Select project'
                                                styles={customStyles}
                                                options={localProjectOptions}
                                                value={project2}
                                                onChange={e=>setProject2(e)}
                                            />
                                        )}
                                        {manual2 && (
                                            <Form.Control
                                                type="text"
                                                placeholder='Project Number'
                                                value={projectNumber2}
                                                onChange={(e) => setProjectNumber2(e.target.value)}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} md="7">
                                        <Form.Group as={Row}>
                                            <Col xs="9">
                                                <RangeSlider
                                                    value={range2}
                                                    onChange={e => handleRange2(e.target.value)}
                                                    variant='danger'
                                                    step={0.25}
                                                    max={hours}
                                                />
                                            </Col>
                                            <Col xs="3">
                                                <Form.Control 
                                                    value={range2}
                                                    onChange={(e) => handleRange2(e.target.value)}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Form.Group>
                                </Row>
                            )}
                            {show3 && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="1" className='subbie'>
                                        <Form.Check
                                            label=""
                                            checked={manual3}
                                            onChange={(e) => setManual3(e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        {!manual3 && (
                                            <Select
                                                placeholder='Select project'
                                                styles={customStyles}
                                                options={localProjectOptions}
                                                value={project3}
                                                onChange={e=>setProject3(e)}
                                            />
                                        )}
                                        {manual3 && (
                                            <Form.Control
                                                type="text"
                                                placeholder='Project Number'
                                                value={projectNumber3}
                                                onChange={(e) => setProjectNumber3(e.target.value)}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} md="7">
                                        <Form.Group as={Row}>
                                            <Col xs="9">
                                                <RangeSlider
                                                    value={range3}
                                                    onChange={e => handleRange3(e.target.value)}
                                                    variant='success'
                                                    step={0.25}
                                                    max={hours}
                                                />
                                            </Col>
                                            <Col xs="3">
                                                <Form.Control 
                                                    value={range3}
                                                    onChange={(e) => handleRange3(e.target.value)}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Form.Group>
                                </Row>
                            )}
                            {show4 && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="1" className='subbie'>
                                        <Form.Check
                                            label=""
                                            checked={manual4}
                                            onChange={(e) => setManual4(e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        {!manual4 && (
                                            <Select
                                                placeholder='Select project'
                                                styles={customStyles}
                                                options={localProjectOptions}
                                                value={project4}
                                                onChange={e=>setProject4(e)}
                                            />
                                        )}
                                        {manual4 && (
                                            <Form.Control
                                                type="text"
                                                placeholder='Project Number'
                                                value={projectNumber4}
                                                onChange={(e) => setProjectNumber4(e.target.value)}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} md="7">
                                        <Form.Group as={Row}>
                                            <Col xs="9">
                                                <RangeSlider
                                                    value={range4}
                                                    onChange={e => handleRange4(e.target.value)}
                                                    variant='warning'
                                                    step={0.25}
                                                    max={hours}
                                                />
                                            </Col>
                                            <Col xs="3">
                                                <Form.Control 
                                                    value={range4}
                                                    onChange={(e) => handleRange4(e.target.value)}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Form.Group>
                                </Row>
                            )}
                            {show5 && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="1" className='subbie'>
                                        <Form.Check
                                            label=""
                                            checked={manual5}
                                            onChange={(e) => setManual5(e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        {!manual5 && (
                                            <Select
                                                placeholder='Select project'
                                                styles={customStyles}
                                                options={localProjectOptions}
                                                value={project5}
                                                onChange={e=>setProject5(e)}
                                            />
                                        )}
                                        {manual5 && (
                                            <Form.Control
                                                type="text"
                                                placeholder='Project Number'
                                                value={projectNumber5}
                                                onChange={(e) => setProjectNumber5(e.target.value)}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} md="7">
                                        <Form.Group as={Row}>
                                            <Col xs="9">
                                                <RangeSlider
                                                    value={range5}
                                                    onChange={e => handleRange5(e.target.value)}
                                                    variant='info'
                                                    step={0.25}
                                                    max={hours}
                                                />
                                            </Col>
                                            <Col xs="3">
                                                <Form.Control 
                                                    value={range5}
                                                    onChange={(e) => handleRange5(e.target.value)}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Form.Group>
                                </Row>
                            )}
                            {show6 && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="1" className='subbie'>
                                        <Form.Check
                                            label=""
                                            checked={manual6}
                                            onChange={(e) => setManual6(e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        {!manual6 && (
                                            <Select
                                                placeholder='Select project'
                                                styles={customStyles}
                                                options={localProjectOptions}
                                                value={project6}
                                                onChange={e=>setProject6(e)}
                                            />
                                        )}
                                        {manual6 && (
                                            <Form.Control
                                                type="text"
                                                placeholder='Project Number'
                                                value={projectNumber6}
                                                onChange={(e) => setProjectNumber6(e.target.value)}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} md="7">
                                        <Form.Group as={Row}>
                                            <Col xs="9">
                                                <RangeSlider
                                                    value={range6}
                                                    onChange={e => handleRange6(e.target.value)}
                                                    variant='secondary'
                                                    step={0.25}
                                                    max={hours}
                                                />
                                            </Col>
                                            <Col xs="3">
                                                <Form.Control 
                                                    value={range6}
                                                    onChange={(e) => handleRange6(e.target.value)}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Form.Group>
                                </Row>
                            )}
                            {show7 && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="1" className='subbie'>
                                        <Form.Check
                                            label=""
                                            checked={manual7}
                                            onChange={(e) => setManual7(e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        {!manual7 && (
                                            <Select
                                                placeholder='Select project'
                                                styles={customStyles}
                                                options={localProjectOptions}
                                                value={project7}
                                                onChange={e=>setProject7(e)}
                                            />
                                        )}
                                        {manual7 && (
                                            <Form.Control
                                                type="text"
                                                placeholder='Project Number'
                                                value={projectNumber7}
                                                onChange={(e) => setProjectNumber7(e.target.value)}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} md="7">
                                        <Form.Group as={Row}>
                                            <Col xs="9">
                                                <RangeSlider
                                                    value={range7}
                                                    onChange={e => handleRange7(e.target.value)}
                                                    variant='primary'
                                                    step={0.25}
                                                    max={hours}
                                                />
                                            </Col>
                                            <Col xs="3">
                                                <Form.Control 
                                                    value={range7}
                                                    onChange={(e) => handleRange7(e.target.value)}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Form.Group>
                                </Row>
                            )}
                            {show8 && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="1" className='subbie'>
                                        <Form.Check
                                            label=""
                                            checked={manual8}
                                            onChange={(e) => setManual8(e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        {!manual8 && (
                                            <Select
                                                placeholder='Select project'
                                                styles={customStyles}
                                                options={localProjectOptions}
                                                value={project8}
                                                onChange={e=>setProject8(e)}
                                            />
                                        )}
                                        {manual8 && (
                                            <Form.Control
                                                type="text"
                                                placeholder='Project Number'
                                                value={projectNumber8}
                                                onChange={(e) => setProjectNumber8(e.target.value)}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} md="7">
                                        <Form.Group as={Row}>
                                            <Col xs="9">
                                                <RangeSlider
                                                    value={range8}
                                                    onChange={e => handleRange8(e.target.value)}
                                                    variant='danger'
                                                    step={0.25}
                                                    max={hours}
                                                />
                                            </Col>
                                            <Col xs="3">
                                                <Form.Control 
                                                    value={range8}
                                                    onChange={(e) => handleRange8(e.target.value)}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Form.Group>
                                </Row>
                            )}
                            {show9 && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="1" className='subbie'>
                                        <Form.Check
                                            label=""
                                            checked={manual9}
                                            onChange={(e) => setManual9(e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        {!manual9 && (
                                            <Select
                                                placeholder='Select project'
                                                styles={customStyles}
                                                options={localProjectOptions}
                                                value={project9}
                                                onChange={e=>setProject9(e)}
                                            />
                                        )}
                                        {manual9 && (
                                            <Form.Control
                                                type="text"
                                                placeholder='Project Number'
                                                value={projectNumber9}
                                                onChange={(e) => setProjectNumber9(e.target.value)}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} md="7">
                                        <Form.Group as={Row}>
                                            <Col xs="9">
                                                <RangeSlider
                                                    value={range9}
                                                    onChange={e => handleRange9(e.target.value)}
                                                    variant='success'
                                                    step={0.25}
                                                    max={hours}
                                                />
                                            </Col>
                                            <Col xs="3">
                                                <Form.Control 
                                                    value={range9}
                                                    onChange={(e) => handleRange9(e.target.value)}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Form.Group>
                                </Row>
                            )}
                            {show10 && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="1" className='subbie'>
                                        <Form.Check
                                            label=""
                                            checked={manual10}
                                            onChange={(e) => setManual10(e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        {!manual10 && (
                                            <Select
                                                placeholder='Select project'
                                                styles={customStyles}
                                                options={localProjectOptions}
                                                value={project10}
                                                onChange={e=>setProject10(e)}
                                            />
                                        )}
                                        {manual10 && (
                                            <Form.Control
                                                type="text"
                                                placeholder='Project Number'
                                                value={projectNumber10}
                                                onChange={(e) => setProjectNumber10(e.target.value)}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} md="7">
                                        <Form.Group as={Row}>
                                            <Col xs="9">
                                                <RangeSlider
                                                    value={range10}
                                                    onChange={e => handleRange10(e.target.value)}
                                                    variant='warning'
                                                    step={0.25}
                                                    max={hours}
                                                />
                                            </Col>
                                            <Col xs="3">
                                                <Form.Control 
                                                    value={range10}
                                                    onChange={(e) => handleRange10(e.target.value)}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Form.Group>
                                </Row>
                            )}
                            {show11 && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="1" className='subbie'>
                                        <Form.Check
                                            label=""
                                            checked={manual11}
                                            onChange={(e) => setManual11(e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        {!manual11 && (
                                            <Select
                                                placeholder='Select project'
                                                styles={customStyles}
                                                options={localProjectOptions}
                                                value={project11}
                                                onChange={e=>setProject11(e)}
                                            />
                                        )}
                                        {manual11 && (
                                            <Form.Control
                                                type="text"
                                                placeholder='Project Number'
                                                value={projectNumber11}
                                                onChange={(e) => setProjectNumber11(e.target.value)}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} md="7">
                                        <Form.Group as={Row}>
                                            <Col xs="9">
                                                <RangeSlider
                                                    value={range11}
                                                    onChange={e => handleRange11(e.target.value)}
                                                    variant='info'
                                                    step={0.25}
                                                    max={hours}
                                                />
                                            </Col>
                                            <Col xs="3">
                                                <Form.Control 
                                                    value={range11}
                                                    onChange={(e) => handleRange11(e.target.value)}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Form.Group>
                                </Row>
                            )}
                            {show12 && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="1" className='subbie'>
                                        <Form.Check
                                            label=""
                                            checked={manual12}
                                            onChange={(e) => setManual12(e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        {!manual12 && (
                                            <Select
                                                placeholder='Select project'
                                                styles={customStyles}
                                                options={localProjectOptions}
                                                value={project12}
                                                onChange={e=>setProject12(e)}
                                            />
                                        )}
                                        {manual12 && (
                                            <Form.Control
                                                type="text"
                                                placeholder='Project Number'
                                                value={projectNumber12}
                                                onChange={(e) => setProjectNumber12(e.target.value)}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} md="7">
                                        <Form.Group as={Row}>
                                            <Col xs="9">
                                                <RangeSlider
                                                    value={range12}
                                                    onChange={e => handleRange12(e.target.value)}
                                                    variant='secondary'
                                                    step={0.25}
                                                    max={hours}
                                                />
                                            </Col>
                                            <Col xs="3">
                                                <Form.Control 
                                                    value={range12}
                                                    onChange={(e) => handleRange12(e.target.value)}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Form.Group>
                                </Row>
                            )}
                            <Row>
                                <Form.Group as={Col} md="12">
                                    <Stack direction='horizontal' gap={1}>
                                        <Form.Label>Custom</Form.Label> 
                                        {!projMax && (
                                            <Button
                                                onClick={addProj}
                                                className='ms-auto'
                                                variant='outline-primary'
                                            >
                                                <i className="fas fa-plus"/>
                                            </Button>
                                        )}
                                        {(!projMin && !projMax) && (
                                            <Button
                                                onClick={removeProj}
                                                variant='outline-secondary'
                                            >
                                                <i className="fas fa-minus"/>
                                            </Button>
                                        )}
                                        {(projMax) && (
                                            <Button
                                                onClick={removeProj}
                                                className='ms-auto'
                                                variant='outline-secondary'
                                            >
                                                <i className="fas fa-minus"/>
                                            </Button>
                                        )}
                                    </Stack>
                                </Form.Group>
                            </Row>
                        </Row>
                    )}
                    <Row>
                        <div className='form-fail'>
                            {errorText}
                        </div>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                {(!zeroTimesheet) && (
                    <Button
                        variant="primary"
                        onClick={handleSubmit}
                        disabled={isDisabled}
                    >
                        {submitString}
                    </Button>
                )}
                {(zeroTimesheet) && (
                    <Button
                        variant="danger"
                        onClick={handleSubmitDelete}
                    >
                        {submitStringDelete}
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    </>
}
