// Change password form and handler

import React, { useState, useEffect } from 'react';
import { Breadcrumb, Button, Form, Container, Row, Col } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import './ResetPassword.css';

export default function ResetPassword() {

    useEffect(() => {
        document.title = "Portal – Change Password";
    }, []);

    // const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    let history = useHistory();

    const newPasswordHandleChange = (e) => {
        document.getElementById('form-feedback').innerText = '';
        setNewPassword(e.target.value);
    }

    const confirmPasswordHandleChange = (e) => {
        document.getElementById('form-feedback').innerText = '';
        setConfirmPassword(e.target.value);
    }
    
    const handleSubmit = (event) => {
        
        event.preventDefault();

        const regex = new RegExp('(?=.{8,})((?=.*[a-z])(?=.*[A-Z])|(?=.*[a-z])(?=.*[0-9])|(?=.*[a-z])(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*[0-9])|(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[0-9])(?=.*[^A-Za-z0-9]))');

        if (!regex.test(newPassword)) { 
            document.getElementById('form-feedback').innerText = 'Password is not secure';
        } else if (newPassword !== confirmPassword) {
            document.getElementById('form-feedback').innerText = 'Passwords do not match';
        } else if (newPassword === confirmPassword && regex.test(newPassword)) {
            document.getElementById('form-feedback').innerText = '';
            const Parse = require('parse');
            const User = Parse.Object.extend("User");
            const user = new User();
            
            user.set(Parse.User.current());

            user.save().then((user) => {
                user.set('password', newPassword);
                user.save();
                history.push('/account');
            }).catch((error) => {
                document.getElementById('form-feedback').innerText = error.message;
            });
        }
    }

    return <>
        {/* <Container>
            <Breadcrumb className="container">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/account">Manage Account</Breadcrumb.Item>
                <Breadcrumb.Item active>Change Password</Breadcrumb.Item>
            </Breadcrumb>
        </Container> */}
        <Container>
            <div className="client-homepage">
                <div className="home-header">
                    <h2>Change Password</h2>
                </div>
                <hr/>
                <Form onSubmit={handleSubmit}>  
                    {/* <Form.Group className="mb-3">
                        <Form.Label>Current Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder=""
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            required
                            autoFocus
                        />
                    </Form.Group> */}
                    <Row>
                        <Form.Group className="mb-3" as={Col} md="4">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder=""
                                value={newPassword}
                                onChange={newPasswordHandleChange}
                                required
                            />
                            <Form.Text muted>
                                Please choose a secure password with at least 8 characters that contains 
                                at least two of the following: lowercase letters, uppercase letters, numbers, and symbols.
                            </Form.Text>
                        </Form.Group>
                        </Row>
                        <Row>
                        <Form.Group className="mb-3" as={Col} md="4">
                            <Form.Label>Confirm New Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder=""
                                value={confirmPassword}
                                onChange={confirmPasswordHandleChange}
                                required
                            />
                            <Form.Text muted>
                                Both passwords must match.
                            </Form.Text>
                        </Form.Group>
                    </Row>
                    <Button type="submit">Confirm</Button>
                    <div id='form-feedback' className='form-fail'>
                        
                    </div>
                </Form>
            </div>
        </Container>
    </>
}
