import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, InputGroup, Modal, ProgressBar, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { documentOptions, customStyles } from '../../data/Data';
import PdfUpload from './PdfUpload';

export default function AddDocument(props) {

    // Status Modal
    const [show, setShow] = useState(false);

    // Variables
    const [documentType, setDocumentType] = useState([]);
    const [staffOptions, setStaffOptions] = useState([]);
    const [directorOptions, setDirectorOptions] = useState([]);
    const [preparedBy, setPreparedBy] = useState('');
    const [reviewedBy, setReviewedBy] = useState('');
    const [authorisedBy, setAuthorisedBy] = useState('');
    const [cloudLink, setCloudLink] = useState('');
    const [reviewedAt, setReviewedAt] = useState(new Date());
    const [revision, setRevision] = useState('A');
    const [isDraft, setIsDraft] = useState(false);
    const [other, setOther] = useState('');
    const [showOther, setShowOther] = useState(false);
    const [acceptedFiles, setAcceptFiles] = useState([]);
    const [over20Mb, setOver20Mb] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const history = useHistory();
    
    const Parse = require('parse');
    
    useEffect(()=> {
        getStaffOptions();
        getDirOptions();
        if (props.isRevision) {
            setRevisionValues();
        }
    },[]);

    useEffect(()=> {
        if (showOther) {
            if (over20Mb) {
                (!documentType || !preparedBy || !reviewedBy || !authorisedBy || !revision 
                    || !other || !reviewedAt || !cloudLink) ? setIsDisabled(true) : setIsDisabled(false)
            } else {
                (!documentType || !preparedBy || !reviewedBy || !authorisedBy || !revision
                    || !other || !reviewedAt || !acceptedFiles.length > 0) ? setIsDisabled(true) : setIsDisabled(false)
            }
        } else {
            if (over20Mb) {
                (!documentType || !preparedBy || !reviewedBy || !authorisedBy || !revision 
                    || !reviewedAt || !cloudLink) ? setIsDisabled(true) : setIsDisabled(false)
            } else {
                (!documentType || !preparedBy || !reviewedBy || !authorisedBy || !revision 
                    || !reviewedAt || !acceptedFiles.length > 0) ? setIsDisabled(true) : setIsDisabled(false)
            }
        }        
    }, [showOther, over20Mb, documentType, preparedBy, reviewedBy, authorisedBy, cloudLink, reviewedAt, acceptedFiles, other, revision]);

    useEffect(()=>{
        if(document.getElementById('document-other-name')) {
            if(documentType.label === 'Others') {
                setShowOther(true);
                document.getElementById('document-other-name').className = 'toggle';
            } else {
                setShowOther(false);
                document.getElementById('document-other-name').className = 'none';
            }
        }
    },[documentType]);

    const handleClose = () =>  {
        setShow(false);
        setPreparedBy('');
        setReviewedBy('');
        setAuthorisedBy('');
        setCloudLink('');
        setReviewedAt(new Date());
        setIsDraft(false);
        setAcceptFiles('');
        setOther('');
        setOver20Mb(false);
        setShowOther(false);
        if (props.isRevision) {
            setRevisionValues();
        } else {
            setRevision('A');
            setDocumentType('');
        }
    }

    const handleShow = () => {
        setShow(true);
    }

    const defaultSingleValue = (options, propsValue) => {
        return options.filter(option => option.label === propsValue)[0];
    }

    const setRevisionValues = () => {
        const str = props.revisionLetter;
        const nextLetter = String.fromCharCode(str.charCodeAt(str.length - 1) + 1);
        setRevision(nextLetter);
        setDocumentType(defaultSingleValue(documentOptions, props.documentType));
    }

    const getStaffOptions = () => {
        const query = new Parse.Query(Parse.Role);
        query.equalTo('name','staff').first().then(staff => { 
            const users = staff.getUsers().query();
            users.ascending('firstName');
            users.find()
            .then((userQueryRes) => {
                const currentUser = Parse.User.current();
                for (let i = 0; i < userQueryRes.length; i++) {
                    if (currentUser.id === userQueryRes[i].id) {
                        var thisUser = userQueryRes.splice(i,1);
                        userQueryRes.unshift(thisUser[0]); 
                        break;
                    }
                }
                const options = userQueryRes.map(user => ({
                    "value" :user.id,
                    "label" : user.attributes.firstName + ' ' + user.attributes.lastName,
                    "object" : user
                }));
                setStaffOptions(options);
            }, (error) => {
                alert('Please send screenshot of this error message: ' + error.message);
            })
        }, (error) => {
            alert('Please send screenshot of this error message: ' + error.message);
        })
    }

    const getDirOptions = () => {
        const query = new Parse.Query(Parse.Role);
        query.equalTo('name','admin').first().then(staff => { 
            const users = staff.getUsers().query();
            users.equalTo('lastName','Magrabi');
            users.ascending('firstName');
            users.find()
            .then((userQueryRes) => {
                // const currentUser = Parse.User.current();
                // for (let i = 0; i < userQueryRes.length; i++) {
                //     if (currentUser.id === userQueryRes[i].id) {
                //         var thisUser = userQueryRes.splice(i,1);
                //         userQueryRes.unshift(thisUser[0]); 
                //         break;
                //     }
                // }
                const options = userQueryRes.map(user => ({
                    "value" :user.id,
                    "label" : user.attributes.firstName + ' ' + user.attributes.lastName,
                    "object" : user
                }));
                setDirectorOptions(options);
            }, (error) => {
                alert('Please send screenshot of this error message: ' + error.message);
            })
        }, (error) => {
            alert('Please send screenshot of this error message: ' + error.message);
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const Document = Parse.Object.extend('Document');
        const document = new Document();

        document.set('projectId', props.project);
        if(showOther) {
            document.set('documentType', other)
        } else {
            document.set('documentType', documentType.label);
        }
        document.set('revision', revision);
        document.set('preparedBy', preparedBy.object);
        document.set('reviewedBy', reviewedBy.object);
        document.set('authorisedBy', authorisedBy.object);
        document.set('reviewedAt', reviewedAt);
        document.set('createdBy', Parse.User.current());
        if (isDraft) {
            document.set('note', 'Draft');
        }
        document.set('archive', false);
        document.set('showClient', true);
        document.set('userViews', 0);
        document.set('viewedByClient', false);

        document.set('over20Mb', over20Mb);
        if (over20Mb) {
            document.set('cloudLink', cloudLink);
        } else {
            if (acceptedFiles.length > 0) {
                const file = acceptedFiles[0];
                const name = acceptedFiles[0].name;
                document.set('file', new Parse.File(name, file));
            }
        }

        var docACL = new Parse.ACL();
        docACL.setRoleReadAccess('staff', true);
        docACL.setRoleWriteAccess('staff', true);
        docACL.setReadAccess(props.clientId, true);
        document.setACL(docACL);

        document.save()
        .then((document)=> {
            // console.info('Revision object created: ' + document.id)
        }).then(() => {
            if (props.isRevision) {
                const oldDocument = new Parse.Query('Document');
                oldDocument.get(props.oldDocumentId).then((oldDocRes)=> {
                    oldDocRes.set('archive', true);
                    oldDocRes.save().then(() => {
                        setTimeout(()=>{
                            history.go(0);
                        }, 1000);
                    }, (error) => {
                        alert('Please send screenshot of this error message: ' + error.message);
                    });
                });
            } else {
                setTimeout(()=>{
                    history.go(0);
                }, 1000);
            }
        }, (error) => {
            alert('Please send screenshot of this error message: ' + error.message);
        });

        handleClose();
    }

    const handleUseCloudSwitch = (e) => {
        setOver20Mb(e);
        if (e) {
            document.getElementById('file-upload').classList.remove('toggle');
            document.getElementById('file-upload').classList.add('none');
            document.getElementById('cloud-link').classList.remove('none');
            document.getElementById('cloud-link').classList.add('toggle');
        } else {
            document.getElementById('cloud-link').classList.remove('toggle');
            document.getElementById('cloud-link').classList.add('none');
            document.getElementById('file-upload').classList.remove('none');
            document.getElementById('file-upload').classList.add('toggle');
        }
    }

    let button;
    if (props.isRevision) {
        button = <Button
            variant="primary"
            style={{fontSize: ".7rem",padding: "0 .5rem",textTransform: 'capitalize',letterSpacing: '0.01rem'}}
            onClick={handleShow}
        >
            Issue Revision
        </Button>;
    } else {
        button = <Button onClick={handleShow}>
            <i className="fas fa-plus"></i>
        </Button>;
    }

    return(
        <div>
            {button}
            <Modal show={show} onHide={handleClose} centered size='lg' backdrop="static">
                <Modal.Header className='modal-header' closeButton>
                    <Modal.Title>Add Document</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body'>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Form.Group as={Col} md="4" className="mb-3">
                                <Form.Label>Document</Form.Label>
                                <Select 
                                    styles={customStyles}
                                    placeholder="Select type..."
                                    options={documentOptions}
                                    defaultValue={documentType}
                                    getOptionValue={option => option.label}
                                    onChange={(e)=>setDocumentType(e)}
                                    required
                                    autoFocus
                                /> 
                            </Form.Group>
                            <Form.Group as={Col} md="4" className="mb-3">
                                <Form.Label>Date</Form.Label>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className='form-control'
                                    selected={reviewedAt}
                                    onChange={(date)=> setReviewedAt(date)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="2" className="mb-3">
                                <Form.Label>Revision #</Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>REV</InputGroup.Text>
                                    <Form.Control
                                        type='text'
                                        placeholder=''
                                        value={revision}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            value = value.replace(/[^A-Za-z]/ig, '');
                                            setRevision(value.toUpperCase());
                                        }}
                                        maxLength='1'
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="2" className="mb-3">
                                <Form.Label>Draft</Form.Label>
                                <Form.Check
                                    type="switch"
                                    label=""
                                    value={isDraft}
                                    onChange={(e) => setIsDraft(e.target.checked)}
                                />
                            </Form.Group>
                        </Row>
                        <Row id="document-other-name" className="none">
                            <Form.Group as={Col} md="8" className="mb-3">
                                    <Form.Control
                                        type='text'
                                        placeholder='Type name...'
                                        onChange={(e) => setOther(e.target.value)}
                                    />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md="4" className="mb-3">
                                <Form.Label>Prepared By</Form.Label>
                                <Select 
                                    styles={customStyles}
                                    options={staffOptions}
                                    getOptionValue={option => option.label}
                                    onChange={(e)=>setPreparedBy(e)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4" className="mb-3">
                                <Form.Label>Reviewed By</Form.Label>
                                <Select 
                                    styles={customStyles}
                                    options={staffOptions}
                                    getOptionValue={option => option.label}
                                    onChange={(e)=>setReviewedBy(e)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4" className="mb-3">
                                <Form.Label>Authorised By</Form.Label>
                                <Select 
                                    styles={customStyles}
                                    options={directorOptions}
                                    getOptionValue={option => option.label}
                                    onChange={(e)=>setAuthorisedBy(e)}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Check
                                    type="switch"
                                    label="Use cloud link"
                                    value={over20Mb}
                                    onChange={(e) => handleUseCloudSwitch(e.target.checked)}
                                />
                            </Form.Group>
                        </Row>
                        <Row id="cloud-link" className="none">
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label>Cloud Link</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type='text'
                                        placeholder=''
                                        value={cloudLink}
                                        onChange={(e) => {setCloudLink(e.target.value)}}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row id="file-upload" className="toggle">
                            <Form.Group className='mb-3'>
                                <PdfUpload
                                    file={setAcceptFiles}
                                    list={acceptedFiles}
                                />
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='modal-header'>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSubmit}
                        disabled={isDisabled}
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>  
        </div>
    )
}
