import { Button} from 'react-bootstrap';

export default function StarProject(props) {

    const Parse = require('parse');

    const starThisProject = () => {
        const query = new Parse.Query(Parse.Object.extend('Project'));
        query.get(props.row.values.idDisplay)
        .then((project) => {
            project.set('star', true);
            project.save()
            .then((res)=>{
                const dataCopy = props.projectData;
                var thisProject = dataCopy[props.row.index];
                thisProject.starDisplay = 'true';
                dataCopy[props.row.index] = thisProject;
                props.setProjectData(dataCopy);
            }).catch((error)=>{
                alert('Please send screenshot of this error message: ' + error.message);
            })
        }).catch((error)=>{
            alert('Please send screenshot of this error message: ' + error.message);
        })
    }

    const unstarThisProject = () => {
        const query = new Parse.Query(Parse.Object.extend('Project'));
        query.get(props.row.values.idDisplay)
        .then((project) => {
            project.set('star', false);
            project.save()
            .then((res)=>{
                const dataCopy = props.projectData;
                var thisProject = dataCopy[props.row.index];
                thisProject.starDisplay = 'false';
                dataCopy[props.row.index] = thisProject;
                props.setProjectData(dataCopy);
            }).catch((error)=>{
                alert('Please send screenshot of this error message: ' + error.message);
            })
        }).catch((error)=>{
            alert('Please send screenshot of this error message: ' + error.message);
        })
    }

    if (props.row.values.starDisplay === 'true') {
        return <>
            <Button variant='link' className="star" size="sm" onClick={unstarThisProject}>
                <i className="fas fa-star"></i>
            </Button>
        </>
    } else {
        return <>
            <Button variant='link' className="star" size="sm" onClick={starThisProject}>
                <i className="far fa-star"></i>
            </Button>
        </>
    }
}
