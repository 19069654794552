import React, { useState, useEffect } from 'react';
import { Container, ListGroup, Alert, Button, Table,
    Col, Row, Tooltip, OverlayTrigger, Accordion, Card} from 'react-bootstrap';
import { useTable, useSortBy } from 'react-table'
import { matchSorter } from 'match-sorter'
import EditKnowledgeCard from './EditKnowledgeCard';
import AddKnowledgeCard from './AddKnowledgeCard';
import { knowledgeCatOptions, customStyles } from '../../data/Data';
import Select from 'react-select';

import './KnowledgeBase.css';


export default function KnowledgeBase() {

    const Parse = require('parse');
    const currentUser = Parse.User.current();

    const [searchText, setSearchText] = useState('');
    const [searchParam] = useState(['heading', 'keywords', 'answer']);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLeaderTableLoaded, setIsLeaderTableLoaded] = useState(false);
    const [filterParam, setFilterParam] = useState([]);
    const [knowledge, setKnowledge] = useState([]);
    const [staff, setStaff] = useState([]);
    const [isStaffLoaded, setIsStaffLoaded] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const [cards, setCards] = useState();

    const [cardMode, setCardMode] = useState('light');

    useEffect(() => {
        document.title = "Knowledge Base";
        loadKnowledge();
        queryStaff();
    },[]);

    useEffect(() => {
        if (currentUser.attributes.darkMode) {
            setCardMode('dark');
        } else {
            setCardMode('light');
        }
    },[currentUser.attributes.darkMode])

    const loadKnowledge = () => {
        const Knowledge = Parse.Object.extend('Knowledge');
        const query = new Parse.Query(Knowledge);
        query.limit(999999);
        query.descending('updatedAt');
        query.find()
        .then(function(res) {
            const content = res.map((item,i) => ({
                'rowKey': i,
                'id': item.id,
                'createdBy': item.get('createdBy').id,
                'heading': item.get('heading'),
                'keywords': item.get('keywords'),
                'type': item.get('type'),
                'answer' : item.get('answer'),
                'url' : item.get('url'),
                'category' : item.get('category'),
            }));
            setKnowledge(content);
            setIsLoaded(true);
        }, (error) => {
            alert(error);
        });
    }

    const queryStaff = () => {
        const staffQuery = new Parse.Query(Parse.Role);
        staffQuery.equalTo('name', 'staff');
        staffQuery.first()
        .then(function(staffQueryRes) {
            const staff = staffQueryRes.getUsers().query();
            staff.equalTo('status', 'Active');
            staff.ascending('firstName');
            staff.find()
            .then((userQueryRes) => {
                setStaff(userQueryRes);
                setIsStaffLoaded(true);
            }, (error) => {
                alert(error);
            })
        }, (error) => {
            alert(error);
        });
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props} className="knowledge-tool">
            Response copied to clipboard
        </Tooltip>
    );

    const fadeTooltip = (props) => {
        if (props === true) {
            setTimeout(()=>{
                document.body.click()
            }, 3000);
        }
    };

    const clipMe = (answer) => {
        navigator.clipboard.writeText(answer);
    }

    const renderCards = () => {
        setCards(search(knowledge).map((item, i) => {
            if (item.id) {
                if (item.type === 'link') {
                    return <>
                        <Row className="mb-3">
                            <Col xs="11">
                                <a href={item.url} target='_blank'>
                                    <div className="card-container">
                                        <ListGroup.Item action className="item-test">
                                            <div className="space-between">
                                                <div>
                                                    <div className="card-heading">{item.heading}</div>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                    </div>
                                </a>
                            </Col>
                            <Col xs="1">
                                <div className='mt-1'>
                                    <EditKnowledgeCard 
                                        rowKey={item.rowKey}
                                        id={item.id}
                                        heading={item.heading}
                                        keywords={item.keywords}
                                        type={item.type}
                                        answer={item.answer}
                                        url={item.url}
                                        category={item.category}
                                        knowledge={knowledge}
                                        setKnowledge={setKnowledge}
                                        renderCards={renderCards}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </>             
                } else {
                    return <>
                        <Row className="mb-3">
                            <Col xs="11">
                                <OverlayTrigger
                                    trigger="click"
                                    rootClose
                                    placement="right"
                                    onToggle={fadeTooltip}
                                    overlay={renderTooltip}
                                >
                                    <span onClick={() => clipMe(item.answer)}>
                                        <div className="card-container">
                                            <ListGroup.Item action className="item-test">
                                                <div className="space-between">
                                                    <div>
                                                        <div className="card-heading">{item.heading}</div>
                                                    </div>
                                                </div>
                                                <div className="card-info">
                                                    <div className="card-row mb-1">
                                                        <span>{item.answer}</span>
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                        </div>
                                    </span>
                                </OverlayTrigger>
                            </Col>
                            <Col xs="1">
                                <div className='mt-4'>
                                    <EditKnowledgeCard 
                                        rowKey={item.rowKey}
                                        id={item.id}
                                        heading={item.heading}
                                        keywords={item.keywords}
                                        type={item.type}
                                        answer={item.answer}
                                        url={item.url}
                                        category={item.category}
                                        knowledge={knowledge}
                                        setKnowledge={setKnowledge}
                                        renderCards={renderCards}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </>
                }
            } else {
                return null;
            }
        }))
    }

    const createCards = React.useMemo(renderCards, [isLoaded, filterParam, searchText]);

    function search(items) {

        if (filterParam.length === 0 && searchText.length === 0) {
            setShowResults(false);
        } else  {
            setShowResults(true);
        }

        if (filterParam.length > 0) {
            var filteredItems = [];
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                for (let j = 0; j < filterParam.length; j++) {
                    if (JSON.stringify(item.category).includes(filterParam[j].value)) {
                        filteredItems.push(item);
                    }
                }
            }
            return matchSorter(filteredItems, searchText, {keys: searchParam});    
        } else {
            return matchSorter(items, searchText, {keys: searchParam});
        }
    }

    const fadeAlert = () => {
        setTimeout(()=>{
            setShowAlert(false);
        }, 3000);
    }

    const getKnowledgeCount = (user) => {
        let k = 0;

        for (let i = 0; i < knowledge.length; i++) {
            if (knowledge[i].createdBy === user) {
                k++;
            }
        }
        return k;
    }

    const getStarDisp = (knowledgeCount) => {
        
        var string;

        if (knowledgeCount >= 100) {
            string = <i className="fas fa-star gold-star"></i>;
        } else if (knowledgeCount >= 25) {
            string = <i className="fas fa-star silver-star"></i>;
        } else if (knowledgeCount >= 10) {
            string = <i className="fas fa-star bronze-star"></i>;
        } else {
            string = '';
        }

        return string;
    }

    const [userContributions, setUserContributions] = React.useState(React.useMemo(() => getKnowledgeCount(currentUser.id), [knowledge]));
    
    const getLeaderData = () => {
        
        var data2 = [];

        for (var i = 0; i < staff.length; i++) {
            var person = staff[i];
            
            var name = person.get('firstName') + ' ' + person.get('lastName');
            var knowledgeCount = getKnowledgeCount(person.id);
            var starDisp = getStarDisp(knowledgeCount);
            data2.push({
                id: i,
                idDisplay: person.id,
                staffDisplay: name,
                contributionsDisplay: knowledgeCount,
                starDisplay: starDisp,
            })
        }
        return data2;
    }

    const [leaderData, setLeaderData] = React.useState(React.useMemo(() => getLeaderData, [knowledge]));
    if (!isLeaderTableLoaded) {
        if (knowledge.length > 0 && isStaffLoaded) {
            setUserContributions(getKnowledgeCount(currentUser.id));
            setLeaderData(getLeaderData);
            setIsLeaderTableLoaded(true);
        }
    }
    
    const leaderCols = React.useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'idDisplay',
                show: false,
            },
            {
                Header: '',
                accessor: 'starDisplay',
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Team Member</span>,
                accessor: 'staffDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Contributions</span>,
                accessor: 'contributionsDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
        ],
        [knowledge]
    )

    const colourRowLeader = (row) => {
        if (row.values.idDisplay === currentUser.id) {
            return ({style: {background: 'var(--teal)',},});
        } else {
            return ({style: {background: 'var(--table-row)',},});
        }
        
    }

    const ContributionsView = () => {
        return <>
            <h4>{userContributions} contributions made</h4>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Leaderboard</Accordion.Header>
                    <Accordion.Body>
                        <TableView columns={leaderCols} data={leaderData} getRowProps={colourRowLeader}/>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    }

    const defaultPropGetter = () => ({})

    function TableView ({ columns, data, getRowProps = defaultPropGetter }) {

        var defState; 

        defState = {
            hiddenColumns: columns.map(column => {
                if (column.show === false) {
                    return column.accessor || column.id;
                }
            }),
            sortBy: [
                {
                    id: "contributionsDisplay",
                    desc: true
                }
            ],
        };

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
        } = useTable({
            columns,
            data,
            initialState: defState,
        },
            useSortBy
        )

        return (
            <Table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>&nbsp;
                                    {column.isSorted
                                    ? column.isSortedDesc
                                        ? <i className="fas fa-sort-down"></i>
                                        : <i className="fas fa-sort-up"></i>
                                    : ''}
                                </span>
                            </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps(getRowProps(row))}>
                        {row.cells.map(cell => {
                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}
                        </tr>
                    )
                    })}
                </tbody>
            </Table>
        )
    }

    const defaultSingleValue = (options, propsValue) => {
        return options.filter(option => option.value === propsValue);
    }

    function searchCategory(text) {
        setFilterParam(defaultSingleValue(knowledgeCatOptions, text));
    }

    function searchButton(text) {
        setSearchText(text);
    }

    function clearSearch () {
        setSearchText('');
        setFilterParam('');
    }

    const ClearSearchView = () => {
        return <>
            <div className='text-center'>
                <Button  variant="light" onClick={() => clearSearch()}>Clear Search</Button>
            </div>
        </>
    }

    const QuickSearchView = () => {
        return <>
            <Row className='mb-4'>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Important Documents</Accordion.Header>
                        <Accordion.Body>
                            <Row>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://loteconsulting.sharepoint.com/sites/TeamLote/Shared%20Documents/Forms/AllItems.aspx' target='_blank'><i className="fas fa-folder"></i> TeamLote Sharepoint</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://forms.office.com/r/31rbytSawY' target='_blank'><i className="fas fa-star"></i> IT Issues</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://loteconsulting.sharepoint.com/:x:/s/LoteIMS/EY2eGiOGjZVBo7NR5yUxpN0BMt-3hujzWxzXiQrzH2dGtw?e=o8SIFw' target='_blank'><i className="fas fa-star"></i> IR Register</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://loteconsulting.sharepoint.com/:x:/s/LoteIMS/EbdzPEOyVNJChl5AT2wvCw0BLZDKHUO0zN-BBAL4sMaVQw?e=TdOM13' target='_blank'><i className="fas fa-star"></i> Template Update</Button>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Subby SharePoints</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://loteconsulting.sharepoint.com/sites/Team-Alam-Lote/Shared%20Documents/Forms/AllItems.aspx' target='_blank'><i className="fas fa-folder"></i> Alam</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://loteconsulting.sharepoint.com/sites/Team-Arthur/Shared%20Documents/Forms/AllItems.aspx' target='_blank'><i className="fas fa-folder"></i> Arthur</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://loteconsulting.sharepoint.com/sites/Team-Deniz/Shared%20Documents/Forms/AllItems.aspx' target='_blank'><i className="fas fa-folder"></i> Deniz</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://loteconsulting.sharepoint.com/sites/Team-Ignis/Shared%20Documents/Forms/AllItems.aspx' target='_blank'><i className="fas fa-folder"></i> Ignis</Button>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://loteconsulting.sharepoint.com/sites/Team-Mosen-Lote/Shared%20Documents/Forms/AllItems.aspx' target='_blank'><i className="fas fa-folder"></i> Mosen</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://loteconsulting.sharepoint.com/sites/Team-Centric/Shared%20Documents/Forms/AllItems.aspx' target='_blank'><i className="fas fa-folder"></i> Centric</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://loteconsulting.sharepoint.com/sites/Team-Lote-HelpingHands/Shared%20Documents/Forms/AllItems.aspx' target='_blank'><i className="fas fa-folder"></i> Helping Hands</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://loteconsulting.sharepoint.com/sites/Team-Sean/Shared%20Documents/Forms/AllItems.aspx' target='_blank'><i className="fas fa-folder"></i> Sean</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://loteconsulting.sharepoint.com/sites/Team-RiskConEng/Shared%20Documents/Forms/AllItems.aspx' target='_blank'><i className="fas fa-folder"></i> RiskCon</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://loteconsulting.sharepoint.com/sites/Team-LoteCFD/Shared%20Documents/Forms/AllItems.aspx' target='_blank'><i className="fas fa-folder"></i> Lote CFD</Button>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Induction</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://loteconsulting.sharepoint.com/:p:/s/TeamLote/ESADjhgcKm5CnHNixLkITXABHoO3cWdrwsbUof-8kIog6A?e=0GI4eL' target='_blank'><i className="fas fa-link"></i> Welcome</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://loteconsulting.sharepoint.com/:p:/s/TeamLote/Eaft_ubDCSJBt2qhstuFx58BGjbedoUrmP7spyqcmxk8Vg?e=ssozpF' target='_blank'><i className="fas fa-link"></i> Communication</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://loteconsulting.sharepoint.com/:w:/s/TeamLote/Ea471czcZFRCiARUvkBRQmYBnBmZ4IRMzcVwUK9AnJh8xg?e=m5RbYo' target='_blank'><i className="fas fa-link"></i> Tasks</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://loteconsulting.sharepoint.com/:w:/s/TeamLote/EZsQRHYkvpFNk6rGzXKs8RYBRZc9NhGAy7cY51PLvTgn5Q?e=9k2Xx8' target='_blank'><i className="fas fa-link"></i> Knowledge Base</Button>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://trainingpreview.edapp.com/p/9kHFBZhBtgGg5dzhXtIJrtBQ' target='_blank'><i className="fas fa-link"></i> IT Setup Start</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://trainingpreview.edapp.com/p/gzhf1xFGwQtRHScPFUuSg3Hc' target='_blank'><i className="fas fa-link"></i> Device Name</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://trainingpreview.edapp.com/p/0kRWVhUNOPRAvuViQtxp8Y5E' target='_blank'><i className="fas fa-link"></i> Deputy</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://trainingpreview.edapp.com/p/8Q2Gcfw6hmO9DfSD51DCj8se' target='_blank'><i className="fas fa-link"></i> Microsoft Office</Button>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://trainingpreview.edapp.com/p/nqhJK1FIxnEWmgfn0AE6qhTq' target='_blank'><i className="fas fa-link"></i> Outlook Email Signature</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://trainingpreview.edapp.com/p/P7ARiUVOHyfgBxtUMNPvyWDC' target='_blank'><i className="fas fa-link"></i> Emails</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://trainingpreview.edapp.com/p/im6XUaGO99q9aS3nk5ojr0XY' target='_blank'><i className="fas fa-link"></i> Recording Screen</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://trainingpreview.edapp.com/p/Mw3XDR42cNYGa7qm7MnwvOdK' target='_blank'><i className="fas fa-link"></i> Securing PDF</Button>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://trainingpreview.edapp.com/p/HKD9mZylEMNEnh3aVIi5jyB0' target='_blank'><i className="fas fa-link"></i> Xero</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://trainingpreview.edapp.com/p/zn1hSqMoiyNpn5ESy5FEOnRU' target='_blank'><i className="fas fa-link"></i> WHS</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://trainingpreview.edapp.com/p/8gDXjyzeDcyqBk8ckw5B0Y02' target='_blank'><i className="fas fa-link"></i> Induction Process</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://trainingpreview.edapp.com/p/2OfzKDtZbmkVyFMlDYjeEZyF' target='_blank'><i className="fas fa-link"></i> Induction Record</Button>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://trainingpreview.edapp.com/p/z2V562hWIp0Q9mXfgpmIFNPO' target='_blank'><i className="fas fa-link"></i> Building Classifications</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://trainingpreview.edapp.com/p/AhkMgi4LbYNG1rSEHMFadxhk' target='_blank'><i className="fas fa-link"></i> Project Invoicing</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://trainingpreview.edapp.com/p/cZh4d9LLuCJI3GMmYuabWpLS' target='_blank'><i className="fas fa-link"></i> Fire Readings</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://trainingpreview.edapp.com/p/exRTrJQkMePzBkPVwh9rH5Xu' target='_blank'><i className="fas fa-link"></i> Mentorship</Button>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://trainingpreview.edapp.com/p/gWIUkrZNLiE0jwQYcJ3cQ1co' target='_blank'><i className="fas fa-link"></i> Cyber Wifi</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://trainingpreview.edapp.com/p/jwYynKTBqMNqpvtxsxfiCOb4' target='_blank'><i className="fas fa-link"></i> Cyber Hygiene</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://trainingpreview.edapp.com/p/E3v1CVsKb2HUqcMGiKpvQCPS' target='_blank'><i className="fas fa-link"></i> Passwords</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://trainingpreview.edapp.com/p/OBUUXt3h0t6S15tqzya5vDaG' target='_blank'><i className="fas fa-link"></i> WFH</Button>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://trainingpreview.edapp.com/p/PmkxtLfIzY0bRsuVd5IHjXaN' target='_blank'><i className="fas fa-link"></i> Teams Background</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://trainingpreview.edapp.com/p/m7B4u4oKV4u919RDe0IGE8oq' target='_blank'><i className="fas fa-link"></i> Clear Screen and Desk</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://trainingpreview.edapp.com/p/rHocrBHQ0QasbqyodsI08puL' target='_blank'><i className="fas fa-link"></i> Password Policy</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://trainingpreview.edapp.com/p/ENu9wfVaMqNKLgAo9DkFb7gQ' target='_blank'><i className="fas fa-link"></i> Gen AI</Button>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://trainingpreview.edapp.com/p/UtkTbiAHuyqq0XVeWLN6bIDE' target='_blank'><i className="fas fa-link"></i> Internet and Email Use</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://trainingpreview.edapp.com/p/XnksoCXPbxjhdmLV4N2JFLrU' target='_blank'><i className="fas fa-link"></i> Phishing</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://trainingpreview.edapp.com/p/1zOYKRsXIDGjKxi03yqrm1Jm' target='_blank'><i className="fas fa-link"></i> Disaster Recovery</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://trainingpreview.edapp.com/p/Y1ehLQ7cJbLLWQ90g9VJirVJ' target='_blank'><i className="fas fa-link"></i> Email Breach</Button>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://trainingpreview.edapp.com/p/RtpDLDdtwztK4ctjAZWCF83A' target='_blank'><i className="fas fa-link"></i> Removable Media</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://trainingpreview.edapp.com/p/uqCPWI63rh1YljSpAz848B8u' target='_blank'><i className="fas fa-link"></i> Mobile Device Security</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://trainingpreview.edapp.com/p/XGo4xHKBCK6seO30jbi9k2fM' target='_blank'><i className="fas fa-link"></i> Physical Security</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://trainingpreview.edapp.com/p/kZ7tlHjcKikXthuERP0YRKBP' target='_blank'><i className="fas fa-link"></i> Public Wi-Fi</Button>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://trainingpreview.edapp.com/p/we52pR3GDaTQWDMqWd9rG3Qh' target='_blank'><i className="fas fa-link"></i> Social Engineering</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://trainingpreview.edapp.com/p/7bNSSmxyOfUuEYY3VafH7Q8t' target='_blank'><i className="fas fa-link"></i> Social Media Use</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://trainingpreview.edapp.com/p/i7deSoKjogaPdVhxpMQyzo1g' target='_blank'><i className="fas fa-link"></i> Passwords and Authentication</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://trainingpreview.edapp.com/p/LXbDLgZn8Gce2aso3qXNp5vT' target='_blank'><i className="fas fa-link"></i> Security at Home</Button>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Further Classrooms</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://trainingpreview.edapp.com/p/bbfkmBNY46LWEh1g3Da384oI' target='_blank'><i className="fas fa-link"></i> BOCSAR</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" href='https://trainingpreview.edapp.com/p/2IOPVUiLMDOVT4IwSv7HTcAw' target='_blank'><i className="fas fa-link"></i> CPTED</Button>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://trainingpreview.edapp.com/p/BjHQp6RcQseMGoofVlv4DpQr' target='_blank'><i className="fas fa-link"></i> Icons</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://trainingpreview.edapp.com/p/0UzarZlZ2zKCPwigaTzB4d6o' target='_blank'><i className="fas fa-link"></i> IMS Folder</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://trainingpreview.edapp.com/p/Typ6X8AiTFRbdGzZEiyxnGJB' target='_blank'><i className="fas fa-link"></i> Festive Greetings</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://trainingpreview.edapp.com/p/iHCfLbpVLdY4VtLlFjQkZwEM' target='_blank'><i className="fas fa-link"></i> LinkedIn</Button>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://trainingpreview.edapp.com/p/6AlSa54ZNErSErW27gdCK8MA' target='_blank'><i className="fas fa-link"></i> Newsletter</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://trainingpreview.edapp.com/p/L4XJLHl7Zam5d8QJaPxraOrB' target='_blank'><i className="fas fa-link"></i> Newsletter Folder</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="orange-button knowledge-button" href='https://trainingpreview.edapp.com/p/zWpC7eOebou4ufBYydJ3oEmk' target='_blank'><i className="fas fa-link"></i> Web Updates</Button>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Row>

            <Row className='mb-4'>
                <Col md={3}>
                    <Card bg={cardMode} className='knowledge-card-home'>
                        <Card.Body>
                            <Card.Title>Fee Proposal</Card.Title>
                            <Button variant="outline-dark" className="teal-button knowledge-button mb-2" href='https://loteconsulting.sharepoint.com/:b:/s/LoteIMS/EcBINwXm27xIgr5bIIl1zgoBT_7yx9FPxLjT1TMABP9sXg?e=QLTbG8' target='_blank'><i className="fas fa-th-large"></i> Flowchart</Button>
                            <Button variant="outline-light" className="orange-button knowledge-button" href='https://loteconsulting.sharepoint.com/:w:/s/LoteIMS/ETEN60h-IDdAmU9raFqlEuoBU5wEtdjTk7bLjyM89Rv1nQ?e=bOhYo0' target='_blank'><i className="fas fa-check-square"></i> Checklist</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card bg={cardMode} className='knowledge-card-home'>
                        <Card.Body>
                            <Card.Title>Report Writing</Card.Title>
                            <Button variant="outline-dark" className="teal-button knowledge-button mb-2" href='https://loteconsulting.sharepoint.com/:b:/s/LoteIMS/ES98dwDSG6ZOku8jAa1uFVYBEjhg9ZUyhEfXro8NBrETnA?e=vHOKK6' target='_blank'><i className="fas fa-th-large"></i> Flowchart</Button>
                            <Button variant="outline-light" className="orange-button knowledge-button" href='https://loteconsulting.sharepoint.com/:w:/s/LoteIMS/ESKVwcfsYLNCm34f3frGIgEB18lTQ15uoI841b2UEtD3cw?e=IDHRcl' target='_blank'><i className="fas fa-check-square"></i> Checklist</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card bg={cardMode} className='knowledge-card-home'>
                        <Card.Body>
                            <Card.Title>FER</Card.Title>
                            <Button variant="outline-dark" className="teal-button knowledge-button mb-2" href='https://loteconsulting.sharepoint.com/:b:/s/LoteIMS/Ecyfwp_1UYZGsT8emIYH8G0BKTmZkRKjzqOBNkE2bc-Utw?e=0otGT0' target='_blank'><i className="fas fa-th-large"></i> Flowchart</Button>
                            <Button variant="outline-light" className="orange-button knowledge-button" href='https://loteconsulting.sharepoint.com/:w:/s/LoteIMS/EdXfKMpaJxdNjB6ZvsiG-84BY6c3U7Tigwwqr2ENBBWrmQ?e=1KQT2W' target='_blank'><i className="fas fa-check-square"></i> Checklist</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card bg={cardMode} className='knowledge-card-home'>
                        <Card.Body>
                            <Card.Title>FEBQ</Card.Title>
                            <Button variant="outline-dark" className="teal-button knowledge-button mb-2" href='https://loteconsulting.sharepoint.com/:b:/s/LoteIMS/EfYvjdeUUdpGtA2A7JqBcqIBjZpN-M4Zfq-P311VZtTWOw?e=AovKNT' target='_blank'><i className="fas fa-th-large"></i> Flowchart</Button>
                            <Button variant="outline-light" className="orange-button knowledge-button" href='https://loteconsulting.sharepoint.com/:w:/s/LoteIMS/EVZCOjPbKTtOoFgBmGxEFqoBpd65P54XnFtF27WiHOMocA?e=SmDwST' target='_blank'><i className="fas fa-check-square"></i> Checklist</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className='mb-4'>
                <Col md={3}>
                    <Card bg={cardMode} className='knowledge-card-home'>
                        <Card.Body>
                            <Card.Title>Site Inspection</Card.Title>
                            <Button variant="outline-dark" className="teal-button knowledge-button mb-2" href='https://loteconsulting.sharepoint.com/:b:/s/LoteIMS/EdbZUbTGH4RKhNWrUIkTeTMBEkMNMYJQXFSkL7LfJEeWYw?e=J6bxJi' target='_blank'><i className="fas fa-th-large"></i> Flowchart</Button>
                            <Button variant="outline-light" className="orange-button knowledge-button" href='https://loteconsulting.sharepoint.com/:w:/s/LoteIMS/Ef2cwfKxvkRGkFm3RPZ0i_YBDm73inyOVOi4Df_h2Ev1vw?e=ZMWR7T' target='_blank'><i className="fas fa-check-square"></i> Checklist</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card bg={cardMode} className='knowledge-card-home'>
                        <Card.Body>
                            <Card.Title>Site Inspection Report</Card.Title>
                            <Button variant="outline-dark" className="teal-button knowledge-button mb-2" href='https://loteconsulting.sharepoint.com/:b:/s/LoteIMS/Ec0VOmB7sadFtr4QltatlwUBRdi63zpDP76AU5yVkH9nng?e=ozfTFi' target='_blank'><i className="fas fa-th-large"></i> Flowchart</Button>
                            <Button variant="outline-light" className="orange-button knowledge-button" href='https://loteconsulting.sharepoint.com/:w:/s/LoteIMS/EVXF9ZJO1ARJr9PRs2MO-coBXE2A4li9asPKKsnF8hwNeQ?e=swEXNK' target='_blank'><i className="fas fa-check-square"></i> Checklist</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card bg={cardMode} className='knowledge-card-home'>
                        <Card.Body>
                            <Card.Title>FEIR</Card.Title>
                            <Button variant="outline-dark" className="teal-button knowledge-button mb-2" href='https://loteconsulting.sharepoint.com/:b:/s/LoteIMS/Ec0VOmB7sadFtr4QltatlwUBRdi63zpDP76AU5yVkH9nng?e=ozfTFi' target='_blank'><i className="fas fa-th-large"></i> Flowchart</Button>
                            <Button variant="outline-light" className="orange-button knowledge-button" href='https://loteconsulting.sharepoint.com/:w:/s/LoteIMS/Eb_93tCMt1RMv0cflisG4IUB3AnqWEI2qvALZPQ2i9wC9w?e=RmbV4q' target='_blank'><i className="fas fa-check-square"></i> Checklist</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card bg={cardMode} className='knowledge-card-home'>
                        <Card.Body>
                            <Card.Title>Cladding Inspection</Card.Title>
                            <Button variant="outline-light" className="orange-button knowledge-button" href='https://loteconsulting.sharepoint.com/:w:/s/LoteIMS/Eaxmnf-b2hVJpXJG2qHeCMkB76220Fwkfiw3EP8KAsnAAQ?e=hxsC2C' target='_blank'><i className="fas fa-check-square"></i> Checklist</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className='mb-4'>
                <Col md={3}>
                    <Card bg={cardMode} className='knowledge-card-home'>
                        <Card.Body>
                            <Card.Title>Risk Assessment</Card.Title>
                            <Button variant="outline-dark" className="teal-button knowledge-button mb-2" href='https://loteconsulting.sharepoint.com/:b:/s/LoteIMS/ERQFLLaQVthAnlQ7tA81rpgB3BTP221VoTQs1VjT6MCTgQ?e=EobgSQ' target='_blank'><i className="fas fa-th-large"></i> Flowchart</Button>
                            <Button variant="outline-light" className="orange-button knowledge-button" href='https://loteconsulting.sharepoint.com/:w:/s/LoteIMS/ESt8WcbGM2hIlGevgfD4IboBgycK5APkBg5iH1giBASliw?e=92KDuc' target='_blank'><i className="fas fa-check-square"></i> Checklist</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card bg={cardMode} className='knowledge-card-home'>
                        <Card.Body>
                            <Card.Title>Cladding Risk Assessment Report</Card.Title>
                            <Button variant="outline-dark" className="teal-button knowledge-button mb-2" href='https://loteconsulting.sharepoint.com/:b:/s/LoteIMS/EVhN295O-3BKr5lPbDrMxG4B9Ajz2ZNsNXlWXBqJy-fmMw?e=I5ahLV' target='_blank'><i className="fas fa-th-large"></i> Flowchart</Button>
                            <Button variant="outline-light" className="orange-button knowledge-button" href='https://loteconsulting.sharepoint.com/:w:/s/LoteIMS/EWttpyGY7-RPqlOpAtXEEdsBUfj7zgH--crNZrj5G3SIOw?e=lmmRuA' target='_blank'><i className="fas fa-check-square"></i> Checklist</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card bg={cardMode} className='knowledge-card-home'>
                        <Card.Body>
                            <Card.Title>PBDB</Card.Title>
                            <Button variant="outline-dark" className="teal-button knowledge-button" href='https://loteconsulting.sharepoint.com/:b:/s/LoteIMS/EXKvJmL3hw9Don4TIhQg4YYBr-VlvvKO5Wtk7OKiQrjMFg?e=OurdKa' target='_blank'><i className="fas fa-th-large"></i> Flowchart</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card bg={cardMode} className='knowledge-card-home'>
                        <Card.Body>
                            <Card.Title>Consultants Advice</Card.Title>
                            <Button variant="outline-dark" className="teal-button knowledge-button" href='https://loteconsulting.sharepoint.com/:b:/s/LoteIMS/EYuA_bmujzNCr0AXoXgWyC4BEN0cX3yKEogNEARD-5aMNA?e=VBx8nH' target='_blank'><i className="fas fa-th-large"></i> Flowchart</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className='mb-4'>
                <Col md={3}>
                    <Card bg={cardMode} className='knowledge-card-home'>
                        <Card.Body>
                            <Card.Title>BCA/FSS Lote</Card.Title>
                            <Button variant="outline-dark" className="teal-button knowledge-button mb-2" href='https://loteconsulting.sharepoint.com/:b:/s/LoteIMS/EReXaWAJSYBMt-EqbIv5-1gBLos7Xx5yxmGWb2WP20zurw?e=YaYKhg' target='_blank'><i className="fas fa-th-large"></i> Flowchart</Button>
                            <Button variant="outline-light" className="orange-button knowledge-button" href='https://loteconsulting.sharepoint.com/:w:/s/LoteIMS/EQNlK2X7Y3lLocGJu1w5kv0BX-Y3XXr85K56QSCPQfN3Mw?e=779gxv' target='_blank'><i className="fas fa-check-square"></i> Checklist</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card bg={cardMode} className='knowledge-card-home'>
                        <Card.Body>
                            <Card.Title>BCA/FSS Subby</Card.Title>
                            <Button variant="outline-dark" className="teal-button knowledge-button mb-2" href='https://loteconsulting.sharepoint.com/:b:/s/LoteIMS/EX16CweUeZFJuCHuFCKpON4BIvGkPcO5AaPee7iNm8aSSA?e=bK7kHC' target='_blank'><i className="fas fa-th-large"></i> Flowchart</Button>
                            <Button variant="outline-light" className="orange-button knowledge-button" href='https://loteconsulting.sharepoint.com/:w:/s/LoteIMS/EWT5dkCpoyhNqYSbPhSb0nEBPYiTlci1bGYfzfxQSB83yg?e=hJxhi8' target='_blank'><i className="fas fa-check-square"></i> Checklist</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card bg={cardMode} className='knowledge-card-home'>
                        <Card.Body>
                            <Card.Title>Peer Review</Card.Title>
                            <Button variant="outline-dark" className="teal-button knowledge-button mb-2" href='https://loteconsulting.sharepoint.com/:b:/s/LoteIMS/EfVz7Yrw60RArjFHgNaenaIBi-2h--vVjvDPbif3YVbDAA?e=kZVOX5' target='_blank'><i className="fas fa-th-large"></i> Flowchart</Button>
                            <Button variant="outline-light" className="orange-button knowledge-button" href='https://loteconsulting.sharepoint.com/:w:/s/LoteIMS/ESyyIoiHylVEvsnlYNXDIucB5rygt44FPT41iIxoxRA6dA?e=oba0XH' target='_blank'><i className="fas fa-check-square"></i> Checklist</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card bg={cardMode} className='knowledge-card-home'>
                        <Card.Body>
                            <Card.Title>DCD</Card.Title>
                            <Button variant="outline-dark" className="teal-button knowledge-button mb-2" href='https://loteconsulting.sharepoint.com/:b:/s/LoteIMS/EXvNPVrzi6RLj7Z9XaJcyu0Bg-uJaG5Uuty_3F6SWnVs3w?e=P9YKqT' target='_blank'><i className="fas fa-th-large"></i> Flowchart</Button>
                            <Button variant="outline-light" className="orange-button knowledge-button" href='https://loteconsulting.sharepoint.com/:w:/s/LoteIMS/EeHwKnmGo3RLrN1lbohnAFkBw19GOec4d_MJH3CxNZgkgA?e=cCijam' target='_blank'><i className="fas fa-check-square"></i> Checklist</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className='mb-4'>
                <Col md={3}>
                    <Card bg={cardMode} className='knowledge-card-home'>
                        <Card.Body>
                            <Card.Title>Non-Standard Contracts</Card.Title>
                            <Button variant="outline-dark" className="teal-button knowledge-button mb-2" href='https://loteconsulting.sharepoint.com/:b:/s/LoteIMS/EcD8kWRceC5HuoFr_ux-ZVkBS-qjvFMhtfGW3z0RB9ku6A?e=KNM3wy' target='_blank'><i className="fas fa-th-large"></i> Flowchart</Button>
                            <Button variant="outline-light" className="orange-button knowledge-button" href='https://loteconsulting.sharepoint.com/:w:/s/LoteIMS/EaPZ9M8wO1tCpRrbkMsiQckBBIEHwe-nUpvdtBum-WepNQ?e=GXcvnU' target='_blank'><i className="fas fa-check-square"></i> Checklist</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card bg={cardMode} className='knowledge-card-home'>
                        <Card.Body>
                            <Card.Title>FECDS</Card.Title>
                            <Button variant="outline-dark" className="teal-button knowledge-button" href='https://loteconsulting.sharepoint.com/:b:/s/LoteIMS/Eb-PnyZGkJJIpcH1RWHwMo8BrjSeLNyBtLHqHhn8ExBHSw?e=9aDV2Y' target='_blank'><i className="fas fa-th-large"></i> Flowchart</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card bg={cardMode} className='knowledge-card-home'>
                        <Card.Body>
                            <Card.Title>FSUB</Card.Title>
                            <Button variant="outline-dark" className="teal-button knowledge-button" href='https://loteconsulting.sharepoint.com/:b:/s/LoteIMS/EXpod4xdLvxLlQNEvK5n1J4BOgDuhGZdq3Ow8qCAWofiyw?e=rr0whn' target='_blank'><i className="fas fa-th-large"></i> Flowchart</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card bg={cardMode} className='knowledge-card-home'>
                        <Card.Body>
                            <Card.Title>FSUR</Card.Title>
                            <Button variant="outline-dark" className="teal-button knowledge-button" href='https://loteconsulting.sharepoint.com/:b:/s/LoteIMS/EX1EyvFegwdPr4X5Ny4OyLEBttv9aGhvfR_gN1IFiEEWiA?e=7mUiUT' target='_blank'><i className="fas fa-th-large"></i> Flowchart</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className='mb-4'>

                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Common Searches</Accordion.Header>
                        <Accordion.Body>
                            <Row>
                                <Col md={3}>
                                    <Button variant="outline-light" className="orange-button knowledge-button" onClick={() => searchButton('security promo')}><i className="fas fa-search"></i> Cross Sell Security</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-light" className="orange-button knowledge-button" onClick={() => searchButton('PDF X-Change')}><i className="fas fa-search"></i> PDF X-Change</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-light" className="orange-button knowledge-button" onClick={() => searchButton('Customer service')}><i className="fas fa-search"></i> Customer service</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-light" className="orange-button knowledge-button" onClick={() => searchButton('Lote 90')}><i className="fas fa-search"></i> Lote 90</Button>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Search Categories</Accordion.Header>
                        <Accordion.Body>
                            <Row className='mb-2'> 
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" onClick={() => searchCategory('Important Documents')}><i className="fas fa-search"></i> Important</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" onClick={() => searchCategory('Induction')}><i className="fas fa-search"></i> Induction</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" onClick={() => searchCategory('Customer Service')}><i className="fas fa-search"></i> Customer Service</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" onClick={() => searchCategory('Fire Safety')}><i className="fas fa-search"></i> Fire Safety</Button>
                                </Col>
                            </Row>
                            <Row className='mb-2'>
                                <Col md={3}>
                                    <Button variant="outline-light" className="orange-button knowledge-button" onClick={() => searchCategory('Security')}><i className="fas fa-search"></i> Security</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-light" className="orange-button knowledge-button" onClick={() => searchCategory('IT Support')}><i className="fas fa-search"></i> IT Support</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-light" className="orange-button knowledge-button" onClick={() => searchCategory('Administration')}><i className="fas fa-search"></i> Administration</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-light" className="orange-button knowledge-button" onClick={() => searchCategory('Report Writing')}><i className="fas fa-search"></i> Report Writing</Button>
                                </Col>
                            </Row>
                            <Row className='mb-2'>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" onClick={() => searchCategory('Site Inspections')}><i className="fas fa-search"></i> Site Inspections</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" onClick={() => searchCategory('Email Templates')}><i className="fas fa-search"></i> Email Templates</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" onClick={() => searchCategory('Training')}><i className="fas fa-search"></i> Training</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-dark" className="teal-button knowledge-button" onClick={() => searchCategory('Cybersecurity')}><i className="fas fa-search"></i> Cybersecurity</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <Button variant="outline-light" className="orange-button knowledge-button" onClick={() => searchCategory('Insurance')}><i className="fas fa-search"></i> Insurance</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-light" className="orange-button knowledge-button" onClick={() => searchCategory('Accounts')}><i className="fas fa-search"></i> Accounts</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-light" className="orange-button knowledge-button" onClick={() => searchCategory('Legal')}><i className="fas fa-search"></i> Legal</Button>
                                </Col>
                                <Col md={3}>
                                    <Button variant="outline-light" className="orange-button knowledge-button" onClick={() => searchCategory('WHS')}><i className="fas fa-search"></i> WHS</Button> 
                                </Col>
                            </Row> 
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Row>
        </>
    }

    if (!isLoaded) { 
        return <><Container><span style={{color:'var(--text)', fontSize:'1em'}}><em>Loading knowledge base...</em></span></Container></>;
    } else {
        return <>
            {/* <Container>
                <Breadcrumb className='container'>
                    <Breadcrumb.Item href='/staff'>Home</Breadcrumb.Item>
                    <Breadcrumb.Item active >Knowledge Base</Breadcrumb.Item>
                </Breadcrumb>
            </Container> */}
            <Container>
                <div className="home-header">
                    <h2>Lote Knowledge Base</h2>
                    <AddKnowledgeCard 
                        knowledge={[...knowledge]}
                        setKnowledge={setKnowledge}
                        renderCards={renderCards}
                        setShowAlert={setShowAlert}
                        fadeAlert={fadeAlert}
                        userContributions={userContributions}
                        setUserContributions={setUserContributions}
                    />
                </div>
                <hr/>
                <div className="client-homepage">
                    
                    <div className="search-wrapper mb-3">
                        <label htmlFor="search-form">
                            <input
                                type="search"
                                name="search-form"
                                id="search-form"
                                className="search-input"
                                placeholder="Search"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            <span className="sr-only">Search knowledge base</span>
                        </label>
                        <div style={{width: '300px'}}>
                            <Select 
                                styles={customStyles}
                                isMulti
                                options={knowledgeCatOptions}
                                placeholder='Select Category'
                                onChange={(e)=>setFilterParam(e)}
                                value={filterParam}
                            />
                        </div> 
                    </div>
                    <div className='mb-3' style={{alignItems:'center'}}>
                        <Alert show={showAlert} variant="success">
                            <Alert.Heading>Card added successfully</Alert.Heading>
                        </Alert>
                        {!showResults && (
                            <div>
                                <Row className='mb-3'>
                                    <QuickSearchView />
                                </Row>
                                <Row>
                                    <ContributionsView />
                                </Row>
                            </div>
                        )}
                        {showResults && (
                            <div>
                                <ListGroup>
                                    {cards}
                                </ListGroup>
                                <ClearSearchView />
                            </div>
                        )}
                    </div>        
                </div>
            </Container>
        </>
    }
}
