// Data arrays that are stored locally within codebase for convenience instead of in database — no sensitive data.

export const customStyles = {
    option: (defaultStyles, state) => ({
        ...defaultStyles,
        color: state.isSelected ? "var(--text)" : "var(--text-muted)",
        backgroundColor: state.isSelected ? "var(--box-background)" : "var(--form-background)",
        '&:hover': {
            backgroundColor: 'var(--box-background)'
        }
    }),

    menu: (defaultStyles) => ({ 
        ...defaultStyles, 
        backgroundColor: "var(--form-background)",
    }),

    control: (defaultStyles) => ({
        ...defaultStyles,
        backgroundColor: "var(--form-background)",
        '&:hover': {
            borderColor: 'var(--text)'
        }
    }),

    singleValue: (defaultStyles) => ({ 
        ...defaultStyles, 
        color: "var(--text)" 
    }),

    multiValue: (defaultStyles) => ({ 
        ...defaultStyles, 
        backgroundColor: "var(--box-background)",
    }),

    multiValueLabel: (defaultStyles) => ({ 
        ...defaultStyles, 
        color: "var(--text)" 
    }),
}

export const paginationOptions = [
    { 'value': '20', 'label': 'Show 20' },
    { 'value': '50', 'label': 'Show 50' },
    { 'value': '1000', 'label': 'Show 1000' },
]

export const officeOptions = [
    {"value" : "City", "label" : "City"}, 
    {"value" : "Parramatta", "label" : "Parramatta"}, 
    {"value" : "Remote", "label" : "Remote"}, 
    {"value" : "Site", "label" : "Site"}, 
    {"value" : "Travelling", "label" : "Travelling"}, 
];

export const leaveOptions = [
    {"value" : "Personal", "label" : "Personal/Carer's Leave"}, 
    {"value" : "Annual", "label" : "Annual Leave"}, 
];

// export const phaseOptions = [
//     { 'value': 'Mobilisation', 'label': 'Mobilisation' },
//     { 'value': 'Fire Stage 1', 'label': 'Fire Stage 1' },
//     { 'value': 'Fire Stage 1a', 'label': 'Fire Stage 1a' },
//     { 'value': 'Fire Stage 2', 'label': 'Fire Stage 2' },
//     { 'value': 'Fire Stage 2a', 'label': 'Fire Stage 2a' },
//     { 'value': 'Fire Stage 3', 'label': 'Fire Stage 3' },
//     { 'value': 'Fire Stage 3a', 'label': 'Fire Stage 3a' },
//     { 'value': 'Fire Stage 4', 'label': 'Fire Stage 4' },
//     { 'value': 'Fire Stage 4a', 'label': 'Fire Stage 4a' },
//     { 'value': 'Fire Stage 5', 'label': 'Fire Stage 5' },
//     { 'value': 'Fire Stage 5a', 'label': 'Fire Stage 5a' },
//     { 'value': 'Security Stage 1', 'label': 'Security Stage 1' },
//     { 'value': 'Security Stage 2', 'label': 'Security Stage 2' },
//     { 'value': 'Security Stage 3', 'label': 'Security Stage 3' },
//     { 'value': 'Security Stage 4', 'label': 'Security Stage 4' },
//     { 'value': 'Security Stage 5', 'label': 'Security Stage 5' },
//     { 'value': 'Security Stage 6', 'label': 'Security Stage 6' },
//     { 'value': 'Security Stage 7', 'label': 'Security Stage 7' },
//     { 'value': 'On Hold', 'label': 'On Hold' },
//     { 'value': 'Complete', 'label': 'Complete' },
// ]

// export const projectOptions = [
//     { 'label': '' },
// ]

// export const moblisationOptions = [
//     { 'label': 'Mobilisation Invoice Issued' },
//     { 'label': 'Awaiting Payment of Mobilisation Invoice' }
// ]

// export const stageCustomOptions = [
//     { 'label': 'Scope of Works' },
//     { 'label': 'Report Being Drafted' },
//     { 'label': 'Report Drafted' },
//     { 'label': 'Report Internally Reviewed' },
//     { 'label': 'Report Released to the Client' },
//     { 'label': 'Final Inspection' },
//     { 'label': 'Progress Claim Invoiced' },
//     { 'label': 'Awaiting Payment of Progress Claim'}
// ]

// export const projectStatusOptions = [
//     { key: 0, 'label': 'Active'},
//     { key: 1, 'label': 'On Hold'},
//     { key: 2, 'label': 'Complete'}
// ]

export const yearOptions = [
    {"value" : "2024", "label" : "2024"}, 
    {"value" : "2023", "label" : "2023"}, 
    {"value" : "2022", "label" : "2022"}, 
    {"value" : "2021", "label" : "2021"}, 
    {"value" : "2020", "label" : "2020"}, 
    {"value" : "2019", "label" : "2019"}, 
    {"value" : "2018", "label" : "2018"}, 
    {"value" : "2017", "label" : "2017"}, 
    {"value" : "2016", "label" : "2016"}, 
    {"value" : "2015", "label" : "2015"}, 
    {"value" : "2014", "label" : "2014"}, 
];

export const locationOptions = [
    {"value" : "NSW", "label" : "NSW"}, 
    {"value" : "ACT", "label" : "ACT"}, 
    {"value" : "VIC", "label" : "VIC"}, 
    {"value" : "QLD", "label" : "QLD"}, 
    {"value" : "NT", "label" : "NT"}, 
    {"value" : "SA", "label" : "SA"},
    {"value" : "WA", "label" : "WA"}, 
    {"value" : "TAS", "label" : "TAS"}, 
    {"value" : "Saudi Arabia", "label" : "Saudi Arabia"}, 
    {"value" : "Singapore", "label" : "Singapore"}, 
    {"value" : "Malaysia", "label" : "Malaysia"}, 
    {"value" : "India", "label" : "India"}, 
    {"value" : "UAE", "label" : "UAE"}, 
    {"value" : "Canada", "label" : "Canada"}, 
    {"value" : "Other", "label" : "Other"}, 
];

export const insuranceOptions = [
    {"value" : "$1 million", "label" : "$1 million"}, 
    {"value" : "$2 million", "label" : "$2 million"}, 
    {"value" : "$5 million", "label" : "$5 million"}, 
    {"value" : "$10 million", "label" : "$10 million"}, 
];

export const servicesOptions = [
    {"value" : "Fire Engineering", "label" : "Fire Engineering"}, 
    {"value" : "Security", "label" : "Security"},
    {"value" : "Dangerous Goods", "label" : "Dangerous Goods (DG)"},
    {"value" : "Peer Review", "label" : "Peer Review"},
    {"value" : "Consultants Advice", "label" : "Consultants Advice (CA)"},
    {"value" : "Concept Design", "label" : "Concept Design (FECDS)"},
    {"value" : "Social Impact Assessment", "label" : "Social Impact Assessment (SIA)"},
    {"value" : "Emergency Management", "label" : "Emergency Management"},
    {"value" : "BCA", "label" : "BCA"},
    {"value" : "Feasibility", "label" : "Feasibility"},
    {"value" : "Cladding", "label" : "Cladding"},
    {"value" : "Passive Services", "label" : "Passive Services"},
    {"value" : "Bushfire", "label" : "Bushfire"},
    {"value" : "Transport", "label" : "Transport"},
    {"value" : "Product Assessment", "label" : "Product Assessment"},
    {"value" : "Expert Witness", "label" : "Expert Witness"},
    {"value" : "Evacuation Modelling", "label" : "Evacuation/Egress Modelling"},
    {"value" : "CFD", "label" : "CFD"},
    {"value" : "FSS", "label" : "FSS Fire Safety Study"},
    {"value" : "PHA", "label" : "PHA Preliminary Hazard Analysis"},
];

export const stageOptions = [
    {"value" : "Pre-DA", "label" : "Pre-DA"},
    {"value" : "DA", "label" : "DA"},
    {"value" : "CC", "label" : "CC"},
    {"value" : "OC", "label" : "OC"},
    {"value" : "CDC", "label" : "CDC"},
    {"value" : "Planning", "label" : "Planning"},
    {"value" : "FSO", "label" : "FSO"},
    {"value" : "Feasibility", "label" : "Feasibility"},
    {"value" : "General", "label" : "General"},
];

export const classOptions = [
    {'value': 'Class 1a', 'label': 'Class 1a – Single Dwelling' },
    {'value': 'Class 1b', 'label': 'Class 1b – Small Transient Living' },
    {'value': 'Class 2', 'label': 'Class 2 – Apartment' },
    {'value': 'Class 3', 'label': 'Class 3 – Transient Living' },
    {'value': 'Class 4', 'label': 'Class 4 – Residential Associated with other Classifications' },
    {'value': 'Class 5', 'label': 'Class 5 – Office' },
    {'value': 'Class 6', 'label': 'Class 6 – Retail' },
    {'value': 'Class 7a', 'label': 'Class 7a – Carpark' },
    {'value': 'Class 7b', 'label': 'Class 7b – Storage' },
    {'value': 'Class 8', 'label': 'Class 8 – Industrial' },
    {'value': 'Class 9a', 'label': 'Class 9a – Hospital' },
    {'value': 'Class 9b', 'label': 'Class 9b – Assembly Building' },
    {'value': 'Class 9c', 'label': 'Class 9c – Aged Care' },
    {'value': 'Class 10a', 'label': 'Class 10a – Non-habitable' },
    {'value': 'Class 10b', 'label': 'Class 10b – Outdoor Structure' },
    {'value': 'Class 10c', 'label': 'Class 10c – Private Bushfire Shelter' },
];

export const reportIncludesOptions = [
    {'value': 'None', 'label': 'No Performance Solutions'},
    {'value': 'C1.1', 'label': 'C1.1 / C2D2 – Type of Construction Required'},
    {'value': 'C1.9', 'label': 'C1.9 / C2D10 – Non-combustible Building Elements'},
    {'value': 'C1.10', 'label': 'C1.10 / C2D11 – Fire Hazard Properties'},
    {'value': 'C1.14', 'label': 'C1.14 / C2D14 – Ancillary Elements'},
    {'value': 'C2.2', 'label': 'C2.2 / C3D3 – General Floor Area and Volume Limitations' },
    {'value': 'C2.3', 'label': 'C2.3 / C3D4 – Large Isolated Buildings' },
    {'value': 'C2.4', 'label': 'C2.4 / C3D5 – Requirements for Open Spaces and Vehicular Access' },
    {'value': 'C2.6', 'label': 'C2.6 / C3D7 – Vertical Separation of Openings in External Walls' },
    {'value': 'C2.7', 'label': 'C2.7 / C3D8 – Separation by Fire Walls' },
    {'value': 'C2.8', 'label': 'C2.8 / C3D9 – Separation of Classifications in the same storey' },
    {'value': 'C2.10', 'label': 'C2.10 / C3D11 – Separation of Lift Shafts' },
    {'value': 'C2.12', 'label': 'C2.12 / C3D13 – Separation of Equipment' },
    {'value': 'C2.14', 'label': 'C2.14 / C3D15 – Public Corridors in Class 2 and 3 buildings' },
    {'value': 'C3.2', 'label': 'C3.2 / C4D3 – Protection of Openings' },
    {'value': 'C3.5', 'label': 'C3.5 / C4D6 – Doorways in Fire walls' },
    {'value': 'C3.6', 'label': 'C3.6 / C4D7 – Sliding Doors' },
    {'value': 'C3.10', 'label': 'C3.10 / C4D11 – Openings in fire-isolated lift shafts' },
    {'value': 'C3.11', 'label': 'C3.11 / C4D12 – Bounding Construction: Class 2 & 3 buildings and Class 4 parts' },
    {'value': 'C3.13', 'label': 'C3.13 / C4D14 – Openings in Shafts' },
    {'value': 'C3.15', 'label': 'C3.15 / C4D15 – Openings for Service Penetrations' },
    {'value': 'D1.2', 'label': 'D1.2 / D2D3 – Number of Exits' },
    {'value': 'D1.3', 'label': 'D1.3 / D2D4 – When Fire-Isolated Stairways and Ramps are Required' },
    {'value': 'D1.4', 'label': 'D1.4 / D2D5 – Exit Travel Distances' },
    {'value': 'D1.5', 'label': 'D1.5 / D2D6/7/8/9/10/11 – Distance between Alternative Exits' },
    {'value': 'D1.6', 'label': 'D1.6 – Dimensions of Exits and Paths of Travels to Exits' },
    {'value': 'D1.7', 'label': 'D1.7 / D2D12 – Travel via Fire Isolated Stairs' },
    {'value': 'D1.8', 'label': 'D1.8 / D2D13 – External Stairways or Ramps in lieu of Fire-Isolated Exits' },
    {'value': 'D1.9', 'label': 'D1.9 / D2D14 – Travel via Non-Fire-Isolated Stairways or Ramps' },
    {'value': 'D1.10', 'label': 'D1.10  / D2D15 – Discharge from Exits' },
    {'value': 'D1.11', 'label': 'D1.11 / D2D16 – Horizontal Exits' },
    {'value': 'D1.12', 'label': 'D1.12 / D2D17 – Non-required Stairways, Ramps or Escalators' },
    {'value': 'D2.4', 'label': 'D2.4 / D3D5 – Separation of Rising and Descending Stair Flights' },
    {'value': 'D2.12', 'label': 'D2.12 / D3D13 – Roof as Open Space' },
    {'value': 'D2.13', 'label': 'D2.13 / D3D14 – Goings and Risers' },
    {'value': 'D2.14', 'label': 'D2.14 / D3D15 – Landings' },
    {'value': 'D2.15', 'label': 'D2.15 / D3D16 – Thresholds' },
    {'value': 'D2.17', 'label': 'D2.17 / D3D22 – Handrails' },
    {'value': 'D2.20', 'label': 'D2.20 / D3D25 – Swinging Doors' },
    {'value': 'D2.22', 'label': 'D2.22 / D3D27 – Re-entry from Fire Isolated Exits' },
    {'value': 'E1.3', 'label': 'E1.3 / E1D2 – Fire Hydrants' },
    {'value': 'E1.4', 'label': 'E1.4 / E1D3 – Fire Hose Reels' },
    {'value': 'E1.5', 'label': 'E1.5 / E1D4/5/6/7/8/9/10/11/12/13 – Sprinklers' },
    {'value': 'E1.8', 'label': 'E1.8 / E1D15 – Fire Control Centres' },
    {'value': 'E1.10', 'label': 'E1.10 / E1D17 – Provision for Special Hazards' },
    {'value': 'E2.2', 'label': 'E2.2 / E2D3/4/5/6/7/8/9/10/11/12/13/14/1/516/17/18/19/20 – Smoke Hazard Management' },
    {'value': 'E3.2', 'label': 'E3.2 / E3D3 – Stretcher Lift' },
    {'value': 'E4.9', 'label': 'E4.9 / E4D9 – Sound Systems and Intercom Systems for Emergency Purposes' },
    {'value': 'H3D3', 'label': '3.7.2 / H3D3 - Fire Separation of External Walls' },
    {'value': 'H3D4', 'label': '3.7.3 / H3D4 - Fire Protection of Separating Walls and Floors' },
    {'value': 'H7D4', 'label': '3.10.5 / H7D4 – Construction in Bushfire Prone Areas' },
    // {'value': 'Value', 'label': 'Label' },
];

export const sectorOptions = [
    {'value': 'Transport and Infrastructure', 'label': 'Transport and Infrastructure'},
    {'value': 'Residential', 'label': 'Residential'},
    {'value': 'Commercial and Retail', 'label': 'Commercial and Retail'},
    {'value': 'Health and Medical', 'label': 'Health and Medical'},
    {'value': 'Retirement and Aged Care', 'label': 'Retirement and Aged Care'},
    {'value': 'Industrial', 'label': 'Industrial'},
    {'value': 'Government', 'label': 'Government'},
    {'value': 'Data Centre', 'label': 'Data Centres'},
    {'value': 'Renewable Energy', 'label': 'Renewable Energy'},
    {'value': 'Big Battery and Power Storage', 'label': 'Big Battery and Power Storage'},
    {'value': 'Sports and Entertainment', 'label': 'Sports and Entertainment'},
    {'value': 'Education', 'label': 'Education'},
    {'value': 'Waste Management and Utilities', 'label': 'Waste Management and Utilities'},
    {'value': 'Heritage', 'label': 'Heritage Sites'},
    {'value': 'Cladding', 'label': 'Facade Cladding Risk Assessments'},
    {'value': 'Tunnel', 'label': 'Tunnel Ventilation'},
    {'value': 'Hotel', 'label': 'Hotels'},
    {'value': 'Place of Worship', 'label': 'Places of Worship'},
    {'value': 'Public Assembly', 'label': 'Public Assembly'},
    {'value': 'Mixed-Use Developments', 'label': 'Mixed-Use Developments'}
];

export const lossOptions = [
    {"value" : "price", "label" : "Price too high"}, 
    {"value" : "contract", "label" : "Contract conditions"}, 
    {"value" : "scope", "label" : "Scope issues"}, 
    {"value" : "selected", "label" : "Selected another bid"}, 
    {"value" : "notrequired", "label" : "Not required"}, 
    {"value" : "unknown", "label" : "Unknown"}, 
];

export const feedbackOptions = [
    {"value" : "To send", "label" : "To send"}, 
    {"value" : "Feedback sent", "label" : "Feedback sent"}, 
    {"value" : "-", "label" : "N/A"}, 
];

export const documentOptions = [
    { 'value': 'FP', 'label': 'Fee Proposal'},
    { 'value': 'Contract', 'label': 'Contract'},
    { 'value': 'FER', 'label': 'Fire Engineering Report'},
    { 'value': 'FEIR', 'label': 'Fire Engineering Inspection Report'},
    { 'value': 'FacadeRA', 'label': 'Facade Risk Assessment Report'},
    { 'value': 'CladdingRA', 'label': 'Cladding Risk Assessment'},
    { 'value': 'PR', 'label': 'Peer Review Report'},
    { 'value': 'CSR', 'label': 'Construction Staging Report'},
    { 'value': 'CFD', 'label': 'CFD Report'},
    { 'value': 'CPTED', 'label': 'CPTED Report'},
    { 'value': 'SecRA', 'label': 'Security Risk Assessment'},
    { 'value': 'FEBQ', 'label': 'Fire Engineering Brief Questionnaire'},
    { 'value': 'PBDB', 'label': 'Performance Based Design Brief'},
    { 'value': 'SIR', 'label': 'Site Inspection Report'},
    { 'value': 'CA', 'label': 'Consultant Advice'},
    { 'value': 'BCA', 'label': 'BCA Report'},
    { 'value': 'FECDS', 'label': 'Fire Engineering Concept Design Report'},
    { 'value': 'Others', 'label': 'Others'}
];

export const knowledgeCatOptions = [
    { 'value': 'Important Documents', 'label': 'Important Documents'},
    { 'value': 'Induction', 'label': 'Induction'},
    { 'value': 'Customer Service', 'label': 'Customer Service'},
    { 'value': 'Fire Safety', 'label': 'Fire Safety'},
    { 'value': 'Security', 'label': 'Security'},
    { 'value': 'IT Support', 'label': 'IT Support'},
    { 'value': 'Administration', 'label': 'Administration'},
    { 'value': 'Report Writing', 'label': 'Report Writing'},
    { 'value': 'Site Inspections', 'label': 'Site Inspections'},
    { 'value': 'Email Templates', 'label': 'Email Templates'},
    { 'value': 'Training', 'label': 'Training'},
    { 'value': 'Cybersecurity', 'label': 'Cybersecurity'},
    { 'value': 'Insurance', 'label': 'Insurance'},
    { 'value': 'Accounts', 'label': 'Accounts'},
    { 'value': 'Legal', 'label': 'Legal'},
    { 'value': 'WHS', 'label': 'WHS'},
];
