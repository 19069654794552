import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap';

export default function EditProject(props) {

    // Modal
    const [show,setShow] = useState(false);

    //Variables
    // const [street, setStreet] = useState();
    // const [city, setCity] = useState();
    // const [state, setState] = useState();
    // const [postCode, setPostCode] = useState();
    // const [number, setNumber] = useState(props.projectNo);
    const [name, setName] = useState();
    // const [country, setCountry] = useState();

    const history = useHistory();

    useEffect(()=>{
        // setStreet(props.street);
        // setCity(props.city);
        // setState(props.state);
        // setPostCode(props.postCode);
        setName(props.name);
        // setCountry(props.country);
    }, [show])

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => {
        setShow(true);
    }

    const handleSubmit = () => {
        handleClose();

        const Parse = require('parse');
        const query = new Parse.Query(Parse.Object.extend('Project'));

        // const address = {
        //     street: street,
        //     city: city,
        //     state: state,
        //     zip: postCode,
        //     country: country
        // }

        query.get(props.project.id)
        .then((project) =>{
            // project.set('projectNo', projectNo);
            project.set('name', name);
            // project.set('address', address);
            project.save()
            .then((info)=>{
                setTimeout(()=>{
                    history.go(0);
                }, 1000)
            }).catch((error)=>{
                alert('Please send screenshot of this error message: ' + error.message);
            })
        }).catch((error)=>{
            alert('Please send screenshot of this error message: ' + error.message);
        })
    }

    return (
        <>
            <Button variant="outline-light" onClick={handleShow}>
                <i className='fa fa-cog'/>
            </Button>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header className='modal-header' closeButton>
                    <Modal.Title>Update Project</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body'>
                    <Form onSubmit={handleSubmit}>
                        {/* <Form.Label>Project Info</Form.Label> */}
                        <Form.Group className="mb-2">
                            <Form.Label>Project Name</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Enter Project Name...'
                                defaultValue={props.name}
                                onChange={(e)=>setName(e.target.value)}
                            />
                        </Form.Group>
                        {/* <Form.Group className="mb-2">
                            <Form.Label>Site Address</Form.Label>
                            <FloatingLabel label="Street">
                                <Form.Control
                                    type='text'
                                    className='mb-2'
                                    placeholder='Enter Site Address...'
                                    defaultValue={props.street}
                                    onChange={(e)=>setStreet(e.target.value)}
                                />
                            </FloatingLabel>
                            <FloatingLabel label="City/Suburb">
                                <Form.Control
                                    type='text'
                                    className='mb-2'
                                    placeholder='Enter city/suburb...'
                                    defaultValue={props.city}
                                    onChange={(e)=>setCity(e.target.value)}
                                />
                            </FloatingLabel>
                            <FloatingLabel label="State">
                                <Form.Control
                                    type='text'
                                    className='mb-2'
                                    placeholder='Enter state...'
                                    defaultValue={props.state}
                                    onChange={(e)=>setState(e.target.value)}
                                />
                            </FloatingLabel>
                            <FloatingLabel label="Post Code">
                                <Form.Control
                                    type='text'
                                    className='mb-2'
                                    placeholder='Enter post code...'
                                    defaultValue={props.postCode}
                                    onChange={(e)=>setPostCode(e.target.value)}
                                />
                            </FloatingLabel>
                            <FloatingLabel label="Country">
                                <Form.Control
                                    type='text'
                                    className='mb-2'
                                    placeholder='Enter country...'
                                    defaultValue={props.country}
                                    onChange={(e)=>setCountry(e.target.value)}
                                />
                            </FloatingLabel>
                        </Form.Group> */}
                    </Form>
                </Modal.Body>
                <Modal.Footer className='modal-header'>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Button variant="primary" onClick={handleSubmit}>Update</Button>
                </Modal.Footer>
            </Modal> 
        </>
    )
}
