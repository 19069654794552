import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';

export default function ShowOrgProjects(props) {

    const [show, setShow] = useState(false);
    const [name, setName] = useState();
    const [id, setId] = useState();
    const [orgProjects, setOrgProjects] = useState();
    const [isOrgProjectsLoaded, setIsOrgProjectsLoaded] = useState(false);

    useEffect(()=>{
        setName(props.row.values.nameDisplay);
        setId(props.row.values.idDisplay);
    }, [show])

    const handleClose = () =>  {
        setShow(false);
        setName('');
        setId('');
        setOrgProjects('');
        setIsOrgProjectsLoaded(false);
    }

    const handleShow = () => {
        setShow(true);

        if (!isOrgProjectsLoaded) {
            const Parse = require('parse');
            const Organisation = Parse.Object.extend('Organisation');
            const orgQuery = new Parse.Query(Organisation);
            orgQuery.get(id)
            .then(function(orgQueryRes) {
                const Project = Parse.Object.extend('Project');
                const query = new Parse.Query(Project);
                query.exclude(['internalNotes', 'statusHistory']);
                query.equalTo('organisationId', orgQueryRes)
                query.limit(999999);
                query.ascending('projectId');
                query.find()
                .then(function(res) {
                    setOrgProjects(res);
                    setIsOrgProjectsLoaded(true);
                }, (error) => {
                    alert(error);
                });
            }, (error) => {
                alert(error);
            });
            
        }
    }

    const OrgProjectList = () => {
        var projLinks = 'Loading org projects';

        if (isOrgProjectsLoaded) {
            if (orgProjects.length === 1) {
                var proj = orgProjects[0];
                projLinks = <Link to={'/staff/' + proj.attributes.projectId}>{proj.attributes.projectId + ' — ' +  proj.attributes.name}</Link>;
            } else {
                projLinks = [];
                for (let i = 0; i < orgProjects.length; i++) {
                    const proj = orgProjects[i];
                    projLinks.push(<span><Link to={'/staff/' + proj.attributes.projectId}>{proj.attributes.projectId + ' — ' +  proj.attributes.name}</Link><br /></span>);
                }
            }
        }        

        return <>
            {projLinks}
        </>
    }

    return <>
        <Button variant='outline-info' onClick={handleShow}>
            <i className="fas fa-project-diagram"></i>
        </Button>
        <Modal show={show} onHide={handleClose} centered backdrop="static">
            <Modal.Header closeButton className='modal-header'>
                <Modal.Title>{name} Projects</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <OrgProjectList />
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
