// Index

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'; 
import App from './App.js'

ReactDOM.render(
    <App />, 
document.getElementById('root'));
