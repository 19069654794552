import { useState, useEffect } from 'react';
import { Button, Form, Modal, Row, Col, Spinner, Alert } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/en-au';
import { customStyles } from '../../data/Data.js'
import AddProject from './AddProject.js';
import axios from 'axios';

import './Task.css';

export default function AddTask(props) {

    const Parse = require('parse');

    const [show, setShow] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const [localProjectOptions, setLocalProjectOptions] = useState([]);
    const [localChecklistOptions, setLocalChecklistOptions] = useState([]);

    const [category, setCategory] = useState('Other');
    const [staff, setStaff] = useState({
        "value" : props.currentUser.id,
        "label" : props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName,
        "object": props.currentUser
    });
    const [multiSwitch, setMultiSwitch] = useState(false);
    const [allSwitch, setAllSwitch] = useState(false);
    const [action, setAction] = useState('');
    const [checklist, setChecklist] = useState({
        "value" : 'null',
        "label" : 'No checklist',
        "object" : 'null'
    });
    const [existingProject, setExistingProject] = useState({
        "value" : 'null',
        "label" : 'No project',
        "object" : 'null',
        "number" : ''
    });
    const [isProjectNull, setIsProjectNull] = useState(true);

    const [projectTitleString, setProjectTitleString] = useState(<span>Project</span>);

    const [isRecurring, setIsRecurring] = useState(false);
    const [recurringType, setRecurringType] = useState({
        "value" : 'day',
        "label" : 'Daily',
    });

    const [link, setLink] = useState(false);
    const [url, setUrl] = useState('');

    const [submitString, setSubmitString] = useState('Create Task');

    var dateTime = new Date();
    dateTime.setDate(dateTime.getDate() + 1)
    dateTime.setHours(17);
    dateTime.setMinutes(0);
    dateTime.setMilliseconds(0);
    const [deadline, setDeadline] = useState(dateTime);

    const today = new Date();
    const nextMonth = new Date(today);
    nextMonth.setDate(nextMonth.getDate() + 30);

    const [isDisabled, setIsDisabled] = useState(true);

    var powerUser = false;
    var usrId = props.currentUser.id;

    if (usrId === 'NS7ecaLkAU' || usrId === 'BKD0LlA2EC' || usrId === 'MJpDsoORp1' || usrId === 'q4Uc4IZB6g') {
        powerUser = true;
    }

    const defaultSingleValue = (options, propsValue) => {
        return options.filter(option => option.value === propsValue)[0];
    }

    useEffect(()=> {
        setLocalProjectOptions(props.projectOptions);
    }, [props.projectOptions]);

    useEffect(()=> {
        setLocalChecklistOptions(props.checklistOptions);
    }, [props.checklistOptions]);

    useEffect(()=> {
        if (category === 'Other') {
            (!action || !category || !deadline) ? setIsDisabled(true) : setIsDisabled(false)
        } else {
            (!action || !category || !deadline || !existingProject || isProjectNull) ? setIsDisabled(true) : setIsDisabled(false)
        }
        if (staff === undefined) {
            setIsDisabled(true);
        }
        if (link) {
            var validUrl = require('valid-url');
            if (!validUrl.isWebUri(url)) {
                setIsDisabled(true);
            }
        }
    }, [action, staff, deadline, category, existingProject, isProjectNull, link, url]);
        
    const handleClose = () =>  {
        setShow(false);
        setCategory('Other');
        setAction('');
        setChecklist({
            "value" : 'null',
            "label" : 'No checklist',
            "object" : 'null'
        });
        setIsProjectNull(true);
        setProjectTitleString(<span>Project</span>);
        setExistingProject({
            "value" : 'null',
            "label" : 'No project',
            "object" : 'null',
            "number" : ''
        });
        setStaff({
            "value" : props.currentUser.id,
            "label" : props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName,
            "object": props.currentUser
        });
        setIsRecurring(false);
        setRecurringType({
            "value" : 'day',
            "label" : 'Daily',
        });
        setLink(false);
        setUrl('');
        setSubmitString('Create Task');
    }

    const handleShow = () => {
        if (props.fromProject) {
            setExistingProject(defaultSingleValue(localProjectOptions, props.fromProject));
            setIsProjectNull(false);
        }
        if (props.fromProjectManager) {
            setStaff(defaultSingleValue(props.staffOptions, props.fromProjectManager));
        }
        setShow(true);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setSubmitString(<>
            Creating&nbsp;
            <Spinner
                as="span"
                animation="border"
                size="sm"
            />
        </>);

        var staffLength = staff.length;
        if (staffLength === undefined) {
            staffLength = 1;
        }

        for (let i = 0; i < staffLength; i++) {

            var staffObject;

            if (staffLength === 1) {
                staffObject = staff.object;
            } else {
                staffObject = staff[i].object;
            }

            const Task = Parse.Object.extend('Task');
            const task = new Task();

            task.set('assignedTo', staffObject);
            task.set('action', action);
            if (checklist.value === 'null') {
                task.set('hasChecklist', false);
            } else {
                var checkArray = [];
                for (let i = 0; i < checklist.object.attributes.list.length; i++) {
                    checkArray[i] = false;
                }
                task.set('hasChecklist', true);
                task.set('list', checklist.object);
                task.set('check', checkArray);
            }
            task.set('recurring', isRecurring);
            if (isRecurring) {
                task.set('recurringType', recurringType.value);
            }
            task.set('link', link);
            if (link) {
                task.set('url', url);
            }
            task.set('complete', false);
            task.set('deadline', deadline);
            task.set('createdBy', props.currentUser);

            var taskACL = new Parse.ACL();
            taskACL.setRoleReadAccess('staff', true);
            taskACL.setRoleWriteAccess('staff', true);
            task.setACL(taskACL);

            if (!isProjectNull) {
                task.set('project', existingProject.object);
            } else {
                task.set('project', null);
            }

            task.save()
            .then((task)=>{
                if (staffObject !== undefined) {
                    if ((staffObject.id !== props.currentUser.id) && (staffObject.attributes.slackNotifHook !== undefined)) {
                        const url = staffObject.attributes.slackNotifHook;
                        var userString = '';
                        if (staffObject.attributes.slackUserId.length > 0) {
                            userString = '<@' + staffObject.attributes.slackUserId + '>';
                        } else {
                            userString = staffObject.attributes.firstName + ' ' + staffObject.attributes.lastName;
                        }
                        var taskString = '';
                        if (isProjectNull) {
                            taskString = action;
                        } else {
                            taskString = action + '\n' + existingProject.label;
                        }
                        var deadlineDisp = moment(deadline).locale('en-au').calendar({
                            // sameDay: '[Today]',
                            // nextDay: '[Tomorrow]',
                            nextWeek: 'ddd DD MMM h:mm A',
                            // lastDay: '[Yesterday]',
                            lastWeek: 'ddd DD MMM h:mm A',
                            sameElse: 'ddd DD MMM h:mm A' 
                        });
                        const data = { text: 'Hi '+ userString + ' ' + 
                            props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + 
                            ' has assigned you a task:\n' + taskString + '\nDue: ' + deadlineDisp};
    
                        axios.post(url, JSON.stringify(data), {
                            withCredentials: false,
                            transformRequest: [
                                (data, headers) => {
                                    return data;
                                },
                            ],
                        });
                    }
                }
                if (i === (staffLength - 1)) {
                    if (staffObject.attributes.firstName === 'zSubby') {
                        window.open('https://forms.office.com/Pages/ResponsePage.aspx?id=s7vl7bsdK0u5roqdJ5kw10swtjnC6CZIq2WKG4ASrItUQkdVQ082UDU2REtPN1BCTzFQTjFETllTUC4u','_newtab' + Date.now());
                    }
                    handleClose();
                }
            }, (error) => {
                alert('Please send screenshot of this error message: ' + error.message);
                setSubmitString('Create Task');
            });
        }
    }

    const handleProjectSelect = (e) => {

        setExistingProject(e);
        if (e.value === 'null') {
            setIsProjectNull(true);
        } else {
            setIsProjectNull(false);
        }
    }

    const handleChecklistSelect = (e) => {
        setChecklist(e);
    }
    
    const handlePrefillSelect = (e) => {
        setAction(e.value);
        setCategory(e.cat);
        if (e.cat === 'Other') {
            setProjectTitleString(<span>Project</span>);
        } else {
            setProjectTitleString(<span>Project<span style={{color: "var(--danger)"}}> *</span></span>);
        }
        switch (e.value) {
            case 'Fee Proposal':
                setChecklist(defaultSingleValue(localChecklistOptions, 'S9eOoy5t6D'));
                break;
            case 'FER':
                setChecklist(defaultSingleValue(localChecklistOptions, 'tmBLmA2XHv'));
                break;
            case 'Site Inspection':
                setChecklist(defaultSingleValue(localChecklistOptions, 'WHH1Jclq3l'));
                break;
            case 'FEBQ':
                setChecklist(defaultSingleValue(localChecklistOptions, 'gks5Y0w3Nb'));
                break;
            case 'FEIR':
                setChecklist(defaultSingleValue(localChecklistOptions, 'c4Tnhp6UvK'));
                break;
            case 'Raise Invoice':
                setChecklist(defaultSingleValue(localChecklistOptions, 'BhXdmJOEdC'));
                break;
            default:
                break;
        }
    }

    const handleRecurringSwitch = (e) => {
        setIsRecurring(e);
        if (e === true) {
            document.getElementById('recurring-task').classList.remove('none');
            document.getElementById('recurring-task').classList.add('toggle');
        } else {
            document.getElementById('recurring-task').classList.remove('toggle');
            document.getElementById('recurring-task').classList.add('none');
        }
    }

    const taskOptions = [
        { value: 'Fee Proposal', label: 'Fee Proposal', cat: 'Fee'},
        { value: 'Site Inspection', label: 'Site Inspection', cat: 'Inspection'},
        { value: 'Meeting', label: 'Meeting', cat: 'Other'},
        { value: 'FER', label: 'FER', cat: 'Report'},
        { value: 'FECDS', label: 'FECDS', cat: 'Report'},
        { value: 'FEBQ', label: 'FEBQ', cat: 'Report'},
        { value: 'FEIR', label: 'FEIR', cat: 'Report'},
        { value: 'PBDB', label: 'PBDB', cat: 'Report'},
        { value: 'Raise Invoice', label: 'Raise Invoice', cat: 'Other'},
        { value: 'Review Fee Proposal', label: 'Review Fee Proposal', cat: 'Fee'},
        { value: 'Review FER', label: 'Review FER', cat: 'Review'},
        { value: 'Review FECDS', label: 'Review FECDS', cat: 'Review'},
        { value: 'Review FEBQ', label: 'Review FEBQ', cat: 'Review'},
        { value: 'Review FEIR', label: 'Review FEIR', cat: 'Review'},
        { value: 'Review PBDB', label: 'Review PBDB', cat: 'Review'},
        { value: '', label: 'Other', cat: 'Other'},
    ];

    const frequencyOptions = [
        { value: 'day', label: 'Daily'},
        { value: 'week', label: 'Weekly'},
        { value: 'fortnight', label: 'Fortnightly'},
        { value: 'month', label: 'Monthly'},
        { value: 'quarter', label: 'Quarterly'},
        { value: 'year', label: 'Annually'},
    ];

    const fadeAlert = () => {
        setTimeout(()=>{
            setShowAlert(false);
        }, 3000);
    }

    function handleMultiSwitch(e) {
        if (e === false) {
            setStaff({
                "value" : props.currentUser.id,
                "label" : props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName,
                "object": props.currentUser
            });
        }
        setMultiSwitch(e);
    }

    function handleAllSwitch(e) {
        if (e === true) {
            setStaff(props.staffOptions);
        }
        setAllSwitch(e);
    }

    function handleUrlChange(e) {
        var validUrl = require('valid-url');
        if (validUrl.isWebUri(e) || e.length === 0) {
            setUrl(e);
        } else {
            setUrl(e);
            setShowAlert(true);
            fadeAlert();
        }
    }

    const handleExited = () => {
        if (!props.projectMode && !props.noText) {
            props.handleExit();
        }
    }

    return(
        <div>
            {(!props.projectMode && props.noText) && (
                <Button onClick={handleShow}>
                    <i className="fas fa-plus"></i>
                </Button>
            )}
            {(!props.projectMode && !props.noText) && (
                <Button onClick={handleShow}>
                    New Task
                </Button>
            )}
            {(props.projectMode && !props.noText) && (
                <Button variant="outline-primary" onClick={handleShow}>
                    <i className="fas fa-plus"></i> Project Task
                </Button> 
            )}
            {(props.projectMode && props.noText) && (
                <Button variant="outline-primary" className='inline-button' onClick={handleShow}>
                    <i className="fas fa-plus"></i>
                </Button> 
            )}
            
            <Modal show={show} onHide={handleClose} onExited={handleExited} centered backdrop="static">
                <Modal.Header className='modal-header' closeButton>
                    <Modal.Title>New Task</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body'>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            {!multiSwitch && (
                                <Form.Group as={Col} md="6" className="mb-1">
                                    <Form.Label>Assigned To</Form.Label>
                                    <Select 
                                        styles={customStyles}
                                        options={props.staffOptions}
                                        onChange={(e)=>setStaff(e)}
                                        value={staff}
                                        defaultValue={staff}
                                    />
                                </Form.Group>
                            )}
                            {multiSwitch && (
                                <Form.Group as={Col} md="6" className="mb-1">
                                    <Form.Label>Assigned To</Form.Label>
                                    <Select 
                                        styles={customStyles}
                                        options={props.staffOptions}
                                        onChange={(e)=>setStaff(e)}
                                        value={staff}
                                        defaultValue={staff}
                                        isMulti
                                    />
                                </Form.Group>
                            )}
                            <Form.Group as={Col} md="6" className="mb-1">
                                <Form.Label>Deadline</Form.Label>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy h:mm aa"
                                    className='form-control'
                                    selected={deadline}
                                    showTimeSelect
                                    // minDate={new Date()}
                                    todayButton="Today"
                                    onChange={(date)=> setDeadline(date)}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md="4" className="mb-3">
                                <Form.Check
                                    type="switch"
                                    label="Multiple users"
                                    checked={multiSwitch}
                                    onChange={(e) => handleMultiSwitch(e.target.checked)}
                                />
                            </Form.Group>
                            {(multiSwitch && powerUser) && (
                                <Form.Group as={Col} md="4" className="mb-3">
                                    <Form.Check
                                        type="switch"
                                        label="Select all"
                                        checked={allSwitch}
                                        onChange={(e) => handleAllSwitch(e.target.checked)}
                                    />
                                </Form.Group>
                            )}
                        </Row>
                        <Row>
                            <Form.Label>Action</Form.Label>
                            <Form.Group as={Col} md="4" className="mb-3">
                                <Select 
                                    styles={customStyles}
                                    options={taskOptions} 
                                    onChange={(e)=>handlePrefillSelect(e)}
                                    placeholder='Prefill'
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="8" className="mb-3">
                                <Form.Control
                                    id='action-text'
                                    type='text'
                                    placeholder='Or type action'
                                    value={action}
                                    autoFocus
                                    onChange={(e) => {
                                        setAction(e.target.value);
                                    }}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group className="mb-1">
                                <Form.Label>Checklist</Form.Label>
                                <Select 
                                    styles={customStyles}
                                    options={localChecklistOptions}
                                    onChange={(e)=>handleChecklistSelect(e)}
                                    value={checklist}
                                    defaultValue={checklist}
                                />
                            </Form.Group>
                        </Row>
                        <Form.Group className="mb-3">
                            <div className="home-header">
                                <Form.Label>{projectTitleString}</Form.Label>
                                <AddProject 
                                    projectData={[...props.projectData]}
                                    currentUser={props.currentUser}
                                    dummyOrg={props.dummyOrg}
                                    dummyUser={props.dummyUser}
                                    projectManagerOptions={props.projectManagerOptions}
                                    nextProjectNumber={props.nextProjectNumber}
                                    taskMode={true}
                                    noText={true}
                                    localProjectOptions={localProjectOptions}
                                    setLocalProjectOptions={setLocalProjectOptions}
                                    handleProjectSelect={handleProjectSelect}
                                />
                            </div>

                            <Form.Group id="existing-project">
                                <Form.Group>
                                    <Select
                                        styles={customStyles} 
                                        options={localProjectOptions}
                                        onChange={(e)=>handleProjectSelect(e)}
                                        value={existingProject}
                                        defaultValue={existingProject}
                                    />
                                </Form.Group>
                            </Form.Group>
                        </Form.Group>
                        <Row>
                            <Form.Group as={Col} md="4" className="mb-3">
                                <Form.Label>Recurring</Form.Label>
                                <Form.Check
                                    type="switch"
                                    label=""
                                    checked={isRecurring}
                                    onChange={(e) => handleRecurringSwitch(e.target.checked)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="8" id="recurring-task" className="none">
                                <Form.Group>
                                    <Form.Label className='pl-4'>Frequency</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Group controlId="form-project-manager">
                                        <Select
                                            styles={customStyles}
                                            id='form-project-manager'
                                            options={frequencyOptions}
                                            onChange={(e)=>setRecurringType(e)}
                                            value={recurringType}
                                        />
                                    </Form.Group>
                                </Form.Group>
                            </Form.Group>
                        </Row>
                        <Row>
                        <Form.Group as={Col} md="4" className="mb-3">
                            <Form.Label>Enable Link</Form.Label>
                                <Form.Check
                                    type="switch"
                                    label=""
                                    checked={link}
                                    onChange={(e) => setLink(e.target.checked)}
                                />
                            </Form.Group>
                            {link && (
                                <Form.Group as={Col} md="8">
                                    <Form.Label>Link Url</Form.Label>
                                    <Form.Control
                                        id='action-text'
                                        type='text'
                                        placeholder='https://www.loteconsulting.com'
                                        value={url}
                                        onChange={(e) => {
                                            handleUrlChange(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                            )}
                        </Row>
                        <Alert show={showAlert} variant="danger">
                            Invalid URL – Please format correctly with https:// etc
                        </Alert>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='modal-header'>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSubmit}
                        disabled={isDisabled}
                    >
                        {submitString}
                    </Button>
                </Modal.Footer>
            </Modal>  
        </div>
    )
}
