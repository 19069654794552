import { useState, useEffect } from 'react';
import { Button, Modal, Table, Stack, Spinner } from 'react-bootstrap';
import moment from 'moment';
import 'moment/locale/en-au';
import AddTask from './AddTask';

export default function ProjectTasks(props) {

    const Parse = require('parse');

    const [show, setShow] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [projectTaskData, setProjectTaskData] = useState([]);
    const [cardMode, setCardMode] = useState('light');

    useEffect(() => {
        if (props.currentUser.attributes.darkMode) {
            setCardMode('dark');
        } else {
            setCardMode('light');
        }
    },[props.currentUser.attributes.darkMode])


    const handleClose = () =>  {
        setProjectTaskData([]);
        setLoaded(false);
        setShow(false);
    }

    const handleShow = () => {
        if (props.fromDashboard) {
            props.hideLastPopover();
        }
        if (!loaded) {
            queryProjectTaskData();
        }
        setShow(true);
    }

    const queryProjectTaskData = () => {
        const task = new Parse.Query(Parse.Object.extend('Task'));
        const query = new Parse.Query(Parse.Object.extend('Project'));
        query.get(props.row.values.idDisplay)
        .then((project) => {
            task.equalTo('project', project);
            task.ascending('deadline');
            task.find()
            .then((results) => {
                var data2 = [];
                for (var i = 0; i < results.length; i++) {
                    var task = results[i];            
                    var actionIs = task.get('action');
                    var actionString = actionIs;
                    var url = task.get('url');
                    if ((task.get('link') === true) && (url != null)) {
                        actionString = <a href={url} target='_blank'>{actionIs}</a>;
                    }
        
                    var complete = task.get('complete');
                    var completeTest = 'false';
                    if (complete) {
                        completeTest = 'true';
                    }

                    data2.push({
                        id: i,
                        personNameDisplay: task.get('assignedTo').attributes.firstName + ' ' + task.get('assignedTo').attributes.lastName,
                        actionDisplay: actionString,
                        deadlineValueDisplay: task.get('deadline'),
                        isComplete: completeTest,
                    })
                }
                setProjectTaskData(data2);
                setLoaded(true);
            }).catch((error) => {
                alert('Please send screenshot of this error message: ' + error.message);
            });
        }).catch((error)=>{
            alert('Please send screenshot of this error message: ' + error.message);
        })
    }

    const TaskRow = () => {
        if (projectTaskData.length > 0) {
            const taskTableRow = projectTaskData.map((item, i) => {
                var timeString = '';
                if (item.isComplete === 'true') {
                    timeString = <span><i style={{color:'var(--success)'}} className="fas fa-check"></i></span>;
                } else {
                    timeString = moment(item.deadlineValueDisplay).locale('en-au').calendar({
                        sameDay: '[Today]',
                        nextDay: '[Tomorrow]',
                        nextWeek: 'DD MMM',
                        lastDay: '[Yesterday]',
                        lastWeek: 'DD MMM',
                        sameElse: 'DD MMM' 
                    });
                }
                return (
                    <tr key={i} id={'task-row-'+i+'-'+item.id}>
                        <td>{item.personNameDisplay}</td>
                        <td>{item.actionDisplay}</td>
                        <td>{timeString}</td>
                    </tr>
                )
            });
            return taskTableRow;
        } 
    }

    const TaskDetails = () => {
        if (projectTaskData.length > 0) {
            return <>
                <Table hover variant={cardMode}>
                    <thead>
                        <tr>
                            <th>Responsible</th>
                            <th>Action</th>
                            <th>Deadline</th>
                        </tr>
                    </thead>
                    <tbody>
                        <TaskRow/>
                    </tbody>
                </Table>
            </>
        } else if (loaded) {
            return <>
                <span style={{color:'var(--text)', fontSize:'1em'}}>No tasks</span>
            </>
        } else {
            return <>
                <span style={{color:'var(--text)', fontSize:'1em'}}><em>Loading tasks...</em></span>
            </>
        }
    }

    const handleExited = () => {
        if (!props.progressMode) {
            props.handleExit();
        }
    }

    return <>
        <Button variant='outline-dark' className='cerulean-button square-button' onClick={handleShow}>
            <i className="fas fa-tasks"></i>
        </Button>

        <Modal show={show} onHide={handleClose} onExited={handleExited} centered size='lg' backdrop="static" scrollable>
            <Modal.Header className='modal-header' closeButton>
                <Modal.Title>Tasks: {props.row.values.projectNumberDisplay} — {props.row.values.projectNameDisplay}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <TaskDetails />
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <AddTask
                    currentUser={props.currentUser}
                    staffOptions={props.staffOptions}
                    projectManagerOptions={props.projectManagerOptions}
                    projectOptions={props.projectOptions}
                    projectData={[...props.projectData]}
                    dummyOrg={props.dummyOrg}
                    dummyUser={props.dummyUser}
                    nextProjectNumber={props.nextProjectNumber}
                    fromProject={props.row.values.idDisplay}
                    fromProjectManager={props.row.values.personDisplay}
                    checklistOptions={props.checklistOptions}
                    projectMode={true}
                    noText={true}
                    handleExit={props.handleExit}
                />
            </Modal.Footer>
        </Modal>
    </>
}
