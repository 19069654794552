// App

import React from 'react';
import Login from './components/account/Login';
import Cookies from 'js-cookie';
import Main from './components/Main';
import ReactDOM from 'react-dom';
// import ClientMain from "./components/client-view/ClientMain";

function App() { 
  
    const token = Cookies.get('sessionToken');

    if (!token) {
        return (
            <div className="block background">
                <div className="overlay">
                    <Login />
                </div>
            </div>
        );
    } else {
        // const Parse = require('parse');
        // Parse.initialize(
        //     process.env.REACT_APP_PARSE_APP_ID,
        //     process.env.REACT_APP_PARSE_JS_KEY
        // );
        // const user = Parse.User.current();
        
        ReactDOM.render(
            // <React.StrictMode>
                <Main />
            // </React.StrictMode>,
            ,
            document.getElementById('root')
        );
        return null;
    }
}

export default App;
