import { useState, useEffect } from 'react';
import { Button, Modal, Form, Row, Col, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { customStyles, feedbackOptions } from '../../data/Data.js'

export default function EditProjectManager(props) {

    const [show, setShow] = useState(false);
    const [projectDisplayString, setProjectDisplayString] = useState('');
    const [feedback, setFeedback] = useState();
    const [submitString, setSubmitString] = useState('Save Project');

    const defaultSingleValue = (options, propsValue) => {
        return options.filter(option => option.value === propsValue);
    }

    const handleClose = () =>  {
        setFeedback(defaultSingleValue(feedbackOptions, props.row.values.feedbackDisplay))
        setProjectDisplayString('');
        setSubmitString('Save Project');
        setShow(false);
    }

    const handleShow = () => {
        setFeedback(defaultSingleValue(feedbackOptions, props.row.values.feedbackDisplay));
        setProjectDisplayString(props.row.values.projectNumberDisplay + ' — ' + props.row.values.projectNameDisplay);
        setShow(true);
    }

    const handleSubmit = () => {
        setSubmitString(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const Parse = require('parse');
        const query = new Parse.Query(Parse.Object.extend('Project'));
        query.get(props.row.values.idDisplay)
        .then((project) =>{
            if (feedback.value) {
                project.set('sendFeedback', feedback.value);
            }
            project.save()
            .then((res2)=>{
                // const dataCopy = props.projectData;
                // var thisProj = dataCopy[props.row.index];
                // if (feedback.value) {
                //     thisProj.feedbackDisplay = feedback.value;
                // }
                // dataCopy[props.row.index] = thisProj;
                // props.setProjectData(dataCopy);
                handleClose();
            }).catch((error)=>{
                alert('Please send screenshot of this error message: ' + error.message);
                setSubmitString('Save Project');
            })
        }).catch((error)=>{
            alert('Please send screenshot of this error message: ' + error.message);
            setSubmitString('Save Project');
        })
    }

    return <>
        <Button variant='outline-secondary' className='square-button' onClick={handleShow}>
            <i className="fas fa-pen"></i>
        </Button>
        <Modal show={show} onHide={handleClose} onExited={props.handleExit} centered size='md' backdrop="static">
            <Modal.Header className='modal-header' closeButton>
                <Modal.Title>{projectDisplayString}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'> 
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-2">
                        <Row>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Group>
                                    <Form.Label>Feedback</Form.Label>
                                    <Select
                                        styles={customStyles}
                                        options={feedbackOptions}
                                        value={feedback}
                                        defaultValue={feedback}
                                        onChange={e=>setFeedback(e)}
                                    />
                                </Form.Group>
                            </Form.Group>
                        </Row>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                >
                    {submitString}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
