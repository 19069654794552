
export default function ProjectStatus(props) {


    if (props.databaseSwitch) {
        return null;
    } else {
        switch (props.row.values.statusDisplay) {
            case 'Undecided':
                return <>
                    <span className='hidden-button-2'>
                        <i style={{color:'var(--text-muted)'}} className="fas fa-question"></i>
                    </span>
                </>
            case 'Lead':
                return <>
                    <span className='hidden-button-2'>
                        <i style={{color:'var(--text-muted)'}} className="fas fa-funnel-dollar"></i>
                    </span>
                </>
            case 'FP on hold':
                return <>
                    <span className='hidden-button-2'>
                        <i style={{color:'var(--text-muted)'}} className="fas fa-pause"></i>
                    </span>
                </>
            case 'Site Inspection':
                return <>
                    <span className='hidden-button-2'>
                        <i style={{color:'var(--text-muted)'}} className="fas fa-clipboard-list"></i>
                    </span>
                </>
            case 'Internal Review':
                return <>
                    <span className='hidden-button-2'>
                        <span style={{color:'var(--text)', fontSize:'1.1em', fontWeight:'bold'}}>IR</span>
                    </span>
                </>
            case 'C10 Review':
                return <>
                    <span className='hidden-button-2'>
                        <span style={{color:'var(--text)', fontSize:'1.1em', fontWeight:'bold'}}>C10</span>
                    </span>
                </>
            case 'SD Review':
                return <>
                    <span className='hidden-button-2'>
                        <span style={{color:'var(--text)', fontSize:'1.1em', fontWeight:'bold'}}>SD</span>
                    </span>
                </>
            case 'On hold':
                return <>
                    <span className='hidden-button-2'>
                        <i style={{color:'var(--text-muted)'}} className="fas fa-pause"></i>
                    </span>
                </>
            default:
                return <>
                    <span className='hidden-button-2'>
                        &nbsp;
                    </span>
                </>
        }
    }
}
